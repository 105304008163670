import React, { useState, useEffect } from "react";
import { getLetterData } from "../services/source";
import Table from "react-bootstrap/Table";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import auth from "../services/auth";
import {
  Page,
  Document,
  View, Image,
  Text,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import pdfHeading from "./common/pdfHeading";

function Invoice() {
  const [invoiceTables, setInvoiceTables] = useState([]);
  const [getSelect, setGetSelect] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [showPDF, setShowPDF] = useState(false);
  const [startDate, setStartDate] = useState(new Date());


  const user = auth.getCurrentUser();
  const nome = user.data.nome;
  const formattedNome = nome.toLowerCase().replace(/\s/g, "");
  let userImage = "assets/img/blank.png";
  if (nome) {
    userImage = `assets/img/${formattedNome}.png`;
  }

  useEffect(() => {
    async function getInvoices() {
      const result = await getLetterData();
      setInvoiceTables(result.data);
    }
    getInvoices();
  }, []);

  const handlechange = (data, clearValue) => {
    if (clearValue.action === "select-option") {
      for (let i = 0; i < data.length; i++) {
        const inv = invoiceTables.filter((val) => {
          if (val.filenumber.includes(data[i].label)) {
            return val;
          }
          return null;
        });
        const input = [...getSelect];
        input.push(inv);
        setGetSelect(input);
      }
    } else if (clearValue.action === "clear") {
      setGetSelect([]);
      setInvoiceData([]);
    } else if (clearValue.action === "remove-value") {
      var item = clearValue.removedValue.value;
      var arr = getSelect.filter((val) => {
        if (val[0].filenumber !== item) {
          return val;
        }
        return null;
      });
      setGetSelect(arr);
    }
  };

  const addMultiRows = (data) => {
    const totalInvoice = getSelect.length;
    data.id = totalInvoice + 1;
    setInvoiceData(getSelect);
  };

  const total = () => {
    const inv = invoiceData.map((sum) => {
      const value = sum[0]?.valfacps || "";
      return parseFloat(value.replace(",", ""));
    });
    if (inv.length > 0) {
      return inv.reduce((prev, rev) => prev + rev);
    }
    return 0;
  };

  const formatotal = () =>{ 
    let invoice = total();
    let invoicetotal = invoice.toLocaleString(undefined, {
      maximumFractionDigits:2,
      minimumFractionDigits:2
    });
    return invoicetotal;
  }

  const styles = StyleSheet.create({
    body: {
      paddingTop: 30,
      paddingBottom: 68,
    },
    subtitle: { textAlign: "center", fontSize: 15, paddingBottom: 10 },
    line: { marginBottom: 10 },
    title: { textAlign: "center" },
    tableRowStyle: {
      flexDirection: "row",
      paddingTop: 0,
      paddingHorizontal: 20,
    },
    tableStyle: { display: "table", width: "auto" },
    firstTableColHeaderStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    tableColHeaderStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    tableCellHeaderStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 9,
      fontWeight: "bold",
    },
    firstTableColStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    tableCellStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 10,
    },
    tableColStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    total: {
      marginTop: 10,
      marginLeft: "68%",
      fontSize: 12,
      fontWeight: "bold",
    },
    assinaturas: {
      fontSize: 12,
      fontWeight: "bold",
      paddingBottom: 20,
      marginLeft: 40,
    },
    assifirst: {
      marginTop: 50,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    }, image: {
      width: "20%",
      marginLeft: "20%",
    },
    preparado: {
      width: "25%",
      marginLeft: "19%",
      marginTop: "-30px",
    },
    aprovado: {
      width: "10%",
      marginLeft: "19%",
      marginTop: "-30px",
    },
  });

  const createTableHeader = () => {
    return (
      <View style={styles.tableRowStyle} fixed>
        <View style={styles.firstTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Nº</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>N/FILE</Text>
        </View>

        {/* <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>V/FILE</Text>
        </View> */}

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>
            Nº DA FATURA DE IMPORTAÇÃO
          </Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>DESCRIÇÃO DE SERVIÇOS</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>FACTURA DE SERVIÇOS</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>VALOR EM AKZ</Text>
        </View>
      </View>
    );
  };

  const generatePDF = () => {
    showPDF ? setShowPDF(false) : setShowPDF(true);
  };

  var tempArray = [];
  invoiceTables.forEach((el) => {
    tempArray.push({
      label: el.filenumber,
      value: el.filenumber,
      color: el.fechofile ? "#F55050" : "#9E9C9C ",
    });
  });

  const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data }) => {
      return { ...styles, color: data.color };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
        color: "#fff",
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
      };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": { color: "#fff" },
      };
    },
  };

  return (
    <div>
      {!showPDF ? (
        <div>
          <div className="row mb-3">
            <div className="col-md-6">
              <Select
                options={tempArray}
                onChange={handlechange}
                isMulti
                placeholder="Selecionar..."
                styles={colorStyles}
              />
              <br />
            </div>
            <div className="col-md-2">
              <button
                className="btn btn-primary"
                onClick={() => addMultiRows(getSelect)}
              >
                Adicionar
              </button>
            </div>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>N/FILE</th>
                {/* <th>V/FILE</th> */}
                <th>Nº DA FATURA DE IMPORTAÇÃO</th>
                <th>DESCRIÇĀO DE SERVIÇOS</th>
                <th>FACTURA DE SERVIÇOS</th>
                <th>VALOR EM AKZ</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.map((val, index) => {
                return (
                  <tr key={index}>
                    <td>{val[0].filenumber}</td>
                    {/* <td>{val[0].ourfile}</td> */}
                    <td>{val[0].refimpor}</td>
                    <td>{val[0].service}</td>
                    <td>{val[0].numfact}</td>
                    <td>
                      <NumericFormat
                        className="form-control text-end disabled"
                        thousandsGroupStyle="thousand"
                        decimalSeparator="."
                        displayType="form-control"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        value={val[0].valfacps}
                        valueIsNumericString={true}
                        disabled
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} style={{ textAlign: "end" }}>
                  TOTAL PAGAR :
                </td>
                <td>
                  <NumericFormat
                    className="form-control text-end disabled"
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="form-control"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={total()}
                    valueIsNumericString={true}
                    disabled
                  />
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
      ) : null}
      <div className="mb-3">
        <label className="form-label">Data: &nbsp; </label>
        <DatePicker
          showIcon
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          locale={pt}
          icon="fa fa-calendar"
          dateFormat="dd/MM/yyyy"
          className="form-control"
        />
      </div>
      <div className="row mb-12">
        <div className="col-md-10">
          <button className="btn btn-success" onClick={() => generatePDF()}>
            {showPDF ? (
              <i
                className="fa fa-arrow-circle-left"
                onClick={() => {
                  setInvoiceData([]);
                  setGetSelect([]);
                }}
              >
                &nbsp;Voltar
              </i>
            ) : (
              <i className="fa fa-file-pdf-o">&nbsp;Exportar</i>
            )}
          </button>
        </div>
        <div className="col-md-2">
          <button
            className="btn btn-danger"
            onClick={() => window.location.reload(false)}
          >
            <i className="fa fa-times-circle">&nbsp;Fechar</i>
          </button>
        </div>
      </div>
      <br />
      <div style={{ paddingLeft: "10%" }}>
        {showPDF ? (
          <PDFViewer width={800} height={1000} showToolbar>
            <Document>
              <Page style={styles.body}>
                <View>{pdfHeading()}</View>
                <Text style={styles.line}>
                  ____________________________________________________________
                </Text>
                <Text style={styles.title}>INVOICE BREAKDOWN</Text>
                <Text style={styles.line}>
                  ____________________________________________________________
                </Text>
                <Text style={styles.subtitle}>"EXO LETTER"</Text>
                <View style={styles.tableStyle}>
                  {createTableHeader()}
                  {invoiceData.map((data, index) => {
                    return (
                      <View key={index} style={styles.tableRowStyle}>
                        <View style={styles.firstTableColStyle}>
                          <Text style={styles.tableCellStyle}>{index + 1}</Text>
                        </View>

                        <View style={styles.tableColStyle}>
                          <Text style={styles.tableCellStyle}>
                            {data[0].filenumber}
                          </Text>
                        </View>

                        {/* <View style={styles.tableColStyle}>
                          <Text style={styles.tableCellStyle}>
                            {data[0].ourfile}
                          </Text>
                        </View> */}

                        <View style={styles.tableColStyle}>
                          <Text style={styles.tableCellStyle}>
                            {data[0].refimpor}
                          </Text>
                        </View>

                        <View style={styles.tableColStyle}>
                          <Text style={styles.tableCellStyle}>
                            {data[0].service}
                          </Text>
                        </View>

                        <View style={styles.tableColStyle}>
                          <Text style={styles.tableCellStyle}>
                            {data[0].numfact}
                          </Text>
                        </View>

                        <View style={styles.tableColStyle}>
                          <Text style={styles.tableCellStyle}>
                            {data[0].valfacps}
                          </Text>
                        </View>
                      </View>
                    );
                  })}

                  <View style={styles.total}>
                    <Text> TOTAL PAGAR : {formatotal()} AKZ</Text>
                  </View>

                  <View style={styles.assinaturas}>
                    <Text style={styles.assifirst}>Preparado por:</Text>
                    <Image
                      style={styles.preparado}
                      src={process.env.PUBLIC_URL + userImage }
                    />
                  </View>
                  <View style={styles.assinaturas}>
                    <Text>Aprovado por:</Text>
                    <Image
                      style={styles.aprovado}
                      src={process.env.PUBLIC_URL + "assets/img/aprovado.png"}
                    />
                  </View>
                  <View style={styles.assinaturas}>
                    <Text>Data: {startDate.toLocaleDateString("pt")}</Text>
                  </View>
                  <Image
                    style={styles.image}
                    src={process.env.PUBLIC_URL + "assets/img/carimbo.png"}
                  />

                  
                </View>
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        ) : null}
      </div>
    </div>
  );
}

export default Invoice;
