import React, { useState, useEffect } from "react";
import Input from "./input";
import { useForm, Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { NumericFormat } from "react-number-format";
import Spinner from "./spinner";
import UseModal from "./useModal";
import {updateFinanceTwo} from "../../services/source"

import { getDates, getDespesas } from "../../services/source";

function EditFinanceTwo(data) {
  const [factcheck, setFactCheck] = useState(data.file[0].faturacao);
  const [enfactcheck, setEnFactCheck] = useState(data.file[0].envfactcli);
  const [atuapricheck, setAtuaPri] = useState(data.file[0].atualizacaoprima);
  const [fechfilecheck, setFechFileCheck] = useState(data.file[0].fechofile);
  const [diff, setDiff] = useState("");
  const [sumval, setSumval] = useState();
  const [sumrev, setSumRev] = useState();
  const [formValues, setFormValues] = useState([
    { valor: " ", descricao: "", fileid: "" },
  ]);
  const [load, setLoad] = useState(false);
  const [sucess, setSucess] = useState(false);

  const cases = data.file[0].filenumber;
  const idfile = data.file[0].idfile;
  const fileid = data.file[0].idfile;

  useEffect(() => {
    async function getDiffDates() {
      await getDates({ cases }).then((res) => {
        setDiff(res.data);
      });
    }
    getDiffDates();
  }, [cases]);

  useEffect(() => {
    async function getDesp() {
      await getDespesas({ idfile }).then((res) => {
        setFormValues(res.data);
      });
    }
    getDesp();
  }, [idfile]);

  useEffect(() => {
    function sumDepFile() {
      const val = formValues.reduce((sum, currentValue) => {
        return sum + parseFloat(currentValue.valor);
      }, 0);
      setValue("despesasdofile", val);
      setSumval(val);
    }
    sumDepFile();
  });

  const dateChange = () => {
    const datas = control._formValues;
    const date1 = datas.datefact;
    const date2 = datas.recfactpgo;
    const diffdays = date2 - date1;
    const days = diffdays / (1000 * 3600 * 24);
    setValue("kpirecbvalorfatservi", days + " Dias");
  };

  const revenueVal = () => {
    const rev = control._formValues;
    const fat = rev.valfacps.replace(/,/g, "");
    const fil = rev.despesasdofile;
    const sum = fat - fil;
    setSumRev(sum);
    setValue("revenue", sum);
  };

  let id = data.file[0].casenumber;
  let filenumber = data.file[0].filenumber;

  const defaultValues = {
    faturacao: data.file[0].faturacao,
    datefact: new Date(data.file[0].datefact),
    valfacps: data.file[0].valfacps,
    datenfact: new Date(data.file[0].datenfact),
    numfact: data.file[0].numfact,
    recfactpgo: new Date(data.file[0].recfactpgo),
    envfactcli: data.file[0].envfactcli,
    atualizacaoprima: data.file[0].atualizacaoprima,
    kpirecbvalorfatservi: data.file[0].kpirecbvalorfatservi,
    fechofile: data.file[0].fechofile,
    observacoes: data.file[0].observacoes,
    revenue: data.file[0].revenue,
  };

  const { register, control, setValue, handleSubmit } = useForm({
    defaultValues,
  });
  registerLocale("pt", pt);

  const NumericFormatWrapper = React.forwardRef(
    ({ onChange, ...rest }, ref) => {
      return (
        <NumericFormat
          {...rest}
          onChange={(val) => {
            onChange(val);
          }}
        />
      );
    }
  );

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // Add the fileid from the database to the submitted form result
    if (i === newFormValues.length - 1) {
      newFormValues[i].fileid = fileid;
    }
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { valor: "", descricao: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const onSubmit = async (data) => {
    const datefact = convert(data.datefact);
    const datenfact = convert(data.datenfact);
    const recfactpgo = convert(data.recfactpgo);
    const payload = {
      id,
      filenumber,
      formValues,
      data,
      datefact,
      datenfact,
      recfactpgo,
    };

    setLoad(true);
    await updateFinanceTwo(payload).then((res) => {
      if (res.data === "true") {
        setLoad(false);
        setSucess(true);
      }
    });
  };

  return (
    <div>
      <Spinner active={load} />
      <UseModal
        stylehead={{
          background: "rgb(228 203 126 / 33%)",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          borderBottom: "1px solid black",
        }}
        stylebody={{ display: "none" }}
        show={sucess}
        close={() => window.location.reload()}
        title={"Ficheiro alterado com sucesso !!!"}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="text-center"
        
        >
         <hr style={{borderTop: "6px solid #0e0e0e"}}></hr>
          Finanças
        </div>
        <div className="row mb-3 mt-3">
          {/* ### Faturacao #### */}
          <div className="col-md-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("faturacao")}
                onChange={(e) => setFactCheck(e.target.checked)}
                checked={factcheck}
                disabled
              />
              <label htmlFor="faturacao">Faturacao</label>
            </div>
          </div>
          {/* ###  Data Faturacao #### */}
          <div className="col-md-3">
            <small>
              <label className="breadcrumb-item active" htmlFor="datasolcli">
                Data Facturacao
              </label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="datefact"
                control={control}
                // rules={{ required: true, valueAsDate:true }}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    isClearable
                  />
                )}
              />
            </div>
          </div>
          {/* ### Valor Fatura Prestacao de Servicos #### */}
          <div className="col-md-3">
            <small>
              <label
                className="breadcrumb-item active text-wrap "
                htmlFor="valorNotadeb"
              >
                Valor Fatura Prestação de Serviços
              </label>
            </small>
            <div className="input-group mb-3">
              <Controller
                name="valfacps"
                control={control}
                render={({ field }) => (
                  <NumericFormatWrapper
                    className="form-control text-end"
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="form-control"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onChange={(val) => field.onChange(val)}
                    valueIsNumericString={true}
                    {...field}
                  />
                )}
              />
              <span className="input-group-text">Kz</span>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          {/* ### Envio Fatura ao Cliente #### */}
          <div className="col-md-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("envfactcli")}
                onChange={(e) => setEnFactCheck(e.target.checked)}
                checked={enfactcheck}
                disabled
              />
              <label htmlFor="enviocliente">Envio Fatura ao Cliente</label>
            </div>
          </div>
          {/* ### Data Envio Fatura #### */}
          <div className="col-md-3">
            <small>
              <label className="breadcrumb-item active" htmlFor="dataenfact">
                Data Envio Factura
              </label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="datenfact"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    isClearable
                  />
                )}
              />
            </div>
          </div>
          {/* ### Numero da Fatura #### */}
          <div className="col-md-3">
            <Input
              className="form-control mt-2"
              type="text"
              id="numfact"
              name="numfact"
              register={register}
              label="Numero Fatura Servicos"
              placeholder="Numero Factura Servicos"
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* ### Data Recebimento da PGTO da fatura servico #### */}
          <div className="col-md-4">
            <small>
              <label className="breadcrumb-item active" htmlFor="dataenfact">
                Data Recebimento da Pagamento
              </label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="recfactpgo"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    onCalendarClose={dateChange}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    isClearable
                  />
                )}
              />
            </div>
          </div>
          {/* ### Atualizacao do programa de faturacao e emissoa do recibo #### */}
          <div className="col-md-6 mt-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("atualizacaoprima")}
                onChange={(e) => setAtuaPri(e.target.checked)}
                checked={atuapricheck}
                disabled
              />
              <label htmlFor="enviocliente">
                Atualizacao do Programa de Faturacao Emissao do Recibo
              </label>
            </div>
          </div>
        </div>
        <div className="row mb-3 mt-4">
          {/* ### KPI Servicos #### */}
          <div className="col-md-6">
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                {...register("kpiservicos")}
                value={diff}
                autoFocus
                readOnly
              />
              <label htmlFor="kpiservicos">KPI Servicos Dias</label>
            </div>
          </div>
          {/* ### KPI Recebimentosde Valor Servicos  #### */}
          <div className="col-md-6">
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                {...register("kpirecbvalorfatservi")}
                readOnly
              />
              <label htmlFor="KPI do Recebimento Factura de Serviços">
                KPI do Recebimento Factura de Serviços
              </label>
            </div>
          </div>
        </div>
        {/* ### Despesasdofile #### */}
        <div className="row mb-3">
          <label htmlFor="despesasFile" className="mb-2">
            Depesas do File
          </label>
          <div className="col-4">
            <div className="input-group mb-3">
              <Controller
                name="despesasdofile"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    className="form-control text-end"
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="form-control"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    valueIsNumericString={true}
                    value={sumval}
                    onChange={(val) => field.onChange(val)}
                    readOnly
                  />
                )}
              />
              <span className="input-group-text">Kz</span>
            </div>
          </div>
          <div className="col-8">
            {formValues.map((element, index) => (
              <div key={index}>
                <input
                  type="hidden"
                  name="id"
                  onChange={(e) => handleChange(index, e)}
                  value={element.id || ""}
                />
                <div className="input-group mb-3">
                  {!index ? (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => addFormFields()}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  ) : null}
                  {index ? (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => removeFormFields(index)}
                    >
                      <i className="fa fa-minus" aria-hidden="true"></i>
                    </button>
                  ) : null}
                  <NumericFormat
                    name="valor"
                    className="form-control text-end"
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="form-control"
                    type="text"
                    value={element.valor || ""}
                    allowNegative={true}
                    thousandSeparator={false}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    valueIsNumericString={true}
                    onChange={(e) => handleChange(index, e)}
                  />
                  <span className="input-group-text">Kz</span>
                  <span style={{ marginLeft: "2%" }}></span>
                  <input
                    type="text"
                    name="descricao"
                    className="form-control"
                    placeholder="Descriçāo"
                    onChange={(e) => handleChange(index, e)}
                    value={element.descricao || ""}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* ### Revenues #### */}
        <div className="row mb-3">
          <div className="col-4"></div>
          <div className="col-6">
            <label htmlFor="revenue" className="col-6  col-form-label">
              Revenue
            </label>
            <div className="input-group mb-3">
              <Controller
                name="revenue"
                control={control}
                render={({ field }) => (
                  <NumericFormatWrapper
                    className="form-control text-end"
                    value={sumrev}
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="form-control"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onChange={(val) => field.onChange(val)}
                    valueIsNumericString={true}
                    readOnly
                    {...field}
                  />
                )}
              />
              <span className="input-group-text">Kz</span>
              <span style={{ marginLeft: "4%" }}></span>
              <input
                className="btn btn-warning"
                onClick={revenueVal}
                type="button"
                placeholder="Calcular"
                defaultValue="Calcular"
              />
            </div>
          </div>
        </div>
        {/* ### Fecho do File #### */}
        <div className="col-md-3 mb-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("fechofile")}
              onChange={(e) => setFechFileCheck(e.target.checked)}
              checked={fechfilecheck}
              disabled
            />
            <label htmlFor="fechofile">Fecho do File</label>
          </div>
        </div>
        <div className="row mb-3">
          {/* ### Observacoes #### */}
          <div className="col-md-12">
            <textarea
              rows="4"
              cols="50"
              className="form-control"
              type="textarea"
              id="observacoes"
              name="observacoes"
              {...register("observacoes")}
              label="Observacoes"
              placeholder="Observacoes"
            />
          </div>
        </div>
        <div className="col-md-12 d-flex justify-content-center mb-4">
          <button
            onClick={revenueVal}
            type="submit"
            className="btn btn-outline-success col-md-2"
          >
            Modificar
          </button>
          <div className="m-2"></div>
          <button
            type="button"
            className="btn btn-outline-danger col-md-2"
            onClick={() => {
              window.location.reload();
            }}
          >
            Fechar
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditFinanceTwo;
