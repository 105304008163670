import React from "react";
import { useForm } from "react-hook-form";
import Input from "../common/input";
import InputSelect from "../common/inputselect";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  nome: yup.string().required("Campo Obrigatório"),
  apelido: yup.string().required("Campo Obrigatório"),
  email: yup
    .string()
    .required("Campo Obrigatório")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Preencher com Valido Email"
    ),
  role: yup
    .string()
    .required("Campo Obrigatório")
    .matches(/[A-Za-z]/, "Campo Obrigatório"),
  pass: yup
    .string()
    .required("Campo Obrigatório")
    .min(8, "Palavra Passe com min 8 caracteres"),
  confirpass: yup
    .string()
    .required("Campo Obrigatório")
    .oneOf([yup.ref("pass")], "As palavras passes têm que ser iguais"),
});

const UserForm = ({
  onSubmit,
  lableButton,
  defaultValues,
  disabled,
  buttonClass,
  selabel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues });

  const options = [
    { label: "Administração", value: "Administacao" },
    { label: "Operações", value: "Operacoes" },
    { label: "Finanças", value: "Financas" },
    { label: "Manager", value: "Manager" },
  ];

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body shadow p-3">
          <div className="row mb-3">
            {/* Nome */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="nome"
                  type="text"
                  errors={errors}
                  label="Nome"
                  placeholder="Nome"
                  disabled={disabled.nome}
                />
              </div>
            </div>
            {/* Apelido */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="apelido"
                  type="text"
                  errors={errors}
                  label="Apelido"
                  placeholder="Apelido"
                  disabled={disabled.apelido}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            {/* Email */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="email"
                  type="text"
                  errors={errors}
                  label="Endereço Email"
                  placeholder="Endereço Email"
                  disabled={disabled.email}
                />
              </div>
            </div>
            {/* Role */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <InputSelect
                  value="Role"
                  label={selabel}
                  name="role"
                  register={register}
                  options={options}
                  errors={errors}
                  disabled={disabled.role}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            {/* Password */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="pass"
                  type="password"
                  errors={errors}
                  label="Palavra Passe"
                  placeholder="Palavra Passe"
                />
              </div>
            </div>
            {/* Confirm Password */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="confirpass"
                  type="password"
                  errors={errors}
                  label="Confirmar Palavra Passe"
                  placeholder="Confirmar Palavra Passe"
                />
              </div>
            </div>
          </div>
          <input {...register("company")} value="CADS" type="hidden" />
          <input {...register("isAdmin")} value="0" type="hidden" />
 
          <div className="mt-4 mb-0">
            <div className="d-grid">
              <button type="submit" className={buttonClass}>
                {lableButton}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserForm;
