import http from "./httpServices.js";
import jwtDecode from "jwt-decode";

const tokenKey = "token";
const user = "/user";



export async function login(data) {
await http.post("/login.php", data).then((res) => sessionStorage.setItem(tokenKey, res.data.jwt))
}

export function logout() {
  sessionStorage.removeItem(tokenKey);
}

export function register(data) {
  http
    .post("/create_user.php", data)
    .then((res) => sessionStorage.setItem(tokenKey, res.data.jwt));
}

export function isAdmin() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    const admin = jwtDecode(jwt);
    return admin.auth;
  } catch (error) {
    return null;
  }
}

export function deleteUser(data) {
  http
    .post("/delete_user.php", data)
    .then((res) => (res.data ? (window.location.href = user) : null));
}

export function editUser(data) {
  http.put("/edit_user.php", data);
}

export function getCurrentUser() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
}

export async function client(data) {
  await http
    .post("/client_login.php", data)
    .then((res) => sessionStorage.setItem(tokenKey, res.data.jwt));
}

const auth = {
  login,
  logout,
  register,
  isAdmin,
  getCurrentUser,
  deleteUser,
  editUser,
  client,
};

export default auth;
