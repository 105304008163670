import React, { useState, useEffect } from "react";

import CheckboxWithLabel from "../common/cheackboxwithlabel";
import InputData from "../common/inputdata";
import CurrencyInput from "../common/currencyinput";
import Input from "../common/input";
import InputButton from "../common/inputbutton";
import UseModal from "../common/useModal";
import InputDynamic from "../common/inputdynamic";

import {
  getDates,
  getDatesLetter,
  getAnexoFacRecName,
} from "../../services/source";

import Accordion from "react-bootstrap/Accordion";
import ReadOnly from "../common/readOnly";

import FileUploadFaturaRecibo from "../fileUploadFaturaRecibo";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Link, useLocation } from "react-router-dom";

const InvoiceForm = ({ onSubmit, defaultValues, onFormValuesSubmit }) => {
  // schema
  const schema = yup.object().shape({
    faturacao: yup.bool().oneOf([true], "Campo Obrigatório"),
    datefact: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
    valfacps: yup.string().required("Campo Obrigatório"),
    envfactcli: yup.bool().oneOf([true], "Campo Obrigatório"),
    datenfact: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
    numfact: yup.string().required("Campo Obrigatório"),
    atualizacaoprima: yup.bool().oneOf([true], "Campo Obrigatório"),
    fechofile: yup.bool().oneOf([true], "Campo Obrigatório"),
  });
  // ############################

  // useState
  const [factcheck, setFactCheck] = useState(false);
  const [enfactcheck, setEnFactCheck] = useState(false);
  const [atuapricheck, setAtuaPri] = useState(false);
  const [showfaturaanexo, setShowFaturaAnexo] = useState(false);
  const [formValues, setFormValues] = useState([{ valor: " ", descricao: "" }]);
  const [fechfilecheck, setFechFileCheck] = useState(false);
  const [facrecName, setFacRecName] = useState();
  // ############################

  let location = useLocation();
  const { file } = location.state;
  const cases = file.filenumber;

  // useEffect
  useEffect(() => {
    async function getDiffDates() {
      if (cases.startsWith("CADS8")) {
        await getDates({ cases }).then((res) => {
          setValue("kpiservicos", res.data);
        });
      } else if (cases.startsWith("CADS9")) {
        await getDatesLetter({ cases }).then((res) => {
          setValue("kpiservicos", res.data);
        });
      }
    }
    getDiffDates();
  }, [cases]);

  useEffect(() => {
    function sumDepFile() {
      const val = formValues.reduce((sum, currentValue) => {
        return sum + parseFloat(currentValue.valor);
      }, 0);
      setValue("despesasdofile", val);
    }
    sumDepFile();
  });

  // ############################

  // functions
  const {
    setValue,
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues });

  const handleFaturaAnexo = () => {
    setShowFaturaAnexo(true);
  };

  const handleCloseFaturaRecibo = () => {
    setShowFaturaAnexo(false);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { valor: "", descricao: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const dateChange = () => {
    const datas = control._formValues;
    const date1 = datas.datefact;
    const date2 = datas.recfactpgo;
    const diffdays = date2 - date1;
    const days = diffdays / (1000 * 3600 * 24);
    setValue("kpirecbvalorfatservi", days + " Dias");
  };

  const revenueVal = () => {
    const rev = control._formValues;
    if (rev) {
      const fat = rev.valfacps ? rev.valfacps.replace(/,/g, "") : 0;
      const fil = rev.despesasdofile ? rev.despesasdofile : 0;
      const sum = fat - fil;
      setValue("revenue", sum);
    } else {
      console.error("control._formValues is undefined or null");
    }
  };
  onFormValuesSubmit(formValues);

  // ############################

  useEffect(() => {
    async function getAnexoRupe() {
      const result = await getAnexoFacRecName(file);
      if (result.data && result.data[0] && result.data[0].filenamefacrec) {
        setFacRecName(result.data[0].filenamefacrec);
      } else {
              return null;
      }
    }
    getAnexoRupe();
  }, [handleCloseFaturaRecibo]);

  return (
    <div>
      <div className="card-header">
        <div className="col">
          <Link to={"/progressTable"}>
            <i className="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i>
          </Link>
        </div>
      </div>
      <div className="card-body">
        <Accordion id="create">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <i
                className="fa fa-folder-open fa-lg"
                aria-hidden="true"
                style={{ marginRight: "8px" }}
              ></i>
              File
            </Accordion.Header>
            <Accordion.Body>
              {<ReadOnly {...file} step={file} />}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <UseModal
            title="Anexo Fatura"
            size={"xl"}
            show={showfaturaanexo}
            close={handleCloseFaturaRecibo}
            body={
              <FileUploadFaturaRecibo
                data={file}
                handleCloseFaturaRecibo={handleCloseFaturaRecibo}
              />
            }
          />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body shadow p-3">
          
          <div className="row mb-3">
            {/* ### Facturacao #### */}
            <div className="col-md-4">
              <CheckboxWithLabel
                name="faturacao"
                register={register}
                type="checkbox"
                id="fact"
                label="Faturação"
                checked={factcheck}
                onChange={(e) => setFactCheck(e.target.checked)}
                errors={errors}
              />
            </div>
            {/* ###  Data Faturacao #### */}
            {factcheck ? (
              <div className="col-md-3">
                <InputData
                  name="datefact"
                  label="Data Faturação"
                  control={control}
                  errors={errors}
                  // disabled={disabled.datecobmin}
                  isclear={true}
                />
              </div>
            ) : null}
            {/* ### Valor Fatura Prestacao de Servicos #### */}
            {factcheck ? (
              <div className="col-md-3">
                <CurrencyInput
                  label="Valor Fatura Prestação de Serviços"
                  name="valfacps"
                  control={control}
                  register={register}
                  suffix="Kz"
                  errors={errors}
                />
              </div>
            ) : null}
          </div>
          <div className="row mb-3">
            {/* ### Envio Fatura ao Cliente #### */}
            <div className="col-md-4">
              <CheckboxWithLabel
                name="envfactcli"
                register={register}
                type="checkbox"
                id="envfac"
                label="Envio Fatura ao Cliente"
                checked={enfactcheck}
                onChange={(e) => setEnFactCheck(e.target.checked)}
                errors={errors}
              />
            </div>
            {/* ### Data Envio Fatura #### */}
            {enfactcheck ? (
              <div className="col-md-3">
                <InputData
                  name="datenfact"
                  label="Data Envio Fatura"
                  control={control}
                  errors={errors}
                  // disabled={disabled.datecobmin}
                  isclear={true}
                />
              </div>
            ) : null}
            {/* ### Numero da Fatura #### */}
            {enfactcheck ? (
              <div className="col-md-3">
                <Input
                  register={register}
                  name="numfact"
                  type="text"
                  errors={errors}
                  label="Numero da Fatura Serviços"
                  placeholder="Numero da Fatura Serviços"
                />
              </div>
            ) : null}
          </div>
          <div className="row mb-3">
            {/* ### Data Recebimento da PGTO da fatura servico #### */}
            <div className="col-md-3">
              <InputData
                name="recfactpgo"
                label="Data Recebimento da Pagamento"
                control={control}
                errors={errors}
                onCalendarClose={dateChange}
                // disabled={disabled.datecobmin}
                isclear={true}
              />
            </div>
            {/* ### Atualizacao do programa de faturacao e emissoa do recibo #### */}
            <div className="col-md-5 mt-4">
              <CheckboxWithLabel
                name="atualizacaoprima"
                register={register}
                type="checkbox"
                id="atualizacao"
                label="Atualizacao do Programa de Faturacao Emissao do
                Recibo"
                checked={atuapricheck}
                onChange={(e) => setAtuaPri(e.target.checked)}
                errors={errors}
              />
            </div>
            {/* ### Anexo Factura e Recibo #### */}
            <div className="col-md-4">
              <InputButton
                button={<i className="fa fa-upload"></i>}
                label="Anexo Fatura"
                name="anexofatura"
                register={register}
                errors={errors}
                onClick={handleFaturaAnexo}
                value={facrecName}
              />
            </div>
          </div>
          <div className="row mb-3">
            {/* ### KPI Servicos #### */}
            <div className="col-md-4">
              <Input
                name="kpiservicos"
                register={register}
                type="text"
                errors={errors}
                label="KPI Servicos Dias"
                placeholder="KPI Servicos Dias"
                autoFocus
                disabled
              />
            </div>
            {/* ### KPI Recebimentosde Valor Servicos  #### */}
            <div className="col-md-4">
              <Input
                register={register}
                name="kpirecbvalorfatservi"
                type="text"
                errors={errors}
                label="KPI do Recebimento Factura de Serviços"
                placeholder="KPI do Recebimento Factura de Serviços"
                disabled
              />
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Despesas dofile #### */}
            <div className="col-4">
              <CurrencyInput
                label="Despesas do File"
                name="despesasdofile"
                control={control}
                register={register}
                suffix="Kz"
                errors={errors}
                disabled
              />
            </div>
            {/* ### Valor e Descricap #### */}
            <div className="col-8 mt-4">
              {formValues.map((element, index) => (
                <InputDynamic
                  key={index}
                  element={element}
                  index={index}
                  handleChange={handleChange}
                  removeFormFields={removeFormFields}
                  addFormFields={addFormFields}
                />
              ))}
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Revenues #### */}
            <div className="col-4"></div>
            <div className="col-4">
              <CurrencyInput
                label="Revenue"
                name="revenue"
                control={control}
                register={register}
                suffix="Kz"
                errors={errors}
                disabled
              />
            </div>
            <div className="col-4 mt-4">
              <input
                className="btn btn-warning"
                onClick={revenueVal}
                type="button"
                placeholder="Calcular"
                defaultValue="Calcular"
              />
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Fecho do File #### */}
            <div className="col-md-3">
              <CheckboxWithLabel
                name="fechofile"
                register={register}
                type="checkbox"
                id="fecho"
                label="Fecho do File"
                checked={fechfilecheck}
                onChange={(e) => setFechFileCheck(e.target.checked)}
                errors={errors}
              />
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Observacoes #### */}
            <div className="col-md-12">
              <textarea
                rows="4"
                cols="50"
                className="form-control"
                type="textarea"
                id="observacoes"
                name="observacoes"
                {...register("observacoes")}
                label="Observacoes"
                placeholder="Observacoes"
              />
            </div>
          </div>
          {/* ######## Button  ########### */}
          <div className="row mb-3">
            <div className="col-md-12 d-flex justify-content-center">
              <button
                onClick={revenueVal}
                type="submit"
                className="btn btn-outline-success col-md-2"
              >
                Submeter
              </button>
              <div className="m-2"></div>
              <button
                className="btn btn-outline-danger col-md-2"
                onClick={() => reset()}
              >
                Apagar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvoiceForm;
