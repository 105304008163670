import React from "react";
import http from "../services/httpServices";
import { delContact } from "../services/source";

function DelContact(data) {

    const onSubmit = () => {
        delContact(data).then((res) => (res.data ? (window.location.href = "/contactos") : null));
      };

  return (<div className="text-center">
  <p>
    Tem a certeza que quer apagar permanentemente o contacto {" "}
    <strong>{data.firstName} {data.lastName}</strong>
  </p>
  <button className="btn btn-danger" onClick={onSubmit}>
    Apagar
  </button>
</div>);
}

export default DelContact;