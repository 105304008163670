import { useEffect } from "react";
import auth from "../services/auth";

function Logout() {
  useEffect(() => {
    auth.logout();
    window.location.href = "/";
  }, []);

  return null;
}
export default Logout;
