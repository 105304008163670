import http from "./httpServices";

export function checkMail(data) {
  return http.post("/checkemail.php", data);
}

export function getUsers() {
  return http.get("/get_user.php");
}

export function createClient(data) {
  return http.post("/create_cliente.php", data);
}

export function getClients() {
  return http.get("/get_clients.php");
}

export function editClient(data) {
  return http.post("/edit_client.php", data);
}

export function delClient(data) {
  return http.post("/delete_client.php", data);
}

export function createImportador(data) {
  return http.post("/create_importador.php", data);
}

export function getImportador() {
  return http.get("/get_importador.php");
}

export function editImportador(data) {
  return http.post("/edit_importador.php", data);
}

export function delImportador(data) {
  return http.post("/delete_importador.php", data);
}

export function createContact(data) {
  return http.post("/create_contact.php", data);
}

export function getContact() {
  return http.get("/get_contact.php");
}

export function editContact(data) {
  return http.post("/edit_contact.php", data);
}

export function delContact(data) {
  return http.post("/delete_contact.php", data);
}

export function getSendEmails() {
  return http.get("/get_send_emails.php");
}

export function disableEmail(data) {
  return http.post("/disable_email.php", data);
}

export function enableEmail(data) {
  return http.post("/enable_email.php", data);
}

export function getRefClient() {
  return http.get("/get_ref_client.php");
}

export function delEmail(data) {
  return http.post("/del_email.php", data);
}

export function getEmailAddress() {
  return http.get("/get_mail_address.php");
}

export function getCase() {
  return http.get("/get_case.php");
}

export function filterSectorLicense() {
  return http.get("/get_colums_sectorlicense.php");
}

export function createFile(data) {
  return http.post("/create_file.php", data);
}

export function createCase(data) {
  return http.post("/create_case.php", data);
}

// home
export function getTotalCases() {
  return http.get("/get_total_cases.php").then((res) => res.data.licensecaseid);
}

export function getProgressTotalCases() {
  return http
    .get("/get_total_progress_cases.php")
    .then((res) => res.data.cicle);
}

export function getTotalCloseCases() {
  return http.get("/get_total_close_cases.php").then((res) => res.data.cicle);
}

export function getTotalClients() {
  return http.get("/get_total_clients.php").then((res) => res.data.id);
}

export function getTotalLetterCases() {
  return http
    .get("/get_total_letter_cases.php")
    .then((res) => res.data.licensecaseid);
}

export function getProgressTotalLetterCases() {
  return http
    .get("/get_total_progress_letter_cases.php")
    .then((res) => res.data.cicle);
}

export function getTotalLetterCloseCases() {
  return http
    .get("/get_total_close_letter_cases.php")
    .then((res) => res.data.cicle);
}

export function getSubmissao() {
  return http.get("/get_submissao.php");
}

export function submitFile(data) {
  return http.post("/add_submition_file.php", data);
}

export function submitLetter(data) {
  return http.post("/add_submition_letter.php", data);
}

export function getSubmitionCases() {
  return http.get("/get_total_sub.php").then((res) => res.data.total);
}

export function createCaseLetter(data) {
  return http.post("/create_letter_case.php", data);
}

export function arquiveProtocoloSub(data) {
  return http.post("/arquive_protocolo_submissao.php", data);
}

export function getArqProSub(data) {
  return http.post("/get_prot_sub.php", data);
}

export function getProgressTable() {
  return http.get("/get_progress_table.php");
}

// export function getProgressCases() {
//   const user = auth.getCurrentUser();
//   const progress = user.data.id;
//   return http
//     .post("/get_prog_cases.php", progress)
//     .then((res) => res.data[0].progress);
// }

export function getAtributeFile(data) {
  return http.post("/get_atribute.php", data).then((res) => res.data[0].role);
}

export function getLicense() {
  return http.get("/get_license.php");
}

export function getCreateFile(data) {
  return http.post("/get_create_file.php", data);
}

export function getCreateLetter(data) {
  return http.post("/get_create_letter.php", data);
}

export function getLicenseLetter() {
  return http.get("/get_license_letter.php");
}

export function arquiveRupe(data) {
  return http.post("/arquive_rupe.php", data);
}

export function getAnexoRupe(data) {
  return http.post("/get_arquive_rupe.php", data);
}

export function getAnexoRupeName(data) {
  return http.post("/get_rupe_name.php", data);
}

export function arquiveNotaDeb(data) {
  return http.post("/arquive_nota_deb.php", data);
}

export function getAnexoNotaDeb(data) {
  return http.post("/get_arquive_nota_deb.php", data);
}

export function getAnexoNotaDebName(data) {
  return http.post("/get_nota_name.php", data);
}

export function financeOne(data) {
  return http.post("/dp_financas_one.php", data);
}

export function updateCicleOne(data) {
  return http.post("/update_clicle_one.php", data);
}

// Files

export function deleteOpenFile(data) {
  return http.post("/delete_openfile.php", data);
}

export function getOpenFiles(data) {
  return http.post("/get_openfile.php", data);
 
}

export function recordOpenfile(data) {
  return http.post("/insert_openfile.php", data);
}

export function arquiveLic(data) {
  return http.post("/arquive_lic.php", data);
}

export function getAnexoLic(data) {
  return http.post("/get_arquive_lic.php", data);
}

export function getAnexoLicName(data) {
  return http.post("/get_lic_name.php", data);
}

export function operationOne(data) {
  return http.post("/dp_operation_one.php", data);
}

export function updateCicleTwo(data) {
  return http.post("/update_clicle_two.php", data);
}

export function getDates(data) {
  return http.post("/get_dates.php", data);
}

export function arquiveFactRec(data) {
  return http.post("/arquive_factura_recibo.php", data);
}

export function getAnexoFacRec(data) {
  return http.post("/get_arquive_fac_rec.php", data);
}

export function getAnexoFacRecName(data) {
  return http.post("/get_fac_rec_name.php", data);
}

export function financeTwo(data) {
  return http.post("/dp_financas_two.php", data);
}

export function updateCicleClose(data) {
  return http.post("/update_clicle_close.php", data);
}

export function getLetter() {
  return http.get("/get_letter.php");
}

export function filterSectorLetter() {
  return http.get("/get_colums_sectorletter.php");
}

export function createLetter(data) {
  return http.post("/create_letter.php", data);
}

export function financeOneLetter(data) {
  return http.post("/dp_financas_one_letter.php", data);
}

export function updateCicleOneLetter(data) {
  return http.post("/update_clicle_letter_one.php", data);
}

export function deleteOpenLetter(data) {
  return http.post("/delete_openletter.php", data);
}

export async function getOpenLetter(data) {
  const res = await http.post("/get_openletter.php", data);
  return res.data;
}

export function recordOpenletter(data) {
  return http.post("/insert_openletter.php", data);
}

export function operationOneLetter(data) {
  return http.post("/dp_operation_one_letter.php", data);
}

export function updateCicleTwoLetter(data) {
  return http.post("/update_clicle_letter_two.php", data);
}

export function getDatesLetter(data) {
  return http.post("/get_dates_letter.php", data);
}

export function financeTwoLetter(data) {
  return http.post("/dp_financas_two_letter.php", data);
}

export function updateCicleCloseLetter(data) {
  return http.post("/update_clicle_close_letter.php", data);
}

export function getFileData() {
  return http.get("/get_file_data.php");
}
export function getLetterData() {
  return http.get("/get_letter_data.php");
}

export function getControlItems() {
  return http.get("/get_control_item.php");
}

export function getNotasPendentes(data) {
  return http.post("/get_notas_pendentes.php", data);
}

export function getNotasCartasPendentes(data) {
  return http.post("/get_notas_cartas_pendentes.php", data);
}

export function getLicencasPendentes(data) {
  return http.post("/get_licencas_pendentes.php", data);
}

export function getLicencasCartasPendentes(data) {
  return http.post("/get_licencas_cartas_pendentes.php", data);
}

export function getArquive() {
  return http.get("/get_arquive.php");
}
export function getFileArquive() {
  return http.get("/get_file_arquive.php");
}
export function delPDF(data) {
  return http.post("/delete_pdf.php", data);
}

export function getOpenFileName(data){
  return http.post("/get_open_file_name.php", data)
}

export function getClientRef(data) {
  return http.post("/trackstatus.php", data);
}


export function getDespesasFile(data){
  return http.post("/get_file_des.php",data);
}

export function getDespesasLetter(data){
  return http.post("/get_letter_des.php",data);
}

export function getFilesLetter(){
  return http.get("/get_files_letter.php");
}

export function arquiveRec(data) {
  return http.post("/arquive_recibo.php", data);
}


export function getAnexoProtName(data) {
  return http.post("/get_pro_name.php", data);
}

export function getAnexoRecName(data) {
  return http.post("/get_rec_name.php", data);
}

export function getEditFiles() {
  return http.post("/get_edit_file.php");
}

export function getDespesas(data){ 
  return http.post("/get_despesas.php", data);
}

export function getEditLetter(){
  return http.get("/get_edit_letter.php");
}

export function updateCreateFile(data){
  return http.post("/update_create_file.php", data);
}

export function updateCreateLetter(data){
  return http.post("/update_create_letter.php", data);
}

export function updateFinanceOne(data){
  return http.post("/update_finance_one.php", data);
}

export function updateFinanceOneLetter(data){
  return http.post("/update_finance_one_letter.php", data);
}

export function updateOperationOne(data){
  return http.post("/update_operation_one.php", data)
}

export function updateOperationOneLetter(data){
  return http.post("/update_operation_one_letter.php", data);
}

export function updateFinanceTwo(data){
  return http.post("/update_finance_two.php",data);
}

export function updateFinanceTwoLetter(data){
  return http.post("/update_finance_two_letter.php",data);
}

export function getSectorLicense(data) {
  return http.post("/get_sector_license.php", data);
}

export function getSectorLetter(data) {
  return http.post("/get_sector_letter.php", data);
}