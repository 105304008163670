import React from "react";
import { useForm } from "react-hook-form";
import Input from "../common/input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    company: yup.string().required("Campo Obrigatório"),
    nif: yup.string().required("Campo Obrigatório"),
    email: yup
      .string()
      .required("Campo Obrigatório")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Preencher com Valido Email"
      ),
    // phone: yup.string().matches(/[0-9]/, "Telefone Valido").min(9, "Telefone tem 9 digitos").max(9, "Telefone tem 9 digitos"),
  });

  const ClientForm = ({ onSubmit, lableButton, defaultValues, disabled, buttonClass, but, }) => {

    const { register, handleSubmit,formState: { errors } } = useForm({ resolver: yupResolver(schema),  defaultValues: defaultValues, });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body shadow p-3">
          <div className="row mb-3">
            {/* Empresa */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="company"
                  type="text"
                  errors={errors}
                  label="Empresa"
                  placeholder="Empresa"
                  disabled={disabled.company}
                />
              </div>
            </div>
            {/* Nif */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="nif"
                  type="text"
                  errors={errors}
                  label="NIF"
                  placeholder="NIF"
                  disabled={disabled.nif}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            {/* Email */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="email"
                  type="text"
                  errors={errors}
                  label="Endereço Email"
                  placeholder="Endereço Email"
                  disabled={disabled.email}
                />
              </div>
            </div>
            {/* Telefone */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="phone"
                  type="text"
                  errors={errors}
                  label="Telefone"
                  placeholder="Telefone"
                  disabled={disabled.phone}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="form-floating mb-3 mb-md-0">
                <textarea
                  cols="50"
                  rows="5"
                  className="form-control"
                  id="inputAddress"
                  type="text"
                  {...register("address")}
                  placeholder="Create a Address"
                  disabled={disabled.address}
                ></textarea>
                <label htmlFor="inputAddress">Morada</label>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-0">
            <div className="d-grid">
              <button type="submit" hidden={but} className={buttonClass}>
                {lableButton}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ClientForm;
