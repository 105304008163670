import React from "react";

function Input({ id, label, errors, register, disabled,defaultValue, name, ...rest }) {
  return (
    <div>
      <div className="form-floating">
        <input
          className="form-control"
          {...rest}
          name={name}
          {...register(name)}
          disabled={disabled}
          defaultValue={defaultValue}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      <p style={{ color: "red" }}>{errors && errors[name]?.message}</p>
    </div>
  );
}

export default Input;
