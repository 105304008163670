import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { Button} from "react-bootstrap";
import "../../styles/fileUpload.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Alert from "react-bootstrap/Alert";
import Spinner from "./spinner";

const schemas = yup.object().shape({
  file: yup
    .mixed()
    .test("required", "É necessario carregar um ficheiro !!!", (value) => {
      return value && value.length;
    })
    .test("fileSize", "O ficheiro é muito grande !!!", (value, context) => {
      return value && value[0] && value[0].size <= 256000;
    })
    .test("type", "Ficheiros suportados pdf !!!", function (value) {
      return value && value[0] && value[0].type === "application/pdf";
    })
    .test("Unique", "Este ficheiro esta duplicado !!!", (values) => {
      return new Set(values).size === values.length;
    }),
});

const FileUpload = ({
  files,
  setFiles,
  titulo,
  casenumber,
  arquive,
  setShowDatas,
  showDatas,
  filenumber,
}) => {
  const [sucess, setSucess] = useState(false);
  const [load, setLoad] = useState(false);


  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemas),
  });

  const uploadHandler = (event) => {
    let file = event.target.files[0];
    if (!file) return;
    // Set the files array to contain only the new file
    setFiles([file]);
  };


  const onSubmit = (data, e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("case", casenumber);
    formdata.append("filenumber", filenumber);
    for (let i = 0; i < files.length; i++) {
      formdata.append("file[]", files[i]);
    }
    setLoad(true);
    arquive(formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.data === "true") {
        setSucess(true);
        setShowDatas(true);
        setLoad(false);
      }
    });
  };

  return (
    <>
      <Alert
        show={sucess}
        variant="success"
        dismissible
        onClose={() => (window.location.href = "/arquive")}
      >
        Ficheiros carregados com sucesso!!!
      </Alert>
      <Spinner active={load} />
      {!showDatas ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="file-card">
            <div className="file-inputs">
              <input
                {...register("file")}
                type="file"
                accept="application/pdf"
                onChange={uploadHandler}
              />
              <button>
                <i>
                  <FontAwesomeIcon icon={faPlus} />
                </i>
                Carregar Ficheiros
              </button>
            </div>
            <input
              className="form-control mb-3"
              type="text"
              value={casenumber}
              {...register("case")}
              style={{ display: 'none' }}
              autoFocus
            />
            <input
              className="form-control mb-3"
              type="text"
              value={filenumber}
              {...register("filenumber")}
              style={{ display: 'none' }}
              autoFocus
              
            />
            <p className="main">{titulo}</p>
            <small className="main">Ficheiros Suportados</small>
            <small className="info">PDF (250KB)</small>
            <div className="row">
              <div className="col">
                <Button
                  onMouseOver={() => clearErrors(["newFile"])}
                  variant="outline-success"
                  type="submit"
                >
                  Carregar
                </Button>
              </div>
              <div className="col">
                <Button
                  onClick={() => reset()}
                  variant="outline-danger"
                  type="button"
                >
                  Apagar
                </Button>
              </div>
            </div>
          </div>
          <br />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors.file?.message}
          </p>
        </form>
      ) : null}
    </>
  );
};

export default FileUpload;
