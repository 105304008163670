import React, { useState } from "react";
import InvoiceFile from "./invoicefile";
import Debit from "./debitfile";
import DebitInvoiceFile from "./debitInvoicefile";

function InvoiceDebitFile() {
  const [invoice, setInvoice] = useState();
  const [debit, setDebit] = useState();
  const [both, setBoth] = useState();

  const handleChange = (data) => {
    if (data === "invoice") {
      setInvoice(true);
    } else {
      setInvoice(false);
    }
    if (data === "debit") {
      setDebit(true);
    } else {
      setDebit(false);
    }
    if (data === "both") {
      setBoth(true);
    } else {
      setBoth(false);
    }
  };

  return (
    <div className="container-fluid component">
      <div className="card mt-4">
        <div className="card-header">
          <div className="col-md-12">
            <h1>
              <i className="far fa-file-alt" aria-hidden="true"></i>
              <span className="ml-3"></span> Relatório Sector License
            </h1>
          </div>
        </div>
        <div className="card-body">
          <div className="col-md-4">
            <select
              type="select"
              className="form-select"
              onChange={(e) => handleChange(e.target.value)}
            >
              <option value="">Tipo Relatorio</option>
              <option value="invoice">Relatorio Faturas</option>
              <option value="debit">Relatorio Debito</option>
              <option value="both">Relatorio Faturas & Debito</option>
            </select>
          </div>
          <hr />
          {invoice ? <InvoiceFile /> : null}
          {debit ? <Debit /> : null}
          {both ? <DebitInvoiceFile /> : null}
        </div>
      </div>
    </div>
  );
}

export default InvoiceDebitFile;
