import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { getNotasPendentes, getNotasCartasPendentes } from "../services/source";
import { useForm } from "react-hook-form";
import PDFControl from "./controlpdf";
import Alert from "react-bootstrap/Alert";

function ControlNotas() {
  const [result, setResult] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [processo, setProcesso] = useState(false);
  const [comment, setComment] = useState([]);
  const [showpdf, setShowPdf] = useState(false);
  const [danger, setDanger] = useState(false);
  const [cartas, setCartas] = useState(false);
  const [licensas, setLicensas] = useState(false);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (data.choose === "Licensas") {
      setProcesso(data.service);
      getNotasForPendentes(data);
    } else if (data.choose === "Cartas") {
      setProcesso(data.service);
      getNotasForCartasPendentes(data);
    }
  };

  const secondOnSubmit = (data) => {
    setComment(data);
    setShowPdf(true);
    setShowTable(false);
  };

  async function getNotasForPendentes(data) {
    const result = await getNotasPendentes(data);
    const resNotas = result.data;

    if (resNotas.length === 0) {
      setDanger(true);
      setShowTable(false);
    } else {
      setResult(resNotas);
      setShowTable(true);
    }
  }

  async function getNotasForCartasPendentes(data) {
    const result = await getNotasCartasPendentes(data);
    const resNotas = result.data;
    if (resNotas.length === 0) {
      setDanger(true);
      setShowTable(false);
    } else {
      setResult(resNotas);
      setShowTable(true);
    }
  }

  let today = new Date();

  const displayNotas = result.map((n, index) => {
    let opencase = new Date(n.opencasedate);

    const timeDiff = today.getTime() - opencase.getTime();
    const total = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return (
      <tr key={index}>
        <td>{n.filenumber}</td>
        <td>{n.refimpor}</td>
        <td>{n.importador}</td>
        <td>{n.service}</td>
        <td>{n.nif}</td>
        <td>{n.datesubmit}</td>
        <td>{n.valorfactimpor}</td>
        <td>{n.moeda}</td>
        <td>{total}</td>
      </tr>
    );
  });

  const handleClick = (data) => {
    if (data === "Cartas") {
      setCartas(true);
    } else if (data === "Licensas") {
      setLicensas(true);
    } else if (data === "Inicio") {
      setShowTable(false);
      setCartas(false);
      setLicensas(false);
    }
  };

  const handleClose = () => {
    setDanger(false);
    setCartas(false);
    setLicensas(false);
    window.location.reload();
  };

  return (
    <div>
      <div className="container-fluid component">
        <div
          id="layoutSidenav_content"
          style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <div className="container-fluid px4">
              <h1 className="mt-4">Processos Pendentes Que Aguardam Notas</h1>
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active">Processos Notas</li>
              </ol>
              <div className="card mb-4 mt-4">
                <div className="card-header">
                  <i className="fa fa-table me-1"></i>
                  Tabela de notas Pendentes
                </div>
                <div className="card-body">
                  <div className="dataTable-top">
                    {!showpdf ? (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {!danger ? (
                          <div className="row ">
                            <div className="col-3">
                              <select
                                className="form-select"
                                {...register("choose")}
                                name="choose"
                                onChange={(e) => {
                                  handleClick(e.target.value);
                                }}
                              >
                                <option value="Inicio">Selecionar</option>
                                <option value="Licensas">
                                  Licensas Sectoriais
                                </option>
                                <option value="Cartas">
                                  Cartas de Isençāo
                                </option>
                              </select>
                            </div>
                            {cartas || licensas ? (
                              <div className="col-3 mb-3">
                                <div>
                                  <select
                                    className="form-select"
                                    name="service"
                                    {...register("service")}
                                  >
                                    <option>Selecionar Serviço</option>
                                    <option value="INGA">INGA</option>
                                    {/* <option value="INACOM">INACOM</option> */}
                                    <option value="IRDP">IRDP</option>
                                    {/* <option value="INIQ">INIQ</option> */}
                                    <option value="MINSA">MINSA</option>
                                    <option value="MININT">MININT</option>
                                    <option value="MIREMPET">MIREMPET</option>
                                  </select>
                                </div>
                              </div>
                            ) : null}
                            {cartas || licensas ? (
                              <div className="col-2">
                                <div className="form-floating">
                                  <button
                                    type="submit"
                                    className="btn btn-outline-success"
                                  >
                                    Pesquisar
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </form>
                    ) : null}
                  </div>

                  <Alert
                    show={danger}
                    variant="danger"
                    dismissible
                    onClose={handleClose}
                  >
                    Não existem processos pendentes !!!!
                  </Alert>

                  {showTable ? (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>N File</th>
                          <th>N Factura</th>
                          <th>Importador</th>
                          <th>Designação</th>
                          <th>NIF</th>
                          <th>Data Submissão</th>
                          <th>Valor Factura</th>
                          <th>Moeda</th>
                          <th>Dias Espera</th>
                        </tr>
                      </thead>
                      <tbody>{displayNotas}</tbody>
                    </Table>
                  ) : null}
                  {showTable ? (
                    <form onSubmit={handleSubmit(secondOnSubmit)}>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="form-floating mb-3 mb-md-0">
                            <input
                              className="form-control"
                              id="comentarios"
                              type="text"
                              {...register("comentarios")}
                              placeholder="Comentários"
                            />
                            <label htmlFor="comentarios">Comentários:</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="form-floating mb-3 mb-md-0">
                            <input
                              className="form-control"
                              id="preparado"
                              type="text"
                              {...register("preparado")}
                              placeholder="preparado"
                            />
                            <label htmlFor="preparado">
                              Preparado Por (Nome):
                            </label>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-floating mb-3 mb-md-0">
                            <input
                              className="form-control"
                              id="data"
                              type="text"
                              {...register("data")}
                              placeholder="data"
                            />
                            <label htmlFor="data">Data & Hora:</label>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-floating mb-3 mb-md-0">
                            <input
                              style={{ textAlign: "center" }}
                              className="form-control"
                              id="celula"
                              type="text"
                              {...register("visto")}
                              placeholder="visto"
                            />
                            <label htmlFor="visto">
                              Visto por (Nome & Assinatura)
                            </label>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-floating mb-3 mb-md-0">
                            <input
                              className="form-control"
                              id="contact"
                              type="text"
                              {...register("contact")}
                              placeholder="contact"
                            />
                            <label htmlFor="contactos">Contactos:</label>
                          </div>
                        </div>
                      </div>

                      {showTable ? (
                        <div>
                          <button className="btn btn-outline-success">
                            Exportar
                          </button>
                        </div>
                      ) : null}
                    </form>
                  ) : null}
                  {showpdf ? (
                    <div className="row mb-12">
                      <div className="col-md-2">
                        <button
                          className="btn btn-danger"
                          onClick={() => window.location.reload(false)}
                        >
                          <i className="fa fa-times-circle">&nbsp;Fechar</i>
                        </button>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  {showpdf ? (
                    <PDFControl
                      title="NOTAS"
                      result={result}
                      commnet={comment}
                      processo={processo}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ControlNotas;
