import React, { useState, useEffect } from "react";
import FileUpload from "./common/fileUpload";
import FileList from "./common/fileList";
import { arquiveNotaDeb, getAnexoNotaDeb } from "../services/source";

function FileUploadNotaDeb({ data, handleCloseNotaDeb }) {
  const [files, setFiles] = useState([]);
  const [showDatas, setShowDatas] = useState(false);

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  useEffect(() => {
    async function getAnexo() {
      const result = await getAnexoNotaDeb(data);
      setShowDatas(result.data);
    }
    getAnexo();
  }, []);

  useEffect(() => {
    if (showDatas) {
      handleCloseNotaDeb();
    }
  }, [showDatas, handleCloseNotaDeb]);

  return (
    <>
      <FileUpload
        files={files}
        setFiles={setFiles}
        titulo="Nota Debito"
        casenumber={data.idfile}
        arquive={arquiveNotaDeb}
        setShowDatas={setShowDatas}
        showDatas={showDatas}
        filenumber={data.filenumber}
      />
      <FileList files={files} removeFile={removeFile} />
    </>
  );
}

export default FileUploadNotaDeb;
