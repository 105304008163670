import React from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const CurrencyInput = ({
  label,
  name,
  control,
  register,
  options,
  errors,
  defaultValue = "",
  suffix = "",
  ref,
  ...rest
}) => {
  return (
    <div>
      <small>
        <label className="breadcrumb-item active text-wrap" htmlFor={name}>
          {label}
        </label>
      </small>
      <div className="input-group">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <NumericFormat
              {...field}
              className="form-control text-end"
              thousandsGroupStyle="thousand"
              decimalSeparator="."
              displayType="form-control"
              type="text"
              thousandSeparator={true}
              allowNegative={true}
              fixedDecimalScale={true}
              decimalScale={2}
              valueIsNumericString={true}
              {...rest}
            />
          )}
        />

        {options && (
          <span className="input-group-text">
            <select
              {...register(options.name)}
              style={{
                border: "none",
                backgroundColor: "#e9ecef",
              }}
            >
              {options.values.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </span>
        )}
        {suffix && <span className="input-group-text">{suffix}</span>}
      </div>
      <p style={{ color: "red" }}>{errors?.[name]?.message}</p>
    </div>
  );
};
export default CurrencyInput;
