import React, { useState, useEffect } from "react";
import FileUpload from "./common/fileUpload";
import FileList from "./common/fileList";
import { arquiveRupe, getAnexoRupe } from "../services/source";

function FileUploadRupe({data, handleCloseRupe}) {

  const [files, setFiles] = useState([]);
  const [showDatas, setShowDatas] = useState(false);

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  useEffect(() => {
    async function getAnexo() {
      const result = await getAnexoRupe(data);
      setShowDatas(result.data);
    }
    getAnexo();
  }, []);

  useEffect(() => {
    if (showDatas) {
      handleCloseRupe();
    }
  }, [showDatas, handleCloseRupe]);

  return (
    <>
      <FileUpload
        files={files}
        setFiles={setFiles}
        titulo="Rupe"
        casenumber={data.idfile}
        arquive={arquiveRupe}
        showDatas={showDatas}
        setShowDatas={setShowDatas}
        filenumber={data.filenumber}
      />
      <FileList files={files} removeFile={removeFile} />
    </>
  );
}

export default FileUploadRupe;
