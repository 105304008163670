import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UseModal from "../common/useModal";
import EditContact from "../editContact";
import ViewContact from "../viewContact";
import DelContact from "../delContact";
// import DelImportador from "./delImportador";
import ReactPaginate from "react-paginate";
import { getContact } from "../../services/source";

function ContactTable() {
  const [contact, setContact] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [view, setView] = useState(false);
  const [viewdata, setViewData] = useState({});
  const [edit, setEdit] = useState(false);
  const [editdata, setEditData] = useState({});
  const [deldata, setDelData] = useState({});
  const [del, setDel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function getListContact() {
      const result = await getContact();
      setContact(result.data);
    }
    getListContact();
  }, []);

  //Table Options
  const handleView = (view) => {
    setView(true);
    setViewData(view);
  };

  const handleEdit = (edit) => {
    setEdit(true);
    setEditData(edit);
  };

  const handleDelete = (del) => {
    setDel(true);
    setDelData(del);
  };

  //pagination
  const clientsPerPage = 15;
  const pageVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(
    contact.filter((contact) => {
      if (searchTerm === "") {
        return contact;
      } else if (
        contact.firstName.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return contact;
      } else if (
        contact.lastName.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return contact;
      } else if (
        contact.email.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return contact;
      } else if (
        contact.company.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return contact;
      } else if (
        contact.phone1.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return contact;
      } else if (
        contact.phone2.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return contact;
      }
      return false;
    }).length / clientsPerPage
  );

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayContact = contact
    .filter((val) => {
      if (searchTerm === "") {
        return val;
      } else if (
        val.firstName.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      } else if (
        val.lastName.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      } else if (val.company.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      } else if (val.phone1.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      } else if (val.phone2.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      }
      return null;
    })
    .slice(pageVisited, pageVisited + clientsPerPage)
    .map((c) => {
      return (
        <tr key={c.id}>
          <td>{c.id}</td>
          <td>{c.firstName}</td>
          <td>{c.lastName}</td>
          <td>{c.email}</td>
          <td>{c.company}</td>
          <td>{c.phone1}</td>
          <td>{c.phone2}</td>
          <td>
            <DropdownButton
              id="dropdown-basic-button"
              title="Opções"
              variant="secundary"
            >
              <Dropdown.Item href="#/visualizar" onClick={() => handleView(c)}>
                <i className="fa fa-eye"> Visualizar</i>
              </Dropdown.Item>

              <UseModal
                size={"xl"}
                show={view}
                close={() => setView(false)}
                body={<ViewContact {...viewdata} />}
                title="Contacto"
              />

              <Dropdown.Item href="#/editar" onClick={() => handleEdit(c)}>
                <i className="fa fa-edit"> Editar</i>
              </Dropdown.Item>

              <UseModal
                show={edit}
                size={"lg"}
                close={() => setEdit(false)}
                body={<EditContact {...editdata} />}
                title="Editar"
              />

              <Dropdown.Item href="#/apagar" onClick={() => handleDelete(c)}>
                <i className="fa fa-trash"> Apagar</i>
              </Dropdown.Item>

              <UseModal
                show={del}
                close={() => setDel(false)}
                body={<DelContact {...deldata} />}
                title="Apagar"
              />
            </DropdownButton>
          </td>
        </tr>
      );
    });

  return (
    <div>
      <div className="dataTable-top">
        <div className="dataTable-search">
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setSearchTerm(e.target.value);
                changePage({ selected: 0 });
              }}
              placeholder="Procurar..."
              aria-label="Search for..."
              aria-describedby="btnNavbarSearch"
            />
          </div>
        </div>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Apelido</th>
            <th>Email</th>
            <th>Empresa</th>
            <th>Telefone</th>
            <th>Telefone</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>{displayContact}</tbody>
      </Table>
      <div className="pagination justify-content-center">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"page-item disabled"}
          activeClassName={"page-item active"}
          pageLinkClassName={"page-link"}
        />
      </div>
    </div>
  );
}

export default ContactTable;
