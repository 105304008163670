import React, {useState} from "react";
import auth from "../services/auth";
import { Alert } from "react-bootstrap";

function DelUser(data) {
    
  const [danger, setDanger] = useState(false);

  const user = auth.getCurrentUser();

  const onSubmit = () => {
    if (user.data.email === data.email) {
      setDanger(true);
    } else {
      auth.deleteUser(data);
    }
  };

  return (
    <div className="text-center">
      <Alert
        show={danger}
        variant="danger"
        dismissible
        onClose={() => setDanger(false)}
      >
        Não é possivel apagar o utilizador com o login efectuado!!!
      </Alert>
      <p>
        Tem a certeza que quer apagar permanentemente o utilizador{" "}
        <strong>
          {data.nome} {data.apelido}
        </strong>
        .
      </p>
      <button className="btn btn-danger m-2" onClick={onSubmit}>
        Apagar
      </button>
    </div>
  );
}

export default DelUser;
