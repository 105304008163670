import React, { useState, useEffect } from "react";
import "../styles/trackcase.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

import "../styles/trackcase.css";

function TrackCase() {
  const location = useLocation();
  const refFile = location.state.filt;

  const [selectedValue, setSelectedValue] = useState({});



  useEffect(() => {
    setSelectedValue(refFile);
  }, [refFile]);

  const handleChange = (event) => {
    let ser = event.target.value;
    let ob = refFile.filter((e) => e.service === ser);
    setSelectedValue(ob);
  };

  return (
    <div className="container-fluid component">
      <div className="card" style={{ boxShadow: "0px 0px rgb(0,0,0)" }}>
        <div className="flex-container">
          <div className="flex-item-1">
            <Link to={"/track"}>
              <i
                className="fa fa-arrow-circle-left fa-lg"
                aria-hidden="true"
              ></i>
            </Link>
          </div>
          <div className="flex-item-2">
            <h1 style={{ textAlign: "center" }}>Track Cases</h1>
          </div>
          <div className="flex-item-3">
            <img
              src={process.env.PUBLIC_URL + "assets/img/cads.png"}
              alt="cads"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="container-fliuid" style={{ marginTop: "24px" }}>
        <div
          className="card"
          style={{
            paddingBottom: "1%",
            marginTop: "38px",
            marginRight: "119px",
            marginBottom: "0px",
            marginLeft: "116px",
            bordeRadius: "22px",
            borderWith: "1px",
            boxShadow: "inset 1px -2px 6px 0px rgb(55,56,57)",
            backgroundColor: "#f3f0e7",
          }}
        >
          <div className="card-body row">
            <div className="row">
              <div className="col">
                <strong>Referência Cliente</strong>
              </div>
              <div className="col">
                <strong>Factura Importaçāo</strong>
              </div>
              <div className="col">
                <strong>Cliente</strong>
              </div>
              <div className="col">
                <strong>Case Number</strong>
              </div>

              <div className="col-2">
                <strong>Servicos</strong>
              </div>
            </div>
            {/* ######## Cabecalho ############ */}

            {selectedValue.length > 0 && (
              <div className="row">
                <div className="col">
                  <small style={{ color: "#ec4e05" }}>
                    {selectedValue[0].refclient}
                  </small>
                </div>
                <div className="col">
                  <small style={{ color: "#ec4e05" }}>
                    {selectedValue[0].refimpor}
                  </small>
                </div>
                <div className="col">
                  <small style={{ color: "#ec4e05" }}>
                    {selectedValue[0].clients}
                  </small>
                </div>
                <div className="col">
                  <small style={{ color: "#ec4e05" }}>
                    {selectedValue[0].filenumber}
                  </small>
                </div>
                <div className="col-2">
                  {refFile.length > 1 ? (
                    <select
                      className="form-select"
                      style={{
                        boxShadow: "inset 1px -3px 6px rgb(82,86,89)",
                        display: "flex",
                        position: "relative",
                        paddingRight: "36px",
                        marginRight: "13px",
                        marginTop: "4px",
                        marginBottom: "6px",
                        backgroundColor: "#f3f0e7",
                      }}
                      value={selectedValue.service}
                      onChange={handleChange}
                    >
                      <optgroup>
                        {refFile.map((option, index) => (
                          <option key={index} value={option.service}>
                            {option.service}
                          </option>
                        ))}
                      </optgroup>
                    </select>
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      style={{
                        boxShadow: "inset 1px -3px 6px rgb(82,86,89)",
                        display: "flex",
                        position: "relative",
                        paddingRight: "36px",
                        marginRight: "13px",
                        marginTop: "4px",
                        marginBottom: "6px",
                        backgroundColor: "#f3f0e7",
                      }}
                      value={refFile[0].service}
                      disabled
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ################ Line Track ################### */}
        <div className="container-fluid" style={{ marginTop: "24px" }}>
          {selectedValue.length > 0 && (
            <div
              className="d-flex track"
              style={{ marginTop: "98px", marginBottom: "0" }}
            >
              {/* Data Submissao */}
              <div
                className={`step ${
                  selectedValue[0].opencasedate ? "active" : ""
                }`}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-calendar-check-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                  </svg>
                </span>
                <span className="text">Data Submissão</span>
                {selectedValue[0].opencasedate ? (
                  <div>
                    <span
                      className="text"
                      style={{ fontWeight: "bold", color: "#ff5722" }}
                    >
                      <small>{selectedValue[0].opencasedate}</small>
                    </span>
                  </div>
                ) : null}
              </div>
              {/* Pronunciamento Ministerio */}

              <div
                className={`step ${selectedValue[0].datepro ? "active" : ""}`}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-building"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                    <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                  </svg>
                </span>
                <span className="text">Pronunciamento Ministerio</span>

                {selectedValue[0].datepro ? (
                  <div>
                    <span
                      className="text"
                      style={{ fontWeight: "bold", color: "#ff5722" }}
                    >
                      <small>{selectedValue[0].datepro}</small>
                    </span>
                  </div>
                ) : null}

                {selectedValue[0].filenamesub ? (
                  <div>
                    <span className="text" style={{ fontWeight: "bold" }}>
                      {" "}
                      Protocolo Submissão
                    </span>
                    <Link
                      to={
                        process.env.REACT_APP_API_URL +
                        "/uploads/" +
                        selectedValue[0].filenamesub
                      }
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{
                          fontSize: "150%",
                          color: "#ff5722",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      ></FontAwesomeIcon>
                    </Link>
                  </div>
                ) : null}
              </div>

              {/* Solicitação de Pagamento */}
              <div
                className={`step ${
                  selectedValue[0].datecobmin ? "active" : ""
                }`}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-currency-dollar"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                  </svg>
                </span>
                <span className="text">Solicitação de Pagamento</span>
                {selectedValue[0].datecobmin ? (
                  <div>
                    <span
                      className="text"
                      style={{ fontWeight: "bold", color: "#ff5722" }}
                    >
                      <small>{selectedValue[0].datecobmin}</small>
                    </span>
                  </div>
                ) : null}

                {selectedValue[0].filenamerupe ? (
                  <div>
                    <span className="text" style={{ fontWeight: "bold" }}>
                      RUPE
                    </span>
                    <Link
                      to={
                        process.env.REACT_APP_API_URL +
                        "/uploads/" +
                        selectedValue[0].filenamerupe
                      }
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{
                          fontSize: "150%",
                          color: "#ff5722",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      ></FontAwesomeIcon>
                    </Link>
                  </div>
                ) : null}
                <div>
                  {selectedValue[0].pagamentoRupe === "cads" ? (
                    <div>
                      <span className="text" style={{ fontWeight: "bold" }}>
                        Nota
                      </span>
                      <Link
                        to={
                          process.env.REACT_APP_API_URL +
                          "/uploads/" +
                          selectedValue[0].filenamenotadeb
                        }
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          style={{
                            fontSize: "150%",
                            color: "#ff5722",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                        ></FontAwesomeIcon>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* >Pagamento Efectuado */}
              <div
                className={`step ${
                  selectedValue[0].datapagcads
                    ? "active"
                    : "" || selectedValue[0].datapagcli
                    ? "active"
                    : ""
                }`}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check2-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                    <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                  </svg>
                </span>
                <span className="text">Pagamento Efectuado</span>
                {selectedValue[0].pagamentoRupe ? (
                  <div>
                    <span
                      className="text"
                      style={{ fontWeight: "bold", color: "#ff5722" }}
                    >
                      <small>
                        {selectedValue[0]?.datapagcads  ||
                          setSelectedValue[0]?.datapagcli }
                      </small>
                    </span>
                  </div>
                ) : null}
              </div>

              {/*  Licensa Recebida */}
              <div
                className={`step ${
                  selectedValue[0].datereclic ? "active" : ""
                }`}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-receipt"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </span>
                <span className="text">Licensa Recebida</span>
                {selectedValue[0].datereclic ? (
                  <div>
                    <span
                      className="text"
                      style={{ fontWeight: "bold", color: "#ff5722" }}
                    >
                      <small>{selectedValue[0].datereclic}</small>
                    </span>
                  </div>
                ) : null}
              </div>

              {/* Licensa Enviada Cliente */}
              <div
                className={`step ${selectedValue[0].datenvlic ? "active" : ""}`}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-send"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                  </svg>
                </span>
                <span className="text">Licensa Enviada Cliente</span>
                <div>
                  {selectedValue[0].datenvlic ? (
                    <span
                      className="text"
                      style={{ fontWeight: "bold", color: "#ff5722" }}
                    >
                      <small>{selectedValue[0].datenvlic}</small>
                    </span>
                  ) : null}
                  {selectedValue[0].filenamelic ? (
                    <div>
                      <span className="text" style={{ fontWeight: "bold" }}>
                        Licensa
                      </span>

                      <Link
                        to={
                          process.env.REACT_APP_API_URL +
                          "/uploads/" +
                          selectedValue[0].filenamelic
                        }
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          style={{
                            fontSize: "150%",
                            color: "#ff5722",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                        ></FontAwesomeIcon>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Factura */}
              <div
                className={`step ${selectedValue[0].datefact ? "active" : ""}`}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-file-earmark-pdf"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                    <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                  </svg>
                </span>
                <span className="text">Factura</span>
                {selectedValue[0].datefact ? (
                  <div>
                    <span
                      className="text"
                      style={{ fontWeight: "bold", color: "#ff5722" }}
                    >
                      <small>{selectedValue[0].datefact}</small>
                    </span>
                  </div>
                ) : null}

                {selectedValue[0].filenamefacrec ? (
                  <div>
                    <span className="text" style={{ fontWeight: "bold" }}>
                      Fatura
                    </span>
                    <Link
                      to={
                        process.env.REACT_APP_API_URL +
                        "/uploads/" +
                        selectedValue[0].filenamefacrec
                      }
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{
                          fontSize: "150%",
                          color: "#ff5722",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      ></FontAwesomeIcon>
                    </Link>
                  </div>
                ) : null}
                <div>
                  {selectedValue[0].filenamerec ? (
                    <div>
                      <span className="text" style={{ fontWeight: "bold" }}>
                        Recibo
                      </span>
                      <Link
                        to={
                          process.env.REACT_APP_API_URL +
                          "/uploads/" +
                          selectedValue[0].filenamerec
                        }
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          style={{
                            fontSize: "150%",
                            color: "#ff5722",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                        ></FontAwesomeIcon>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TrackCase;
