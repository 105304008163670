import React from "react";
import { NumericFormat } from "react-number-format";


function InputDynamic({ element, index, handleChange, removeFormFields, addFormFields }) {
    
  return (
    <div key={index}>
      <div className="input-group mb-3">
        {!index ? (
          <button
            type="button"
            className="btn btn-success"
            onClick={() => addFormFields()}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        ) : null}
        {index ? (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => removeFormFields(index)}
          >
            <i className="fa fa-minus" aria-hidden="true"></i>
          </button>
        ) : null}
        {/* Render the NumericFormat component */}
        <NumericFormat
          name="valor"
          className="form-control text-end"
          thousandsGroupStyle="thousand"
          decimalSeparator="."
          displayType="form-control"
          type="text"
          value={element.valor || ""}
          allowNegative={true}
          thousandSeparator={false}
          fixedDecimalScale={true}
          decimalScale={2}
          valueIsNumericString={true}
          onChange={(e) => handleChange(index, e)}
        />
        <span className="input-group-text">Kz</span>
        <span style={{ marginLeft: "2%" }}></span>
        <input
          type="text"
          name="descricao"
          className="form-control"
          placeholder="Descrição"
          onChange={(e) => handleChange(index, e)}
          value={element.descricao || ""}
        />
      </div>
    </div>
  );
}

export default InputDynamic;
