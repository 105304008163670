import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../services/httpServices";
import { getRefClient } from "../services/source";
import Spinner from "./common/spinner";

function ComposeEmail() {
  const [address, setAddress] = useState([{ para: "" }]);
  const [number, setNumber] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    async function getInvoices() {
      const result = await getRefClient();
      setNumber(result.data);
    }
    getInvoices();
  }, []);


  const schema = yup.object().shape({
    subject: yup.string().required("Campo Obrigatório"),
    file: yup.string().required("Campo Obrigatório"),
  });

  const clearEmail = () => {
    setAddress([{ para: "" }]);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data, e) => {
    setLoad(true);
    const payload = { address, data };
    e.preventDefault();
    await http.post("create_email.php", payload).then((res) => {
      if (res.data) {
        window.location.reload(true);
      setLoad(false);
      } else {
        console.log("false");
      }
    });
  };

  let setAdd = () => {
    setAddress([...address, { para: "" }]);
  };

  let reAdd = (i) => {
    let newAdd = [...address];
    newAdd.splice(i, 1);
    setAddress(newAdd);
  };

  let handleChange = (i, e) => {
    let newEmail = [...address];
    newEmail[i][e.target.name] = e.target.value;
    setAddress(newEmail);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Spinner active={load} />
        <div>
          <div className="">
            {address.map((el, index) => (
              <div key={index}>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    Para :
                  </span>
                  <input
                    type="email"
                    name="para"
                    className="form-control"
                    aria-describedby="basic-addon1"
                    value={el.para}
                    onChange={(e) => handleChange(index, e)}
                  />
                  {!index ? (
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => setAdd()}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  ) : null}
                  {index ? (
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() => reAdd(index)}
                    >
                      <i className="fa fa-minus" aria-hidden="true"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">
            File Number :
          </span>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register("file")}
          >
            <option value=""></option>
            {number.map((i, index) => (
              <option key={i.filenumber} value={i.idfile}>
                {i.refclient}
              </option>
            ))}
          </select>
        </div>
        <p style={{ color: "red" }}>{errors.file?.message}</p>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">
            Assunto :
          </span>
          <input
            {...register("subject")}
            type="text"
            className="form-control"
            aria-describedby="basic-addon1"
          />
        </div>
        <p style={{ color: "red" }}>{errors.subject?.message}</p>

        <div className="input-group mb-3">
          <textarea
            {...register("message")}
            cols="30"
            rows="10"
            className="form-control"
            placeholder="Messagem
            :"
          ></textarea>
        </div>

        <hr />
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button
            className="btn btn-secondary"
            type="reset"
            onClick={() => clearEmail()}
          >
            <i className="fa fa-times"></i> Descartar
          </button>
          <button className="btn btn-success" type="submit">
            <i className="fa fa-paper-plane-o"></i> Enviar
          </button>
        </div>
      </form>
    </div>
  );
}

export default ComposeEmail;




