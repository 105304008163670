import React from "react";

const CheckboxWithLabel = ({
  id,
  label,
  errors,
  register,
  name,
  checked,
  onChange,
}) => {
  return (
    <div>
      <div className="form-check">
        <label htmlFor={id}>{label}</label>
        <input
          name={name}
          type="checkbox"
          className="form-check-input"
          {...register(name)}
          onChange={onChange}
          checked={checked}
        />
      </div>
      <p style={{ color: "red" }}>{errors[name]?.message}</p>
    </div>
  );
};

export default CheckboxWithLabel;
