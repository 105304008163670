import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "./spinner";
import UseModal from "./useModal";
import {updateOperationOneLetter} from "../../services/source"

import { useForm, Controller } from "react-hook-form";

function EditOperationOneLetter(data) {
  const [load, setLoad] = useState(false);
  const [sucess, setSucess] = useState(false);

  registerLocale("pt", pt);

  const datereclic = new Date(data.file[0].datereclic);
  const datenvlic = new Date(data.file[0].datenvlic);
  let id = data.file[0].casenumber;
  let filenumber = data.file[0].filenumber;

  const defaultValues = {
    datereclic: datereclic,
    datenvlic: datenvlic,
  };

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const { control, handleSubmit } = useForm({ defaultValues });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const datenvlic = convert(data.datenvlic);
    const datereclic = convert(data.datereclic);
    const payload = {
      id,
      filenumber,
      datenvlic,
      datereclic,
    };
    setLoad(true);
    await updateOperationOneLetter(payload).then((res) => {
      if (res.data === "true") {
        setLoad(false);
        setSucess(true);
      }
    });
  };

  return (
    <div>
      <div className="text-center">
        <hr style={{ borderTop: "6px solid #0e0e0e" }}></hr>
        Operation File
      </div>
      <Spinner active={load} />
      <UseModal
        stylehead={{
          background: "rgb(228 203 126 / 33%)",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          borderBottom: "1px solid black",
        }}
        stylebody={{ display: "none" }}
        show={sucess}
        close={() => window.location.reload()}
        title={"Ficheiro alterado com sucesso !!!"}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3 mt-4">
          {/* ###  Data Recebimento das Licensas #### */}
          <div className="col-md-4">
            <small>
              <label className="breadcrumb-item active" htmlFor="abrDate">
                Data Recebimento das Licensas
              </label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="datereclic"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              />
            </div>
          </div>
          {/* ###  Data Envio das Licensas #### */}
          <div className="col-md-4">
            <small>
              <label className="breadcrumb-item active" htmlFor="abrDate">
                Data Envio das Licensas
              </label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="datenvlic"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 d-flex justify-content-center mb-4">
          <button type="submit" className="btn btn-outline-success col-md-2">
            Modificar
          </button>
          <div className="m-2"></div>
          <button
            type="button"
            className="btn btn-outline-danger col-md-2"
            onClick={() => {
              window.location.reload();
            }}
          >
            Fechar
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditOperationOneLetter;
