import React, { useState, useEffect } from "react";
import FileUpload from "./common/fileUpload";
import FileList from "./common/fileList";
import {arquiveLic, getAnexoLic} from "../services/source";

function FileUploadLic({data, handleCloseLic}) {
    
    const [files, setFiles] = useState([]);
    const [showDatas, setShowDatas] = useState(false);


    const removeFile = (filename) => {
      setFiles(files.filter((file) => file.name !== filename));
    };

    useEffect(() => {
      async function getAnexo() {
        const result = await getAnexoLic(data);
        setShowDatas(result.data);
      }
      getAnexo();
    }, []);
    
    useEffect(() => {
      if (showDatas) {
        handleCloseLic();
      }
    }, [showDatas, handleCloseLic]);

  
    return (
      <>
        <FileUpload
          files={files}
          setFiles={setFiles}
          titulo="Licensa"
          casenumber={data.idfile}
          arquive={arquiveLic}
          showDatas={showDatas}
          setShowDatas={setShowDatas}
          filenumber={data.filenumber}
        />
        <FileList files={files} removeFile={removeFile} />
      </>
    );
}

export default FileUploadLic;