import React, { useEffect, useState } from "react";
import "../styles/track.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { getClientRef } from "../services/source";
import auth from "../services/auth";
import { NavLink } from "react-router-dom";
import Spinner from "./common/spinner";

function Track() {
  const [cliRef, setCliRef] = useState([]);
  const [load, setLoad] = useState(true);

  const navigate = useNavigate();

  const user = auth.getCurrentUser();
  const company = user.data;
  const com = user.data.company;

  useEffect(() => {
    async function getRefClient() {
      const client = await getClientRef(company);
      setCliRef(client.data);
    }
    setLoad(false);
    getRefClient();
  }, []);

  // Filter out null, undefined, and duplicate refclient values
  const uniqueCliRef = [...new Set(cliRef.map((e) => e.refclient))];

  const refcli = uniqueCliRef
    .filter((refclient) => refclient !== null && refclient !== undefined)
    .map((refclient) => ({ label: refclient, value: refclient }));

  const handlechange = (selectedOption, { action }) => {
    if (action === "select-option" && selectedOption) {
      const selectedRefClient = selectedOption.value;
      const filt = cliRef.filter((obj) => obj.refclient === selectedRefClient);
      navigate("/trackcase", { state: { filt } });
    }
  };

  return (
    <div className="container-fluid component">
      <Spinner active={load} />
      <div className="card-track">
        <img
          className="img-fluid mb-5"
          src={process.env.PUBLIC_URL + "assets/img/cads.png"}
          alt="Logo"
        />
        <Select
          placeholder="Procurar ou Selecionar Referência..."
          className="mt-5"
          onChange={handlechange}
          options={refcli}
          isClearable
        />

        {com !== "CADS" && (
          <div>
            <NavLink to="/logout">
              <button className="btn btn-outline-secondary logout">
                <i className="fas fa-sign-out-alt"></i>
                Sair
              </button>
            </NavLink>
          </div>
        )}

        {com === "CADS" && (
          <div>
            <NavLink to="/home">
              <button className="btn btn-outline-primary logout">
              <i className="fas fa-home"><small>Voltar</small></i>
                
              </button>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}

export default Track;
