import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../../styles/filesItem.css";

const FileItem = ({ file, viewFile, deleteFile }) => {
  const bytesToKB = (bytes) => {
    return (bytes / 1024).toFixed(2); // Rounding to 2 decimal places
  };

  return (
    <>
      <li
        id="file-items"
        className="file-item"
        key={file.name}
        style={{ display: "flex" }}
      >
        {/* <FontAwesomeIcon icon={faFileAlt} onClick={() => viewFile(file.name)} /> */}
        <p>
          {file.name}&nbsp;&nbsp;[{bytesToKB(file.size)} KB]
        </p>
        <div className="actions">
          <div className="loading"></div>
          {file.isUploading && (
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              onClick={() => deleteFile(file.name)}
            />
          )}
          {!file.isUploading && (
            <FontAwesomeIcon
              className="icon"
              icon={faTrash}
              onClick={() => deleteFile(file.name)}
            />
          )}
        </div>
      </li>
    </>
  );
};

export default FileItem;
