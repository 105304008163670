import React, { useState, useEffect } from "react";
import FileUpload from "./common/fileUpload";
import FileList from "./common/fileList";
import { arquiveFactRec, getAnexoFacRec } from "../services/source";

function FileUploadFaturaRecibo({ data, handleCloseFaturaRecibo }) {
  const [files, setFiles] = useState([]);
  const [showDatas, setShowDatas] = useState(false);

  
  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  useEffect(() => {
    async function getAnexo() {
      const result = await getAnexoFacRec(data);
      setShowDatas(result.data);
    }
    getAnexo();
  }, []);

  useEffect(() => {
    if (showDatas) {
      handleCloseFaturaRecibo();
    }
  }, [showDatas, handleCloseFaturaRecibo]);

  return (
    <>
      <FileUpload
        files={files}
        setFiles={setFiles}
        titulo="Fatura"
        casenumber={data.idfile}
        setShowDatas={setShowDatas}
        showDatas={showDatas}
        arquive={arquiveFactRec}
        filenumber={data.filenumber}
      />
      <FileList files={files} removeFile={removeFile} />
    </>
  );
}

export default FileUploadFaturaRecibo;
