import React, { useState, useEffect } from "react";

import Accordion from "react-bootstrap/Accordion";

import Input from "../common/input";
import InputData from "../common/inputdata";
import CheckboxWithLabel from "../common/cheackboxwithlabel";
import RadioboxWithLabel from "../common/radioboxwithlabel";
import CurrencyInput from "../common/currencyinput";
import InputSelect from "../common/inputselect";
import FileUploadRupe from "../fileUploadRupe";
import FileUploadNotaDeb from "../fileUploadNotaDeb";
import ReadOnly from "../common/readOnly";

import { Link, useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputButton from "../common/inputbutton";
import UseModal from "../common/useModal";

import { getAnexoRupeName, getAnexoNotaDebName,deleteOpenFile } from "../../services/source";

import "../../styles/rupeform.css";

const RupeForm = ({ onSubmit, defaultValues, disabled, obj}) => {
  const [notadeb, setNotaDeb] = useState("empty");
  const [solnotliq, setSolNotLiq] = useState(false);
  const [showrupe, setShowRupe] = useState(false);
  const [showNotaDebitoFile, setShowNotaDebitoFile] = useState(false);
  const [rupeName, setRupeName] = useState();
  const [notaDebName, setNotaDebName] = useState();

  let location = useLocation();
  const { file } = location.state;




  const schema = yup.object().shape({
    refcobminit: yup.string().required("Campo Obrigatório"),
    datecobmin: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
    caducidade: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
    solnotaliq: yup.bool().oneOf([true], "Campo Obrigatório"),
    pagamentoRupe: yup.string().when(notadeb, () => {
      if (notadeb === "empty")
        return yup.string().required("Campo Obrigatório");
      else return yup.string().notRequired();
    }),
    refnotadebito: yup.string().when(notadeb, () => {
      if (notadeb === "cads") return yup.string().required("Campo Obrigatório");
      else return yup.string().notRequired();
    }),
    datenodeb: yup
      .date()
      .nullable("Campo Obrigatório")
      .when(notadeb, () => {
        if (notadeb === "cads") return yup.date().required("Campo Obrigatório");
        else return yup.date().notRequired();
      }),
    valornotdeb: yup.string().when(notadeb, () => {
      if (notadeb === "cads") return yup.string().required("Campo Obrigatório");
      else return yup.string().notRequired();
    }),
    datapagcads: yup
      .date()
      .nullable("Campo Obrigatório")
      .when(notadeb, () => {
        if (notadeb === "cads") return yup.date().required("Campo Obrigatório");
        else return yup.date().notRequired();
      }),

    reftrasacao: yup.string().when(notadeb, () => {
      if (notadeb === "client")
        return yup.string().required("Campo Obrigatório");
      else return yup.string().notRequired();
    }),
    tipoPagamento: yup.string().when(notadeb, () => {
      if (notadeb === "client")
        return yup.string().required("Campo Obrigatório");
      else return yup.string().notRequired();
    }),
    datapagcli: yup
      .date()
      .nullable("Campo Obrigatório")
      .when(notadeb, () => {
        if (notadeb === "client")
          return yup.date().required("Campo Obrigatório");
        else return yup.date().notRequired();
      }),
    valorPagamento: yup.string().when(notadeb, () => {
      if (notadeb === "client")
        return yup.string().required("Campo Obrigatório");
      else return yup.string().notRequired();
    }),
  });

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues });

  const changeNota = (notadeb) => {
    setNotaDeb(notadeb);
  };

  const pagamento = [
    { label: "Multicaixa", value: "Multicaixa" },
    { label: "Transferencia", value: "Transferencia" },
  ];

  const handleRupeAnexo = () => {
    setShowRupe(true);
  };

  const handleNotaDebitoAnexo = () => {
    setShowNotaDebitoFile(true);
  };

  const handleCloseRupe = () => {
    setShowRupe(false);
  };

  const handleCloseNotaDeb = () => {
    setShowNotaDebitoFile(false);
  };

  useEffect(() => {
    async function getAnexoNotaDeb() {
      const result = await getAnexoNotaDebName(file);
      if (result.data && result.data[0] && result.data[0].filenamenotadeb) {
        setNotaDebName(result.data[0].filenamenotadeb);
      } else {
        return null;
      }
    }
    getAnexoNotaDeb();
  }, [handleCloseNotaDeb]);

  useEffect(() => {
    async function getAnexoRupe() {
      const result = await getAnexoRupeName(file);
      if (result.data && result.data[0] && result.data[0].filenamerupe) {
        setRupeName(result.data[0].filenamerupe);
      } else {
        return null;
      }
    }
    getAnexoRupe();
  }, [handleCloseRupe]);

  return (
    <>
      <div className="card-header">
        <div className="col">
          <Link to={"/progressTable"}>
            <i className="fa fa-arrow-circle-left fa-lg" aria-hidden="true" onClick={deleteOpenFile(obj)}></i>
          </Link>
        </div>
      </div>
      <div className="card-body">
        <Accordion id="create">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <i
                className="fa fa-f fa-lg"
                aria-hidden="true"
                style={{ marginRight: "8px" }}
              ></i>
              File
            </Accordion.Header>
            <Accordion.Body>
              {<ReadOnly {...file} step={file} />}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <UseModal
              title="Anexo Rupe"
              size={"xl"}
              show={showrupe}
              close={handleCloseRupe}
              body={
                <FileUploadRupe data={file} handleCloseRupe={handleCloseRupe} />
              }
            />
            <UseModal
              title="Anexo Nota Debito"
              size={"xl"}
              show={showNotaDebitoFile}
              close={handleCloseNotaDeb}
              body={
                <FileUploadNotaDeb
                  data={file}
                  handleCloseNotaDeb={handleCloseNotaDeb}
                />
              }
            />

      <div className="mt-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-body shadow p-3">
            {/* ### Rupe #### */}
            <i className="fa fa-sticky-note mb-3" aria-hidden="true">
              <label htmlFor="notaCobranca">&nbsp;Nota Cobrança</label>
            </i>
  

            <div className="col-md-8">
              <Input
                register={register}
                name="refcobminit"
                type="text"
                errors={errors}
                label="Rupe"
                placeholder="Rupe"
                // disabled={disabled.refcobminit}
              />
              <div className="row mb-3">
                {/* ### Data Cobranca Ministerio #### */}
                <div className="col-md-4">
                  <InputData
                    name="datecobmin"
                    label=" Data Emissāo Nota Liquidaçāo"
                    control={control}
                    errors={errors}
                    // disabled={disabled.datecobmin}
                    isclear={true}
                  />
                </div>

                {/* ### Caducidade #### */}
                <div className="col-md-4">
                  <InputData
                    name="caducidade"
                    label=" Caducidade"
                    control={control}
                    errors={errors}
                    // disabled={disabled.caducidade}
                    isclear={true}
                  />
                </div>
                {/* ### Anexo  Rupe #### */}
                <div className="col-md-4">
                  <InputButton
                    button={<i className="fa fa-upload"></i>}
                    label="Anexo Rupe"
                    name="rupeanexo"
                    register={register}
                    errors={errors}
                    onClick={handleRupeAnexo}
                    value={rupeName}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              {/* ### Solicitacao de Nota Liquidacao #### */}
              <div className="col-md-3">
                <CheckboxWithLabel
                  name="solnotaliq"
                  register={register}
                  type="checkbox"
                  id="solntlo"
                  label="Solicitacao de Pagamento"
                  checked={solnotliq}
                  onChange={(e) => setSolNotLiq(e.target.checked)}
                  errors={errors}
                />
              </div>
              {solnotliq ? (
                <>
                  {/* ###  Radio Pagamento CADS  #### */}
                  <div className="col-md-4">
                    <RadioboxWithLabel
                      register={register}
                      name="pagamentoRupe"
                      type="radio"
                      id="1"
                      value="cads"
                      onChange={(e) => changeNota("cads")}
                      errors={errors}
                      label="Pagamento Efectuado CADS"
                    />
                  </div>
                  {/* ###  Radio Pagamento CLIENTE  #### */}
                  <div className="col-md-4">
                    <RadioboxWithLabel
                      register={register}
                      name="pagamentoRupe"
                      type="radio"
                      id="2"
                      value="client"
                      checked={notadeb === "client"}
                      onChange={(e) => changeNota("client")}
                      errors={errors}
                      label="Pagamento Efectuado Cliente"
                    />
                  </div>
                </>
              ) : null}
            </div>

            {/* CADS */}
            {notadeb === "cads" && solnotliq ? (
              <div className="row mb-3">
                {/* ###  Referencia Nota de Debito #### */}
                <div className="col-md-2">
                  <Input
                    register={register}
                    name="refnotadebito"
                    type="text"
                    errors={errors}
                    label="Referencia Nota Debito"
                    placeholder="Referencia Nota Debito"
                  />
                </div>
                {/* ### Valor Nota Debito #### */}
                <div className="col-md-2">
                  <CurrencyInput
                    label="Valor Nota de Debito"
                    name="valornotdeb"
                    control={control}
                    register={register}
                    suffix="Kz"
                    errors={errors}
                    // disabled={disabled.valoremolumentos}
                  />
                </div>
                {/* ###  Data Nota Debito #### */}
                <div className="col-md-3">
                  <InputData
                    name="datenodeb"
                    label=" Data Nota Debito"
                    control={control}
                    errors={errors}
                    // disabled={disabled.caducidade}
                    isclear={true}
                  />
                </div>

                {/* ### Data Pagamento #### */}
                <div className="col-md-3">
                  <InputData
                    name="datapagcads"
                    label=" Data Pagamento"
                    control={control}
                    errors={errors}
                    // disabled={disabled.caducidade}
                    isclear={true}
                  />
                </div>
                {/* ### Anexo #### */}
                <div className="col-md-2">
                  <InputButton
                    button={<i className="fa fa-upload"></i>}
                    label="Anexo Nota Debito"
                    name="notaDebitofile"
                    register={register}
                    errors={errors}
                    onClick={handleNotaDebitoAnexo}
                    value={notaDebName}
                  />
                </div>
              </div>
            ) : (
              false
            )}

            {/* CLIENTE */}
            {notadeb === "client" && solnotliq ? (
              <div className="row mb-3">
                {/* ### Referencia Transacção #### */}
                <div className="col-md-3">
                  <Input
                    register={register}
                    name="reftrasacao"
                    type="text"
                    errors={errors}
                    label="Referencia Transacção"
                    placeholder="Referencia Transacção"
                  />
                </div>
                {/* ### Tipo Pagamento #### */}
                <div className="col-md-3">
                  <InputSelect
                    value="Pagamento"
                    label="Tipo Pagamento"
                    register={register}
                    name="tipoPagamento"
                    options={pagamento}
                    errors={errors}
                    // disabled={disabled.service || ver}
                  />
                </div>
                {/* ### Valor Pagamento #### */}
                <div className="col-md-3">
                  <CurrencyInput
                    label="Valor Pagamento"
                    name="valorPagamento"
                    control={control}
                    register={register}
                    suffix="Kz"
                    errors={errors}
                    // disabled={disabled.valoremolumentos}
                  />
                </div>
                {/* ### Data Pagamento#### */}
                <div className="col-md-3">
                  <InputData
                    name="datapagcli"
                    label=" Data Pagamento"
                    control={control}
                    errors={errors}
                    // disabled={disabled.caducidade}
                    isclear={true}
                  />
                </div>
              </div>
            ) : (
              false
            )}

            {/* ######## Button  ########### */}
            <div className="row mb-3">
              <div className="col-md-12 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-outline-success col-md-2"
                >
                  Submeter
                </button>
                <div className="m-2"></div>
                <button
                  className="btn btn-outline-danger col-md-2"
                  onClick={() => reset()}
                >
                  Apagar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RupeForm;
