import React, { useState, useEffect } from "react";
import { DropdownButton, Dropdown, Table } from "react-bootstrap";
import UseModal from "../common/useModal";
import ViewClient from "../viewClient";
import EditClient from "../editClient";
import DelClient from "../delClient";

import { getClients } from "../../services/source";
import ReactPaginate from "react-paginate";

function ClientTable() {
  const [client, setClient] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  //   const [num, setNum] = useState();
  const [view, setView] = useState(false);
  const [viewdata, setViewData] = useState({});
  const [edit, setEdit] = useState(false);
  const [editdata, setEditData] = useState({});
  const [deldata, setDelData] = useState({});
  const [del, setDel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function getListClient() {
      const result = await getClients();
      setClient(result.data);
    }
    getListClient();
  }, []);

  //Pagination
  const clientsPerPage = 15;
  const pageVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(
    client.filter((clients) => {
      if (searchTerm === "") {
        return clients;
      } else if (
        clients.company.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return clients;
      } else if (
        clients.email.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return clients;
      } else if (
        clients.nif.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return clients;
      }
      return false;
    }).length / clientsPerPage
  );

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //Table Options
  const handleView = (view) => {
    setView(true);
    setViewData(view);
  };

  const handleEdit = (edit) => {
    setEdit(true);
    setEditData(edit);
  };

  const handleDelete = (del) => {
    setDel(true);
    setDelData(del);
  };

  const displayUsers = client
    .filter((val) => {
      if (searchTerm === "") {
        return val;
      } else if (val.company.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      } else if (val.nif.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      }
      return null;
    })
    .slice(pageVisited, pageVisited + clientsPerPage)
    .map((c) => {
      return (
        <tr key={c.id}>
          <td>{c.id}</td>
          <td>{c.company}</td>
          <td>{c.nif}</td>
          <td>{c.email}</td>
          <td>
            <DropdownButton
              id="dropdown-basic-button"
              title="Opções"
              variant="secundary"
            >
              <Dropdown.Item href="#/visualizar" onClick={() => handleView(c)}>
                <i className="fa fa-eye"> Visualizar</i>
              </Dropdown.Item>

              <UseModal
                size={"lg"}
                show={view}
                close={() => setView(false)}
                body={<ViewClient {...viewdata} />}
                title="Ficha Cliente"
              />

              <Dropdown.Item href="#/editar" onClick={() => handleEdit(c)}>
                <i className="fa fa-edit"> Editar</i>
              </Dropdown.Item>

              <UseModal
                show={edit}
                size={"lg"}
                close={() => setEdit(false)}
                body={<EditClient {...editdata} />}
                title="Editar"
              />

              <Dropdown.Item href="#/apagar" onClick={() => handleDelete(c)}>
                <i className="fa fa-trash"> Apagar</i>
              </Dropdown.Item>

              <UseModal
                show={del}
                close={() => setDel(false)}
                 body={<DelClient {...deldata} />}
                title="Apagar"
              />
            </DropdownButton>
          </td>
        </tr>
      );
    });

  return (
    <div>
      <div className="dataTable-top">
        <div className="dataTable-search">
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setSearchTerm(e.target.value);
                changePage({ selected: 0 });
              }}
              placeholder="Procurar..."
              aria-label="Search for..."
              aria-describedby="btnNavbarSearch"
            />
          </div>
        </div>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Empresa</th>
            <th>NIF</th>
            <th>Email</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>{displayUsers}</tbody>
      </Table>
      <div className="pagination justify-content-center">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"page-item disabled"}
          activeClassName={"page-item active"}
          pageLinkClassName={"page-link"}
        />
      </div>
    </div>
  );
}

export default ClientTable;
