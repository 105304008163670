import React, { useState, useEffect } from "react";
import Input from "./input";
import { useForm, Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { NumericFormat } from "react-number-format";
import Spinner from "./spinner";
import UseModal from "./useModal";
import { updateFinanceOne } from "../../services/source";

function EditFinanceOne(data) {
  const [solnotliq, setSolNotLiq] = useState(true);
  const [notadeb, setNotaDeb] = useState(data.file[0].pagamentoRupe);
  const [load, setLoad] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const refcobminit = data.file[0].refcobminit;
  const datecobmin = new Date(data.file[0].datecobmin);
  const caducidade = new Date(data.file[0].caducidade);
  const solnotaliq = data.file[0].solnotaliq;
  const pagamentoRupe = data.file[0].pagamentoRupe;
  const refnotadebito = data.file[0].refnotadebito;
  const datenodeb = new Date(data.file[0].datenodeb);
  const valornotdeb = data.file[0].valornotdeb;
  const datapagcads = new Date(data.file[0].datapagcads);
  const reftrasacao = data.file[0].reftrasacao;
  const tipoPagamento = data.file[0].tipoPagamento;
  const datapagcli = new Date(data.file[0].datapagcli);
  const valorPagamento = data.file[0].valorPagamento;
  let id = data.file[0].casenumber;
  let filenumber = data.file[0].filenumber;
  const isento = data.file[0].step1;

  const defaultValues = {
    refcobminit: refcobminit,
    datecobmin: datecobmin,
    caducidade: caducidade,
    solnotaliq: solnotaliq,
    pagamentoRupe: pagamentoRupe,
    refnotadebito: refnotadebito,
    datenodeb: datenodeb,
    valornotdeb: valornotdeb,
    datapagcads: datapagcads,
    reftrasacao: reftrasacao,
    tipoPagamento: tipoPagamento,
    datapagcli: datapagcli,
    valorPagamento: valorPagamento,
  };

  useEffect(() => {
    if (isento === "ISENTO") {
      setIsDisabled(true);
    }
  }, [isento]);

  const { register, control, handleSubmit } = useForm({ defaultValues });
  registerLocale("pt", pt);

  const changeNota = (notadeb) => {
    setNotaDeb(notadeb);
  };

  const NumericFormatWrapper = React.forwardRef(
    ({ onChange, ...rest }, ref) => {
      return (
        <NumericFormat
          {...rest}
          onChange={(val) => {
            onChange(val);
          }}
        />
      );
    }
  );

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const onSubmit = async (data) => {
    const datecobmin = convert(data.datecobmin);
    const caducidade = convert(data.caducidade);
    const datenodeb = convert(data.datenodeb);
    const datapagcli = convert(data.datapagcli);
    const datapagcads = convert(data.datapagcads);
    const payload = {
      id,
      filenumber,
      data,
      datecobmin,
      caducidade,
      datenodeb,
      datapagcli,
      datapagcads,
    };
    setLoad(true);
    await updateFinanceOne(payload).then((res) => {
      if (res.data === "true") {
        setLoad(false);
        setSucess(true);
      }
    });
  };

  return (
    <div>
      <Spinner active={load} />
      <UseModal
        stylehead={{
          background: "rgb(228 203 126 / 33%)",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          borderBottom: "1px solid black",
        }}
        stylebody={{ display: "none" }}
        show={sucess}
        close={() => window.location.reload()}
        title={"Ficheiro alterado com sucesso !!!"}
      />
      <div className="text-center">
        <hr style={{ borderTop: "6px solid #0e0e0e" }}></hr>
        Finanças
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          {/* ### Rupe #### */}
          <div className="col-mb-8">
            <Input
              className="form-control mt-2"
              id="refcobminit"
              type="text"
              name="refcobminit"
              register={register}
              label="RUPE"
              placeholder="RUPE"
              disabled={isDisabled}
            />
          </div>
          {/* ### Data Cobranca Ministerio #### */}
          <div className="col-md-3">
            <small>
              <label className="breadcrumb-item active" htmlFor="abrDate">
                Data Emissāo Nota Liquidaçāo
              </label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="datecobmin"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    disabled={isDisabled}
                  />
                )}
              />
            </div>
          </div>
          {/* ### Caducidade #### */}
          <div className="col-md-3">
            <small>
              <label htmlFor="caducidade">Caducidade</label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="caducidade"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    disabled={isDisabled}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {/* ### Solicitacao de Nota Liquidacao #### */}
        <div className="row mb-3">
          <div className="col-md-3">
            <div className="form-check">
              <label htmlFor="solntlo">Solicitacao de Pagamento</label>
              <input
                type="checkbox"
                className="form-check-input"
                {...register("solnotaliq")}
                onChange={(e) => setSolNotLiq(e.target.checked)}
                checked={solnotliq}
                disabled={isDisabled}
              />
            </div>
          </div>

          {solnotliq && (
            <>
              {/* ###  Radio Pagamento CADS  #### */}
              <div className="col-md-4">
                <div className="form-check">
                  <input
                    disabled={isDisabled}
                    className="form-check-input"
                    type="radio"
                    value="cads"
                    id="1"
                    {...register("pagamentoRupe")}
                    checked={notadeb === "cads"}
                    onChange={(e) => changeNota("cads")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Pagamento Efectuado CADS
                  </label>
                </div>
              </div>
              {/* ###  Radio Pagamento CLIENTE  #### */}
              <div className="col-md-4">
                <div className="form-check">
                  <input
                    disabled={isDisabled}
                    className="form-check-input"
                    type="radio"
                    id="2"
                    {...register("pagamentoRupe")}
                    value="client"
                    checked={notadeb === "client"}
                    onChange={(e) => changeNota("client")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Pagamento Efectuado Cliente
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
        {/* ### Pagamento CADS #### */}
        {notadeb === "cads" && solnotliq ? (
          <div className="row mb-3">
            {/* ### Ref Nota Debito #### */}
            <div className="col-md-3">
              <Input
                className="form-control mt-2"
                type="text"
                id="refnotadebito"
                name="refnotadebito"
                register={register}
                label="Referencia Nota Debito"
                placeholder="Referencia Nota Debito"
              />
            </div>
            {/* ### Data Nota Debito #### */}
            <div className="col-md-3">
              <small>
                <label className="breadcrumb-item active" htmlFor="notaDebDate">
                  Data Nota Debito
                </label>
              </small>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fa fa-calendar"></i>
                </span>
                <Controller
                  name="datenodeb"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      locale={pt}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      isClearable
                    />
                  )}
                />
              </div>
            </div>
            {/* ### Valor Nota Debito #### */}
            <div className="col-md-3">
              <small>
                <label
                  className="breadcrumb-item active text-wrap "
                  htmlFor="valorNotadeb"
                >
                  Valor Nota Debito
                </label>
              </small>
              <div className="input-group mb-3">
                <Controller
                  name="valornotdeb"
                  control={control}
                  render={({ field }) => (
                    <NumericFormatWrapper
                      className="form-control text-end"
                      thousandsGroupStyle="thousand"
                      decimalSeparator="."
                      displayType="form-control"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      onChange={(val) => field.onChange(val)}
                      valueIsNumericString={true}
                      {...field}
                    />
                  )}
                />

                <span className="input-group-text">Kz</span>
              </div>
            </div>
            {/* ### Data Pagamento CADS #### */}
            <div className="col-md-3">
              <small>
                <label className="breadcrumb-item active" htmlFor="datapagcads">
                  Data Pagamento
                </label>
              </small>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fa fa-calendar"></i>
                </span>
                <Controller
                  name="datapagcads"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      locale={pt}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      isClearable
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          false
        )}
        {/* ### Pagamento Cliente #### */}
        {notadeb === "client" && solnotliq ? (
          <div className="row mb-3">
            {/* ### Ref Transacao#### */}
            <div className="col-md-3 mt-1">
              <Input
                className="form-control"
                type="text"
                id="reftrasacao"
                name="reftrasacao"
                register={register}
                label="Referencia Transaçāo"
                placeholder="Referencia Transaçāo"
              />
            </div>
            {/* ### Tipo Pagamento #### */}
            <div className="col-md-3 mt-1">
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  {...register("tipoPagamento")}
                  placeholder="Tipo Pagamento"
                  type="select"
                  name="tipoPagamento"
                >
                  <option value="">Tipo Pagamento</option>
                  <option value="Multicaixa">Multicaixa</option>
                  <option value="transferencia">Transferencia</option>
                </select>
                <label htmlFor="Tipo Pagamento">Tipo Pagamento</label>
              </div>
            </div>
            {/* ### Data #### */}
            <div className="col-md-3">
              <small>
                <label className="breadcrumb-item active" htmlFor="datapagcli">
                  Data Pagamento
                </label>
              </small>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fa fa-calendar"></i>
                </span>
                <Controller
                  name="datapagcli"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      locale={pt}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      isClearable
                    />
                  )}
                />
              </div>
            </div>
            {/* ### Valor Pagamento #### */}
            <div className="col-md-3">
              <small>
                <label
                  className="breadcrumb-item active text-wrap "
                  htmlFor="valorPagamento"
                >
                  Valor Pagamento
                </label>
              </small>
              <div className="input-group mb-3">
                <Controller
                  name="valorPagamento"
                  control={control}
                  render={({ field }) => (
                    <NumericFormatWrapper
                      className="form-control text-end"
                      thousandsGroupStyle="thousand"
                      decimalSeparator="."
                      displayType="form-control"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      onChange={(val) => field.onChange(val)}
                      valueIsNumericString={true}
                      {...field}
                    />
                  )}
                />
                <span className="input-group-text">Kz</span>
              </div>
            </div>
          </div>
        ) : (
          false
        )}
        {!isDisabled ? (
          <div className="col-md-12 d-flex justify-content-center mb-4">
            <button type="submit" className="btn btn-outline-success col-md-2">
              Modificar
            </button>
            <div className="m-2"></div>
            <button
              type="button"
              className="btn btn-outline-danger col-md-2"
              onClick={() => {
                window.location.reload();
              }}
            >
              Fechar
            </button>
          </div>
        ) : null}
      </form>
    </div>
  );
}

export default EditFinanceOne;
