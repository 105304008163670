import React, { useState } from "react";
import { delEmail } from "../services/source";
import Alert from "react-bootstrap/Alert";

function DelEmail(del) {
  const [danger, setDanger] = useState(false);
  const onSubmit = async () => {
    await delEmail(del).then((res) => {
      if (res.data) {
        window.location.reload(true);
      } else {
        <Alert
          show={danger}
          variant="danger"
          dismissible
          onClose={() => setDanger(false)}
        >
          Não é possivel apagar o email!!!
        </Alert>;
      }
    });
  };

  return (
    <div>
      <p>Tem a certeza que quer apagar permanentemente o email?</p>
      <button className="btn btn-danger m-2" onClick={onSubmit}>
        Apagar
      </button>
    </div>
  );
}

export default DelEmail;
