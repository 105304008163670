import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { getProgressTable } from "../services/source";
import auth from "../services/auth";
import { Link } from "react-router-dom";
import io from "socket.io-client";

function ProgressTable() {
  const [disCase, setDisCase] = useState([]);
  const user = auth.getCurrentUser();
  const [searchTerm, setSearchTerm] = useState("");
  const file = user.data.role;

  const socket = io.connect(process.env.REACT_APP_SOCKET);
  const room = file;
  socket.emit("join_room", room);

  const pathFile = (res) => {
    switch (res) {
      case "1":
        return "/dpFinancasOne";
      case "2":
        return "/dpOperacoesOne";
      case "3":
        return "/dpFinancasTwo";
      case "Close":
        return "/closeReadOnly";
      case "4":
        return "/dpFinancasOneLetter";
      case "5":
        return "/dpOperacoesOneLetter";
      case "6":
        return "/dpFinancasTwoLetter";
      case "Close Letter":
        return "/closeReadOnlyLetter";
      default:
        return null;
    }
  };

  async function getCaseNumber() {
    const result = await getProgressTable();
    setDisCase(result.data);
  }
  
  useEffect(() => {
    socket.on("receivetask", (data) => {
      socket.emit(getCaseNumber());
    });
  });

  useEffect(() => {
    getCaseNumber();
  }, []);

  const displayCases = disCase
    .filter((f) => {
      if (file === " ") {
        return f;
      } else if (f.role === file) {
        return f;
      }  
      return null;
    }).filter((t)=>{
      if(searchTerm === ""){
        return t;
      }else if(
        t.filenumber.toLowerCase().includes(searchTerm.toLowerCase())
      ){
        return t;
      }
    })
    .map((cas, index) => {
      return (
        <tr key={index} style={{ textAlign: "center" }}>
          <td>{cas.filenumber}</td>
          <td>{cas.role}</td>
          <td>{cas.refclient}</td>
          <td>{cas.refimpor}</td>
          <td>{cas.created}</td>
          <td>{cas.modified}</td>
          <td>
            <Link to={pathFile(cas.cicle)} state={{ file: cas }}>
              <i className="fa fa-forward" aria-hidden="true"></i>
            </Link>
          </td>
        </tr>
      );
    });

  return (

    <div className="container-fluid component">
      <h3 className="text-dark mb-4">Ficheiros Atribuidos
      </h3>
      <div className="row mb3">
        <div className="card mb-3">
          <div className="card-header">
                  <i className="fa fa-table me-1"></i>
                  Tabela Ficheiros
                </div>
                <div className="card-body">
                  <div className="dataTable-top mb-3" >
                    <div className="dataTable-search">
                      <div className="input-group">
                        <input type="text" className="form-control" 
                          onChange={(e)=>{
                            setSearchTerm(e.target.value);
                          }}
                          placeholder="Procurar..."
                        
                        />
                      </div>
                    </div>
                  </div>
                  {/* ############## Tables ############## */}
                  <Table striped bordered hover>
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <th>File</th>
                        <th>Role</th>
                        <th>Ref Cliente</th>
                        <th>Ref Factura</th>
                        <th>Data Criacao</th>
                        <th>Modificado</th>
                        <th>Abrir</th>
                      </tr>
                    </thead>
                    <tbody>{displayCases}</tbody>
                  </Table>
                  {/* ############## !Tables ############## */}
                </div>
        </div>

      </div>
    </div>
  );
}

export default ProgressTable;
