import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function ReadOnly({
  filenumber,
  opencasedate,
  clients,
  importador,
  refclient,
  labeltransp,
  doctransp,
  dateservice,
  refimpor,
  service,
  valorfactimpor,
  valoremolu,
  moeda,
  datesubmit,
  datepro,
  refcobminit,
  datecobmin,
  caducidade,
  filenamerupe,
  solnotaliq,
  pagamentoRupe,
  refnotadebito,
  datenodeb,
  valornotdeb,
  datapagcads,
  filenamenotadeb,
  reftrasacao,
  tipoPagamento,
  valorPagamento,
  datapagcli,
  datereclic,
  datenvlic,
  filenamelic,
  faturacao,
  datefact,
  valfacps,
  envfactcli,
  datenfact,
  numfact,
  recfactpgo,
  atualizacaoprima,
  filenamefacrec,
  kpirecbvalorfatservi,
  kpiservicos,
  despesasdofile,
  revenue,
  fechofile,
  observacoes,
  step,
  filenamesub,
  depfile,
}) {
  return (
    <div>
      {/* Abertura File */}
      <div className="card-body shadow p-3">
        {/*Case Number*/}
        <div className="col-md-4">
          <Form.Label>Numero Ordem</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <i className="fa fa-file"></i>
            </InputGroup.Text>
            <Form.Control value={filenumber} disabled />
          </InputGroup>
        </div>
        {/* Data Abertura File */}
        <div className="col-md-4">
          <Form.Label>Data Abertura File</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <i className="fa fa-calendar"></i>
            </InputGroup.Text>
            <Form.Control value={opencasedate} disabled />
          </InputGroup>
        </div>
        {/* ############################ */}
        <div className="row mb-3">
          {/* Cliente */}
          <div className="col-md-6">
            <Form.Label>Selecionar Cliente</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control value={clients} disabled />
            </InputGroup>
          </div>
          {/* Importador */}
          <div className="col-md-6">
            <Form.Label>Importador</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control value={importador} disabled />
            </InputGroup>
          </div>
        </div>
        {/* ############################ */}
        <div className="row mb-3">
          {/* ### Referencia do ficheiro do cliente #### */}
          <div className="col-md-6">
            <Form.Label>Referencia Ficheiro do Cliente</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control value={refclient} disabled />
            </InputGroup>
          </div>
          {/* ### Documento Transporte #### */}
          <div className="col-md-6">
            <Form.Label>Documento Transporte</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>{labeltransp}</InputGroup.Text>
              <Form.Control value={doctransp} disabled />
            </InputGroup>
          </div>
        </div>
        {/* Data Servico */}
        <div className="col-md-4">
          <Form.Label>Data Pedido Serviço</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <i className="fa fa-calendar"></i>
            </InputGroup.Text>
            <Form.Control value={dateservice} disabled />
          </InputGroup>
        </div>
        {/* ############################ */}
        <div className="row mb-3">
          {/* Referência Factura Importaçāo */}
          <div className="col-md-6">
            <Form.Label>Referência Factura Importaçāo</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control value={refimpor} disabled />
            </InputGroup>
          </div>
          {/* service */}
          <div className="col-md-6">
            <Form.Label>Serviços</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control value={service} disabled />
            </InputGroup>
          </div>
        </div>
        {/* ############################ */}
        <div className="row mb-3">
          {/* ### Valor Fatura Importacao #### */}
          <div className="col-md-3">
            <Form.Label>Valor Factura Importacao</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control value={valorfactimpor} disabled />
              <InputGroup.Text>{moeda}</InputGroup.Text>
            </InputGroup>
          </div>
          {/* ### Valor Estimado de emolumentos a ser pago #### */}
          <div className="col-md-3">
            <Form.Label>Valor Estimado Emolumentos</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control value={valoremolu} disabled />
              <InputGroup.Text>Kz</InputGroup.Text>
            </InputGroup>
          </div>
          {/* ### Data Submissap ao Ministerio  #### */}
          {step ? (
            <div className="col-md-3">
              <Form.Label>Data Submissão Ministério</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={datesubmit} disabled />
              </InputGroup>
            </div>
          ) : null}
          {/* ### Data Pronunciamento #### */}
          {step ? (
            <div className="col-md-3">
              <Form.Label>Data Pronunciamento</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={datepro} disabled />
              </InputGroup>
            </div>
          ) : null}
        </div>
        <div className="row mb-3">
          {/* Anexo Protocolo Submissao */}
          {step ? (
            <div className="col-md-6">
              <Form.Label>Anexo Protocolo Submissão</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={filenamesub} disabled />
              </InputGroup>
            </div>
          ) : null}
        </div>
      </div>
      {/* Rupe */}
      {step && step.step1 ? (
        <div className="card-body shadow p-3">
          {/* ### Ref de documentos cobranca Ministerios #### */}
          <div className="col-mb-8">
            <Form.Label>Rupe</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control value={refcobminit} disabled />
            </InputGroup>
          </div>
          <div className="row mb-3">
            {/* ### Data  Emissão Nota Liquidação #### */}
            <div className="col-md-3">
              <Form.Label>Data Emissão Nota Liquidação</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={datecobmin} disabled />
              </InputGroup>
            </div>
            {/* ### Caducidade #### */}
            <div className="col-md-4">
              <Form.Label>Caducidade</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={caducidade} disabled />
              </InputGroup>
            </div>
            {/* ### Anexo #### */}
            <div className="col-md-4">
              <Form.Label>Anexo Rupe</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={filenamerupe} disabled />
              </InputGroup>
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Solicitacao de Nota Liquidacao #### */}
            <div className="col-md-3">
              <Form.Check
                label="Solicitação de Nota Liquidação"
                defaultChecked={solnotaliq}
                type="checkbox"
                disabled
              />
            </div>
            {solnotaliq ? (
              <>
                {/* ###  Radio Pagamento CADS  #### */}
                <div className="col-md-4">
                  <Form.Check
                    label="Pagamento Efectuado CADS"
                    defaultChecked={pagamentoRupe}
                    type="radio"
                    disabled
                  />
                </div>
                {/* ###  Radio Pagamento CLIENTE  #### */}
                <div className="col-md-4">
                  <Form.Check
                    label="Pagamento Efectuado Cliente"
                    defaultChecked={pagamentoRupe}
                    type="radio"
                    disabled
                  />
                </div>
              </>
            ) : null}
          </div>

          {/* ### Pag CADS #### */}

          {pagamentoRupe === "cads" && solnotaliq ? (
            <div className="row mb-3">
              {/* ###  Referencia Nota de Debito #### */}
              <div className="col-md-2">
                <Form.Label>Referencia Nota Debito</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control value={refnotadebito} disabled />
                </InputGroup>
              </div>
              {/* ### Valor Nota Debito #### */}
              <div className="col-md-2">
                <Form.Label>Valor Nota Debito</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control value={valornotdeb} disabled />
                  <InputGroup.Text>Kz</InputGroup.Text>
                </InputGroup>
              </div>
              {/* ###  Data Nota Debito #### */}
              <div className="col-md-3">
                <Form.Label>Data Nota Debito</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control value={datenodeb} disabled />
                </InputGroup>
              </div>
              {/* ### Data Pagamento #### */}
              <div className="col-md-3">
                <Form.Label>Data Pagamento</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control value={datapagcads} disabled />
                </InputGroup>
              </div>
              {/* ### Anexo #### */}
              <div className="col-md-2">
                <Form.Label>Anexo Nota Debito</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control value={filenamenotadeb} disabled />
                </InputGroup>
              </div>
            </div>
          ) : (
            false
          )}

          {/* ### Pag Client #### */}
          {pagamentoRupe === "client" && solnotaliq ? (
            <div className="row mb-3">
              {/* ### Referencia Transacção #### */}
              <div className="col-md-3">
                <Form.Label>Referencia Transacção</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control value={reftrasacao} disabled />
                </InputGroup>
              </div>
              {/* ### Tipo Pagamento #### */}
              <div className="col-md-3">
                <Form.Label> Tipo Pagamento</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control value={tipoPagamento} disabled />
                </InputGroup>
              </div>
              {/* ### Valor Pagamento #### */}
              <div className="col-md-3">
                <Form.Label>Valor Pagamento</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control value={valorPagamento} disabled />
                  <InputGroup.Text>Kz</InputGroup.Text>
                </InputGroup>
              </div>
              {/* ### Data Pagamento#### */}
              <div className="col-md-3">
                <Form.Label>Data Pagamento</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control value={datapagcli} disabled />
                </InputGroup>
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      ) : null}
      {/* Operacoes  */}
      {step && step.step2 ? (
        <div className="card-body shadow p-3">
          <div className="row mb-3">
            {/* ### Data Recebimentos Licensas #### */}
            <div className="col-md-4">
              <Form.Label>Data Recebimentos Licensas</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={datereclic} disabled />
              </InputGroup>
            </div>
            {/* ### Data envio das Licensas #### */}
            <div className="col-md-4">
              <Form.Label> Data envio das Licensas</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={datenvlic} disabled />
              </InputGroup>
            </div>
            {/* ### Anexo Lic #### */}
            <div className="col-md-4">
              <Form.Label>Anexo Licensa</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={filenamelic} disabled />
              </InputGroup>
            </div>
          </div>
        </div>
      ) : null}
      {/* Invoice */}
      {step && step.step3 ? (
        <div className="card-body shadow p-3">
          <div className="row mb-3">
            {/* ### Facturacao #### */}
            <div className="col-md-4">
              <Form.Check
                label="Faturação"
                defaultChecked={faturacao}
                type="checkbox"
                disabled
              />
            </div>
            {/* ###  Data Faturacao #### */}
            <div className="col-md-3">
              <Form.Label> Data Faturação</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={datefact} disabled />
              </InputGroup>
            </div>
            {/* ### Valor Fatura Prestacao de Servicos #### */}
            <div className="col-md-3">
              <Form.Label>Valor Fatura Prestação de Serviços</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={valfacps} disabled />
                <InputGroup.Text>Kz</InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Envio Fatura ao Cliente #### */}
            <div className="col-md-4">
              <Form.Check
                label="Envio Fatura ao Cliente"
                defaultChecked={envfactcli}
                type="checkbox"
                disabled
              />
            </div>
            {/* ### Data Envio Fatura #### */}
            <div className="col-md-3">
              <Form.Label> Data Envio Fatura</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={datenfact} disabled />
              </InputGroup>
            </div>
            {/* ### Numero da Fatura #### */}
            <div className="col-md-3">
              <Form.Label>Numero da Fatura Serviços</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={numfact} disabled />
              </InputGroup>
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Data Recebimento da PGTO da fatura servico #### */}
            <div className="col-md-3">
              <Form.Label>Data Recebimento da Pagamento</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control value={recfactpgo} disabled />
              </InputGroup>
            </div>
            {/* ### Atualizacao do programa de faturacao e emissoa do recibo #### */}
            <div className="col-md-5 mt-4">
              <Form.Check
                label="Atualizacao do Programa de Faturacao Emissao do
              Recibo"
                defaultChecked={atualizacaoprima}
                type="checkbox"
                disabled
              />
            </div>
            {/* ### Anexo Factura e Recibo #### */}
            <div className="col-md-4">
              <Form.Label>Anexo Fatura</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={filenamefacrec} disabled />
              </InputGroup>
            </div>
          </div>
          <div className="row mb-3">
            {/* ### KPI Servicos Dias  #### */}
            <div className="col-md-4">
              <Form.Label>KPI Servicos Dias</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={kpiservicos} disabled />
              </InputGroup>
            </div>
            {/* ### KPI Recebimentosde Valor Servicos  #### */}
            <div className="col-md-4">
              <Form.Label>KPI do Recebimento Factura de Serviços</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={kpirecbvalorfatservi} disabled />
              </InputGroup>
            </div>
          </div>

          <div className="row mb-3">
            {/* Despesas do File  */}
            <div className="col-4">
              <Form.Label>Despesas do File</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={despesasdofile} disabled />
                <InputGroup.Text>Kz</InputGroup.Text>
              </InputGroup>
            </div>

            {/* valor e descricao */}
            {Array.isArray(depfile) &&
              depfile.map((item, index) => (
                <div className="col-4" key={index}>
                  <Form.Label>Valor</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control value={item.valor} disabled />
                    <InputGroup.Text>Kz</InputGroup.Text>
                  </InputGroup>

                  <Form.Label>Descrição</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control value={item.descricao} disabled />
                  </InputGroup>
                </div>
              ))}
          </div>

          <div className="row mb-3">
            <div className="col-4"></div>
            <div className="col-4">
              <Form.Label>Revenues</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control value={revenue} disabled />
                <InputGroup.Text>Kz</InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Fecho do File #### */}
            <div className="col-md-3">
              <Form.Check
                label="Fecho do File"
                defaultChecked={fechofile}
                type="checkbox"
                disabled
              />
            </div>
          </div>
          <div className="row mb-3">
            {/* ### Observacoes #### */}
            <div className="col-md-12">
              <textarea
                rows="4"
                cols="50"
                className="form-control"
                type="textarea"
                id="observacoes"
                name="observacoes"
                defaultValue={observacoes}
                label="Observacoes"
                placeholder="Observacoes"
                readOnly
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ReadOnly;
