import React, { useState, useEffect } from "react";
import ContactoForm from "../components/forms/contactosForm";
import { editContact, getClients } from "../services/source";

function EditContact(data) {
  useEffect(() => {
    async function getCli() {
      const res = await getClients();
      setClients(res.data);
    }
    getCli();
  }, []);

  const [clients, setClients] = useState([]);

  const disableFiels = {
    firstName: false,
    lastName: false,
    email: false,
    company: false,
    pass: false,
    confirpass: false,
    phone1: false,
    phone2: false,
  };

  const initalValues = {
    id: data.id || "",
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    email: data.email || "",
    company: data.company || "",
    phone1: data.phone1 || "",
    phone2: data.phone2 || "",
  };

  const options = clients.map((client) => ({
    label: client.company, // Use the company name as the label
    value: client.company, // Use the client id as the value
  }));

  const onSubmit = (data, e) => {
    e.preventDefault();
    editContact(data).then((res) =>
      res.data ? (window.location.href = "/contactos") : null
    );
  };

  return (
    <>
      <ContactoForm
        onSubmit={onSubmit}
        disabled={disableFiels}
        defaultValues={initalValues}
        options={options}
        buttonClass="btn btn-danger"
        labelButton="Editar Contacto"
        selabel={initalValues.company}
      />
    </>
  );
}

export default EditContact;
