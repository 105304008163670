import React, { useEffect, useState } from "react";
import {
  getTotalCases,
  getProgressTotalCases,
  getTotalCloseCases,
  getTotalClients,
} from "../services/source";
import {
  getTotalLetterCases,
  getProgressTotalLetterCases,
  getTotalLetterCloseCases,
} from "../services/source";
import "../styles/home.css";

function Home() {
  const [total, setTotal] = useState();
  const [totalprog, setTotalProg] = useState();
  const [totalclose, setTotalClose] = useState();
  const [totalclients, setTotalClients] = useState();

  const [totalletter, setTotaletter] = useState();
  const [totaletterprog, setTotaletterProg] = useState();
  const [totaletterclose, setTotaletterClose] = useState();

  useEffect(() => {
    async function Total() {
      const totalfiles = await getTotalCases();
      setTotal(totalfiles);
    }
    Total();
    async function TotalProg() {
      const totalprog = await getProgressTotalCases();
      setTotalProg(totalprog);
    }
    TotalProg();
    async function TotalClose() {
      const totalclose = await getTotalCloseCases();
      setTotalClose(totalclose);
    }
    TotalClose();
    async function TotalClients() {
      const totalclients = await getTotalClients();
      setTotalClients(totalclients);
    }
    TotalClients();
  }, []);

  useEffect(() => {
    async function LetterTotal() {
      const totalletter = await getTotalLetterCases();
      setTotaletter(totalletter);
    }
    LetterTotal();
    async function LetterTotalProg() {
      const totaletterprog = await getProgressTotalLetterCases();
      setTotaletterProg(totaletterprog);
    }
    LetterTotalProg();
    async function LetterTotalClose() {
      const totaletterclose = await getTotalLetterCloseCases();
      setTotaletterClose(totaletterclose);
    }
    LetterTotalClose();
  }, []);

  return (
    <div>
      <div className="container-fluid component">
        <div
          id="layoutSidenav_content"
          // style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-sm px-4">
                <div className="page-header-content-pt-4">
                  <div className="row aling-items-center justify-content-between">
                    <div className="col-auto">
                      <h1 className="page-header-title">
                        <div className="page-header-icon"></div>
                        Painel Principal
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10 ">
                  <div className="row ">
                    <div className="col-xl-3 col-lg-6">
                      <div className="card l-bg-cherry">
                        <div className="card-statistic-3 p-4">
                          <div className="card-icon card-icon-large">
                            <i className="fa fa-file-o fa-5x"></i>
                          </div>
                          <div className="mb-4">
                            <h5 className="card-title mb-0">Numero Licença</h5>
                          </div>
                          <div className="row align-items-center mb-2 d-flex">
                            <div className="col-8">
                              <h2 className="d-flex align-items-center mb-0">
                                {total}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <div className="card l-bg-orange-dark">
                        <div className="card-statistic-3 p-4">
                          <div className="card-icon card-icon-large">
                            <i className="fa fa-spinner fa-5x"></i>
                          </div>
                          <div className="mb-4">
                            <h5 className="card-title mb-0">
                              Licenças Em Progresso
                            </h5>
                          </div>
                          <div className="row align-items-center mb-2 d-flex">
                            <div className="col-8">
                              <h2 className="d-flex align-items-center mb-0">
                                {totalprog}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <div className="card l-bg-green-dark">
                        <div className="card-statistic-3 p-4">
                          <div className="card-icon card-icon-large">
                            <i className="fa fa-files-o fa-5x"></i>
                          </div>
                          <div className="mb-4">
                            <h5 className="card-title mb-0">
                              Licenças Fechados
                            </h5>
                          </div>
                          <div className="row align-items-center mb-2 d-flex">
                            <div className="col-8">
                              <h2 className="d-flex align-items-center mb-0">
                                {totalclose}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <div className="card l-bg-blue-dark">
                        <div className="card-statistic-3 p-4">
                          <div className="card-icon card-icon-large">
                            <i className="fa fa-users fa-5x"></i>
                          </div>
                          <div className="mb-4">
                            <h5 className="card-title mb-0">Clientes</h5>
                          </div>
                          <div className="row align-items-center mb-2 d-flex">
                            <div className="col-8">
                              <h2 className="d-flex align-items-center mb-0">
                                {totalclients}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row ">
                    <div className="col-xl-3 col-lg-6">
                      <div className="card l-bg-cherry">
                        <div className="card-statistic-3 p-4">
                          <div className="card-icon card-icon-large">
                            <i className="fa fa-file-o fa-5x"></i>
                          </div>
                          <div className="mb-4">
                            <h5 className="card-title mb-0">
                              Numero Cartas Isençāo
                            </h5>
                          </div>
                          <div className="row align-items-center mb-2 d-flex">
                            <div className="col-8">
                              <h2 className="d-flex align-items-center mb-0">
                                {totalletter}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <div className="card l-bg-orange-dark">
                        <div className="card-statistic-3 p-4">
                          <div className="card-icon card-icon-large">
                            <i className="fa fa-spinner fa-5x"></i>
                          </div>
                          <div className="mb-4">
                            <h5 className="card-title mb-0">
                              Cartas Isençāo Progresso
                            </h5>
                          </div>
                          <div className="row align-items-center mb-2 d-flex">
                            <div className="col-8">
                              <h2 className="d-flex align-items-center mb-0">
                                {totaletterprog}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <div className="card l-bg-green-dark">
                        <div className="card-statistic-3 p-4">
                          <div className="card-icon card-icon-large">
                            <i className="fa fa-files-o fa-5x"></i>
                          </div>
                          <div className="mb-4">
                            <h5 className="card-title mb-0">
                              Cartas Isençāo Fechados
                            </h5>
                          </div>
                          <div className="row align-items-center mb-2 d-flex">
                            <div className="col-8">
                              <h2 className="d-flex align-items-center mb-0">
                                {totaletterclose}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Home;
