import React, { useState, useEffect } from "react";
import RupeForm from "./forms/RupeForm";
import UseModal from "./common/useModal";
import Spinner from "./common/spinner";

import { useLocation } from "react-router-dom";

import auth from "../services/auth";
import io from "socket.io-client";
import OpenFile from "./common/openFile";

import {
  financeOneLetter,updateCicleOneLetter,
  deleteOpenFile,
  getOpenFiles,
  recordOpenfile,
} from "../services/source"; 


function DepartamnetoFinanceOneLetter() {
  const [showmodal, setShowModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [sucess, setSucess] = useState(false);

  let location = useLocation();
  const { file } = location.state || {};
  const fileopen = file.filenumber;
  const user = auth.getCurrentUser();
  const nome = user.data.nome;
  const obj = { fileopen, nome };
  const socket = io.connect(process.env.REACT_APP_SOCKET);

  useEffect(() => {
    getOpenFiles(obj).then((res) => {
      if (res.data === "true") return recordOpenfile(obj);
      else {
        setShowModal(true);
      }
    });
  }, [fileopen, nome]);

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const onSubmit = (data) => {
    const datecobmin = convert(data.datecobmin);
    const caducidade = convert(data.caducidade);
    const datenodeb = convert(data.datenodeb);
    const datapagcli = convert(data.datapagcli);
    const datapagcads = convert(data.datapagcads);
    const caseid = file.idfile;
    const username = user.data.id;
    const payload = {
      data,
      datecobmin,
      caducidade,
      datenodeb,
      datapagcli,
      datapagcads,
      caseid,
      username,
    };
    setLoad(true);
    financeOneLetter(payload)
    .then((res) => {
      if (res.data === "true") {
        updateCicleOneLetter(payload);
        deleteOpenFile(obj);
        setLoad(false);
        setSucess(true);
        socket.emit("newtask", {
          message: "Carta Isencao Atribuido " + fileopen,
          room: "Operacoes",
        });
      }
    });
  };

  return (
    <div className="container-fluid component">
      <Spinner active={load} />
      <UseModal
        stylehead={{
          background: "rgb(228 203 126 / 33%)",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          borderBottom: "1px solid black",
        }}
        stylebody={{ display: "none" }}
        show={sucess}
        close={() => (window.location.href = "/progressTable")}
        title={"Ficheiro criado com sucesso !!!"}
      />
      <UseModal
        show={showmodal}
        close={() => (window.location.href = "/progressTable")}
        title={"File " + fileopen}
        body={<OpenFile file={fileopen} util={nome} />}
      />
      <h3 className="text-dark mb-4">File Finanças</h3>
      <small>{fileopen}</small>
      <div className="row mb3">
        <div className="card mb-3">
          <RupeForm onSubmit={onSubmit} obj={obj} />
        </div>
      </div>
    </div>
  );
}

export default DepartamnetoFinanceOneLetter;
