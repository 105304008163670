import React, { useState, useEffect } from "react";
import "../styles/login.css";
import Alert from "react-bootstrap/Alert";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import auth from "../services/auth";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Campo Obrigatório")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Preencher com Valido Email"
    ),
  pass: yup
    .string()
    .required("Campo Obrigatório")
    .min(8, "Palavra Passe com min 8 carateres"),
});

function Login() {
  const [danger, setDanger] = useState(false);

  useEffect(() => {
    document.body.classList.add("blogin");
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await auth.login(data);
      window.location.href = "/home";
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        try {
          await auth.client(data);
          window.location.href = "/track";
        } catch (clientEx) {
          console.error("Error with auth.client:", clientEx);
        }
      } else {
        console.error("Error:", ex);
      }
    }
  };

  return (
    <div>
      <video
        muted
        loop
        autoPlay
        typeof="video/mp4"
        className="cadsvideo"
        src={process.env.PUBLIC_URL + "assets/video/video.mp4"}
      ></video>
      <div className="video-overlay"></div>
      <div className="mainlogin">
        <input className="in" type="checkbox" id="chk" aria-hidden="true" />
        <div className="signup">
          <label htmlFor="chk" aria-hidden="true" className="la">
            <img
              src={process.env.PUBLIC_URL + "assets/img/letterwhite.png"}
              alt="cads"
              className="img-fluid"
            />
          </label>
          <img
            src={process.env.PUBLIC_URL + "assets/img/logo.png"}
            alt="logo"
            className="img-fluid"
          />
        </div>
        <div className="login">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="chk" aria-hidden="true" className="la">
              Entrar
            </label>
            <input
              className="in"
              type="email"
              {...register("email")}
              placeholder="Email"
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.email?.message}
            </p>
            <input
              className="in"
              type="password"
              {...register("pass")}
              placeholder="Palavra Passe"
            />{" "}
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.pass?.message}
            </p>
            <button type="submit" className="bt">
              Submeter
            </button>
            <Alert
              show={danger}
              variant="danger"
              dismissible
              onClose={() => setDanger(false)}
            >
              Utilizador ou Palavra Passe invalido !!!
            </Alert>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
