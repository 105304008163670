import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputData from "./inputdata";
import FileUpload from "./fileUpload";
import FileList from "./fileList";
import auth from "../../services/auth";
import UseModal from "./useModal";
import OpenFile from "./openFile";
import {
  submitFile,
  submitLetter,
  createCase,
  createCaseLetter,
  arquiveProtocoloSub,
  getArqProSub,
  recordOpenfile,
  getOpenFiles,
  deleteOpenFile,
} from "../../services/source";
import Spinner from "../common/spinner";
import ReadOnly from "./readOnly";
import io from "socket.io-client";

function FileSubmition(data) {
  const [showmodal, setShowModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [files, setFiles] = useState([]);
  const [showDatas, setShowDatas] = useState(false);
  const [isento, setIsento] = useState(false);

  const socket = io.connect(process.env.REACT_APP_SOCKET);

  const user = auth.getCurrentUser();
  const nome = user.data.nome;
  const fileopen = data.filenumber;
  const obj = { fileopen, nome };

  useEffect(() => {
    getOpenFiles(obj).then((res) => {
      if (res.data === "true") return recordOpenfile(obj);
      else {
        setShowModal(true);
      }
    });
  }, [fileopen, nome]);

  useEffect(() => {
    async function getArquive() {
      const result = await getArqProSub(data);
      setShowDatas(result.data);
    }
    getArquive();
  }, []);

  useEffect(() => {
    if (data.service === "INIQ") {
      setIsento(true);
    } else if (data.service === "INACOM") {
      setIsento(true);
    } else setIsento(false);
  }, []);

  const schema = yup.object().shape({
    casenumber: yup.string().notRequired(),
    datesubmit: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
    datepromin: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
  });

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const file = data.filenumber;
  const source = data;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const datesub = convert(data.datesubmit);
    const datepro = convert(data.datepromin);
    const payload = {
      isento,
      source,
      datesub: datesub,
      datepro: datepro,
      casenumber: data.casenumber,
    };
    setLoad(true);
    if (file.startsWith("CADS8")) {
      try {
        await submitFile(payload)
          .then((res) => {
            if (res.data === "true") {
              createCase(payload);
              deleteOpenFile();
            }
          })
          .then(() => window.location.reload());
      } catch (error) {
        console.error(error);
      }
    } else if (file.startsWith("CADS9")) {
      try {
        await submitLetter(payload)
          .then((res) => {
            if (res.data === "true") {
              createCaseLetter(payload);
              deleteOpenFile();
            }
          })
          .then(() => window.location.reload());
      } catch (error) {
        console.error(error);
      }
    }
    setLoad(false);
    deleteOpenFile(obj);

    socket.emit("newtask", {
      message: "Nova Licensa " + file,
      room: "Financas",
    });
  };

  return (
    <div>
      <Spinner active={load} />
      <UseModal
        show={showmodal}
        close={() => (window.location.href = "/home")}
        title={"File " + fileopen}
        body={<OpenFile file={fileopen} util={nome} />}
      />
      <ReadOnly
        filenumber={data.filenumber}
        opencasedate={data.opencasedate}
        clients={data.clients}
        importador={data.importador}
        refclient={data.refclient}
        labeltransp={data.labeltransp}
        doctransp={data.doctransp}
        dateservice={data.dateservice}
        refimpor={data.refimpor}
        service={data.service}
        valorfactimpor={data.valorfactimpor}
        valoremolu={data.valoremolu}
        moeda={data.moeda}
        protocolo={false}
        file_name={data.file_name}
      />

      <div className="m-3">
        {/* Upload File */}
        <FileUpload
          files={files}
          setFiles={setFiles}
          titulo="Protocolo Submissão"
          casenumber={data.casenumber}
          setShowDatas={setShowDatas}
          showDatas={showDatas}
          arquive={arquiveProtocoloSub}
          filenumber={data.filenumber}
        />
        <FileList files={files} removeFile={removeFile} />

        {/* ##################### */}
        {showDatas ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              value={data.casenumber}
              {...register("casenumber")}
              autoFocus
            />
            {/* Data Submisso nos Ministerios */}
            <div className="row mb-3 justify-content-center">
              <div className="col-md-3">
                <InputData
                  name="datesubmit"
                  label="Data Submissāo Ao Ministerio"
                  control={control}
                  errors={errors}
                  isclear={true}
                />
              </div>
              {/*  Data Pronunciamento */}
              <div className="col-md-3">
                <InputData
                  name="datepromin"
                  label="Data Pronuncionamento"
                  control={control}
                  errors={errors}
                  isclear={true}
                />
              </div>
            </div>
            <div className="m-4"></div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-success col-md-2 "
                type="submit"
              >
                Submeter
              </button>
              <div className="m-2"></div>
              <button
                className=" btn btn-outline-danger col-md-2"
                type="button"
                onClick={() => reset()}
              >
                Apagar
              </button>
            </div>
          </form>
        ) : null}
      </div>
    </div>
  );
}

export default FileSubmition;
