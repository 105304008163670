import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import LoadingOverlay from "react-loading-overlay-ts";

function Spinner(props) {
  return (
    <LoadingOverlay
      active={props.active}
      styles={{
        wrapper: {},
        overlay: (base) => ({
          ...base,
          background: "#4e3f2545",
        }),
      }}
      spinner={
        <ScaleLoader
          cssOverride={{
            display: "block",
            position: "absolute",
            color: "#4e3f25",
          }}
          height={45}
        />
      }
    ></LoadingOverlay>
  );
}

export default Spinner;
