import React from "react";
import { Text, StyleSheet, Image, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    image: {  width: "6cm", marginLeft:"auto", marginRight:"auto", marginTop:"20px"},
});

const pdfCover = () => {
    return(
        <View>
        <Image 
        style={styles.image}
            src={process.env.PUBLIC_URL + "assets/img/cads_bw.png"}
        />

    </View>
    )
    
}

export default pdfCover;