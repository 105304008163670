import React from "react";
import { Text, StyleSheet, Image, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  image: {  width: "8cm", marginLeft: "50%" },
  endereco: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    display: "flex",
   
  },
 
});

const pdfHeading = () => {
  return (
    <View>
      <Image
        style={styles.image}
        src={process.env.PUBLIC_URL + "assets/img/cads.png"}
      />
      <Text
        style={[
          styles.endereco,
          { position: "absolute", top: 10, left: "1cm" },
        ]}
      >
        Sede Rua de Benguela 13 /Bairro Patrice Lumumba
      </Text>
      <Text
        style={[
          styles.endereco,
          { position: "absolute", top: 25, left: "1cm" },
        ]}
      >
        Caixa Postal 33892 Luanda. República de Angola
      </Text>
      <Text
        style={[
          styles.endereco,
          { position: "absolute", top:40, left: "1cm" },
        ]}
      >
        Phone: +244 (231) 202 087 / +244 (923) 663 836
      </Text>
     
    
    </View>
  );
};
export default pdfHeading;
