import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Select from "react-select";
import { getLetterData } from "../services/source";
import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import pdfHeading from "./common/pdfHeading";
import { useForm } from "react-hook-form";

function ProtocolDeleveryLetter() {
  const [doc, setDoc] = useState([]);
  const [getSelect, setGetSelect] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [showPDF, setShowPDF] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [prep, setPrep] = useState();
  const [cel, setCel] = useState();
  const [dat, setDat] = useState();
  const [con, setCon] = useState();




  useEffect(() => {
    async function getDocument() {
      const result = await getLetterData();
      setDoc(result.data);
    }
    getDocument();
  }, []);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setPrep(data.preparado);
    setCel(data.celula);
    setDat(data.data);
    setCon(data.contact);
  };

  var filenumbers = [];
  doc.forEach((item) => {
    filenumbers.push({ label: item.filenumber, value: item.filenumber });
  });

  const handlechange = (data, clearValue) => {
    if (clearValue.action === "select-option") {
      for (let i = 0; i < data.length; i++) {
        const proto = doc.filter((val) => {
          if (val.filenumber.includes(data[i].label)) {
            return val;
          }
          return null;
        });
        const input = [...getSelect];
        input.push(proto);
        setGetSelect(input);
      }
    } else if (clearValue.action === "clear") {
      setGetSelect([]);
      setTotalData([]);
    } else if (clearValue.action === "remove-value") {
      var item = clearValue.removedValue.value;
      var arr = getSelect.filter((val) => {
        if (val[0].filenumber !== item) {
          return val;
        }
        return null;
      });
      setGetSelect(arr);
    }
  };

  const addMultiRows = (data) => {
    const totalDocs = getSelect.length;
    data.id = totalDocs + 1;
    setTotalData(getSelect);
  };

  const generatePDF = () => {
    showPDF ? setShowPDF(false) : setShowPDF(true);
  };

  const styles = StyleSheet.create({
    body: {
      paddingTop: 30,
      paddingBottom: 68,
    },
    subtitle: { textAlign: "center", fontSize: 15, paddingBottom: 10 },
    line: { marginBottom: 10 },
    title: { textAlign: "center" },
    tableRowStyle: {
      flexDirection: "row",
      paddingTop: 0,
      paddingHorizontal: 20,
    },
    tableStyle: { display: "table", width: "auto" },
    firstTableColHeaderStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    tableColHeaderStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    tableCellHeaderStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 9,
      fontWeight: "bold",
    },
    firstTableColStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    tableCellStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 10,
    },
    tableColStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    total: {
      marginTop: 10,
      marginLeft: "68%",
      fontSize: 12,
      fontWeight: "bold",
    },
    assinaturas: {
      fontSize: 12,
      fontWeight: "bold",
      paddingBottom: 20,
      marginLeft: 40,
    },
    assifirst: {
      marginTop: 50,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    commentableStyle: {
      marginTop: "4%",
    },
    commentableRowStyle: {
      flexDirection: "row",
      paddingTop: 0,
      paddingHorizontal: 20,
    },
    commnetfirstTableColStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
      padding: "1%",
      backgroundColor: "#bdbdbd",
    },
    commentableCellStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 10,
    },
    commnetsecondTableColStyle: {
      width: "80%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    tableColHeaderStyleCelula: {
      width: "10%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    tableColHeaderStyleVisto: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    firstTableColStylePrep: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
      paddingTop: "3%",
    },
    tableColStyleCelula: {
      width: "10%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      padding: "3%",
    },
    tableColStyleData: {
      width: "10%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      paddingTop: "3%",
    },
    tableColStyleVisto: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    firstTableColHeaderStyleEntrega: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    tableColHeaderStyleCarrimbo: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    firstTableColStylEntrega: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
      padding: "2%",
    },
    tableColStyleCarr: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
  });

  const createTableHeader = () => {
    return (
      <View style={styles.tableRowStyle} fixed>
        <View style={styles.firstTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Nº</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>N/FILE</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>V/FILE</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>
            Nº DA FATURA DE IMPORTAÇÃO
          </Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>DESIGNAÇÃO</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>OBSERVAÇÕES</Text>
        </View>
      </View>
    );
  };

  const createTableHeaderSec = () => {
    return (
      <View style={styles.tableRowStyle} fixed>
        <View style={styles.firstTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Preparado por (Nome)</Text>
        </View>
        <View style={styles.tableColHeaderStyleCelula}>
          <Text style={styles.tableCellHeaderStyle}>Célula</Text>
        </View>

        <View style={styles.tableColHeaderStyleCelula}>
          <Text style={styles.tableCellHeaderStyle}>Data & Hora</Text>
        </View>

        <View style={styles.tableColHeaderStyleVisto}>
          <Text style={styles.tableCellHeaderStyle}>
            Visto por (Nome & Assinatura)
          </Text>
        </View>
        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Contactos</Text>
        </View>
      </View>
    );
  };

  const createTableHeaderTree = () => {
    return (
      <View style={styles.tableRowStyle} fixed>
        <View style={styles.firstTableColHeaderStyleEntrega}>
          <Text style={styles.tableCellHeaderStyle}>
            Entrega a DSV - Panalpina
          </Text>
        </View>
        <View style={styles.tableColHeaderStyleCarrimbo}>
          <Text style={styles.tableCellHeaderStyle}>
            Carrimbo & Assinatura{" "}
          </Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Data</Text>
        </View>
      </View>
    );
  };

  const next = () => {
    showNext ? setShowNext(false) : setShowNext(true);
  };

  const expbtn = () => {
    showBtn ? setShowBtn(false) : setShowBtn(true);
  };

  return (
    <div>
    <div className="container-fluid component">
        <div
          id="layoutSidenav_content"
          style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <div className="container-fluid px4">
              <div className="card mt-4">
                <div className="card-header">
                  <div className="col-md-12">
                    <h1>
                      <i className="fa fa-file-text-o" aria-hidden="true">
                        {" "}
                      </i>
                      &nbsp; Protocolo de Entrega de Documentos Originais
                    </h1>{" "}
                    <small>Exo Letter</small>
                  </div>
                </div>
                <div className="card-body">
                  {!showPDF ? (
                    <div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <Select
                            options={filenumbers}
                            onChange={handlechange}
                            isMulti
                            placeholder="Selecionar..."
                          />
                          <br />
                        </div>
                        <div className="col-md-1">
                          <button
                            className="btn btn-primary"
                            onClick={() => addMultiRows(getSelect)}
                          >
                            Adicionar
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn btn-outline-success"
                            onClick={() => next()}
                          >
                            <i
                              className="fa fa-arrow-circle-o-right fa-lg"
                              aria-hidden="true"
                            >
                              {" "}
                              Avançar{" "}
                            </i>
                          </button>
                        </div>
                      </div>
                      <Table striped bordered hover>
                        <thead>
                          <tr style={{ textAling: "center" }}>
                            <th>N/FILE</th>
                            <th>V/FILE</th>
                            <th>Nº DA FATURA DE IMPORTAÇÃO</th>
                            <th>DESIGNAÇÃO</th>
                            <th>OBSERVAÇÕES</th>
                          </tr>
                        </thead>
                        <tbody>
                          {totalData.map((val, index) => {
                            return (
                              <tr key={index}>
                                <td>{val[0].filenumber}</td>
                                <td>{val[0].refclient}</td>
                                <td className="wrap-text">{val[0].refimpor}</td>
                                <td>{val[0].service}</td>
                                <td></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      {showNext ? (
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row mb-3">
                            <div className="col-md-3">
                              <div className="form-floating mb-3 mb-md-0">
                                <input
                                  className="form-control"
                                  id="preparado"
                                  type="text"
                                  {...register("preparado")}
                                  placeholder="preparado"
                                />
                                <label htmlFor="preparado">
                                  Preparado Por (Nome):
                                </label>
                              </div>
                            </div>
                            <div className="col-md-1">
                              <div className="form-floating mb-3 mb-md-0">
                                <input
                                  style={{ textAlign: "center" }}
                                  className="form-control"
                                  id="celula"
                                  type="text"
                                  {...register("celula")}
                                  placeholder="celula"
                                />
                                <label htmlFor="celula">Célula:</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating mb-3 mb-md-0">
                                <input
                                  className="form-control"
                                  id="data"
                                  type="text"
                                  {...register("data")}
                                  placeholder="data"
                                />
                                <label htmlFor="data">Data & Hora:</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-floating mb-3 mb-md-0">
                                <input
                                  className="form-control"
                                  id="contact"
                                  type="text"
                                  {...register("contact")}
                                  placeholder="contact"
                                />
                                <label htmlFor="contactos">Contactos:</label>
                              </div>
                            </div>
                            <div className="col-md-1">
                              <button
                                className="btn btn-outline-success"
                                onClick={() => expbtn()}
                                type="submit"
                                style={{ marginTop: "5%" }}
                              >
                                <i
                                  className="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : null}
                    </div>
                  ) : null}
                  {showBtn ? (
                    <div className="row mb-12">
                      <div className="col-md-10">
                        <button
                          className="btn btn-success"
                          onClick={() => generatePDF()}
                        >
                          {showPDF ? (
                            <i
                              className="fa fa-arrow-circle-left"
                              // onClick={() =>{ setInvoiceData([]); setGetSelect([])}}
                            >
                              &nbsp;Voltar
                            </i>
                          ) : (
                            <i className="fa fa-file-pdf-o">&nbsp;Exportar</i>
                          )}
                        </button>
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-danger"
                          onClick={() => window.location.reload(false)}
                        >
                          <i className="fa fa-times-circle">&nbsp;Fechar</i>
                        </button>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <div style={{ paddingLeft: "10%" }}>
                    {showPDF ? (
                      <PDFViewer width={800} height={1000} showToolbar>
                        <Document>
                          <Page style={styles.body}>
                            <View>{pdfHeading()}</View>
                            <Text style={styles.line}>
                              ____________________________________________________________
                            </Text>
                            <Text style={styles.title}>
                              PROTOCOLO DE ENTREGA DE DOCUMENTOS ORIGINAIS
                            </Text>
                            <Text style={styles.line}>
                              ____________________________________________________________
                            </Text>
                            <Text style={styles.subtitle}>
                              "SECTOR LICENSE"
                            </Text>
                            <View style={styles.tableStyle}>
                              {createTableHeader()}
                              {totalData.map((data, index) => {
                                const lines = data[0].refimpor.split('/');
                                return (
                                  <View
                                    key={index}
                                    style={styles.tableRowStyle}
                                  >
                                    <View style={styles.firstTableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {index + 1}
                                      </Text>
                                    </View>
                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].filenumber}
                                      </Text>
                                    </View>
                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].refclient}
                                      </Text>
                                    </View>
                                  
                                    <View style={styles.tableColStyle}>
                                      <Text
                                        style={styles.tableCellStyle}
                                      >
                                       {lines}
                                      </Text>
                                    </View>
                                 

                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].service}
                                      </Text>
                                    </View>
                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              })}
                            </View>

                            <View style={styles.commentableStyle}>
                              <View style={styles.commentableRowStyle}>
                                <View style={styles.commnetfirstTableColStyle}>
                                  <Text style={styles.commentableCellStyle}>
                                    {" "}
                                    Comentários
                                  </Text>
                                </View>
                                <View style={styles.commnetsecondTableColStyle}>
                                  <Text style={styles.commentableCellStyle}>
                                    {}
                                  </Text>
                                </View>
                              </View>
                            </View>

                            <View style={styles.commentableStyle}>
                              {createTableHeaderSec()}
                              <View style={styles.tableRowStyle}>
                                <View style={styles.firstTableColStylePrep}>
                                  <Text style={styles.tableCellStyle}>
                                    {prep}
                                  </Text>
                                </View>
                                <View style={styles.tableColStyleCelula}>
                                  <Text style={styles.tableCellStyle}>
                                    {cel}
                                  </Text>
                                </View>
                                <View style={styles.tableColStyleData}>
                                  <Text style={styles.tableCellStyle}>
                                    {dat}
                                  </Text>
                                </View>
                                <View style={styles.tableColStyleVisto}>
                                  <Text style={styles.tableCellStyle}></Text>
                                </View>
                                <View style={styles.tableColStyle}>
                                  <Text style={styles.tableCellStyle}>
                                    {con}
                                  </Text>
                                </View>
                              </View>
                            </View>

                            <View style={styles.commentableStyle}>
                              {createTableHeaderTree()}
                              <View style={styles.tableRowStyle}>
                                <View style={styles.firstTableColStylEntrega}>
                                  <Text style={styles.tableCellStyle}></Text>
                                </View>
                                <View style={styles.tableColStyleCarr}>
                                  <Text style={styles.tableCellStyle}></Text>
                                </View>
                                <View style={styles.tableColStyle}>
                                  <Text style={styles.tableCellStyle}></Text>
                                </View>
                              </View>
                            </View>

                            <Text
                              style={styles.pageNumber}
                              render={({ pageNumber, totalPages }) =>
                                `${pageNumber} / ${totalPages}`
                              }
                              fixed
                            />
                          </Page>
                        </Document>
                      </PDFViewer>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ProtocolDeleveryLetter;
