import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { createClient } from "../services/source";
import ClientTable from "../components/table/clientTable";


import ClientForm from "./forms/clientForm";

function Clients() {
  const [sucess, setSucess] = useState(false);

  const disableFields = {
    company: false,
    nif: false,
    email: false,
    phone: false,
    address: false,
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    await createClient(data).then((res) => {
      if (res.data === "true") {
        e.target.reset();
        setSucess(true);
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="container-fluid d-flex flex-column component">
        <h3 className="text-dark mb-4">Clientes</h3>
        <div className="row mb-3">
          <div className="card mb-3">
            <Alert
              show={sucess}
              variant="success"
              dismissible
              onClose={() => (window.location.href = "/clients")}
            >
              Registo efectuado com sucesso!!!
            </Alert>

            <ClientForm
              onSubmit={onSubmit}
              lableButton="Criar Cliente"
              disabled={disableFields}
              buttonClass="btn btn-primary"
            />

            <div className="card mb-4 mt-4">
              <div className="card-header">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-table me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                </svg>
                Tabela de Clientes
              </div>
              <div className="card-body">
                <ClientTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
