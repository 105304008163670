import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const InputButton = ({
  placeholder,
  register,
  button,
  onClick,
  label,
  name,
  errors,
  value,
}) => {
  return (
    <div>
      <label className="breadcrumb-item active" htmlFor={name}>
        {label}
      </label>

      <InputGroup className="mb-3">
        <Button variant="outline-danger" id="button-addon2" onClick={onClick}>
          {button}
        </Button>
        <Form.Control
          placeholder={placeholder}
          name={name}
          {...register(name)}
          disabled
          value={value}
        />
      </InputGroup>

      <p style={{ color: "red" }}>{errors[name]?.message}</p>
    </div>
  );
};

export default InputButton;
