import React from "react";
import { useForm } from "react-hook-form";
import Input from "../common/input";
import InputSelect from "../common/inputselect";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  firstName: yup.string().required("Campo Obrigatório"),
  lastName: yup.string().required("Campo Obrigatório"),
  email: yup
    .string()
    .required("Campo Obrigatório")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Preencher com Valido Email"
    ),
  company: yup.string().required("Campo Obrigatório"),
  pass: yup.string(),
  confirpass: yup
    .string()
    .oneOf([yup.ref("pass")], "As palavras passe tem que ser iguais"),
});

const ContactoForm = ({
  onSubmit,
  disabled,
  defaultValues,
  buttonClass,
  options,
  labelButton,
  selabel,
  but,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body shadow p-3">
          {/* ############################# Nome ########################   */}
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="firstName"
                  type="text"
                  errors={errors}
                  label="Nome"
                  placeholder="Nome"
                  disabled={disabled.firstName}
                />
              </div>
            </div>
            {/* ############################# Apelido ########################   */}

            <div className="col-md-6">
              <div className="form-floating">
                <Input
                  register={register}
                  name="lastName"
                  type="text"
                  errors={errors}
                  label="Apelido"
                  placeholder="Apelido"
                  disabled={disabled.lastName}
                />
              </div>
            </div>
          </div>
          {/* ############################# Password #######################   */}

          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="pass"
                  type="password"
                  errors={errors}
                  label="Palavra Passe"
                  placeholder="Palavra Passe"
                  disabled={disabled.pass}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="confirpass"
                  type="password"
                  errors={errors}
                  label=" Confirmar Palavra Passe"
                  placeholder=" Confirmar Palavra Passe"
                  disabled={disabled.confirpass}
                />
              </div>
            </div>
          </div>

          {/* ############################# Email #######################   */}
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="email"
                  type="text"
                  errors={errors}
                  label=" Email"
                  placeholder=" Email"
                  disabled={disabled.email}
                />
              </div>
            </div>
            {/* ############################# Empresa #######################   */}

            <div className="col-md-6">
              <div className="form-floating mb-3">
                <InputSelect
                  value={defaultValues.company}
                  label={selabel}
                  name="company"
                  register={register}
                  options={options}
                  errors={errors}
                  disabled={disabled.company}
                />
              </div>
            </div>
          </div>
          {/* ############################# Contactos #######################   */}
          <div className="row mb-3">
            {/* ############################# Unitel #######################   */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="phone1"
                  type="text"
                  errors={errors}
                  label=" Telemóvel"
                  placeholder=" Telemóvel"
                  disabled={disabled.phone1}
                />
              </div>
            </div>
            {/* ############################# Movicel #######################   */}
            <div className="col-md-6">
              <div className="form-floating mb-3 mb-md-0">
                <Input
                  register={register}
                  name="phone2"
                  type="text"
                  errors={errors}
                  label=" Telemóvel"
                  placeholder=" Telemóvel"
                  disabled={disabled.phone2}
                />
              </div>
            </div>
          </div>

          {/* ####################################################   */}
          <div className="mt-4 mb-0">
            <div className="d-grid">
              <button type="submit" hidden={but} className={buttonClass}>
                {labelButton}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactoForm;
