import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UseModal from "../common/useModal";
import EditUser from "../editUser";
import DelUser from "../delUser";
import { getUsers } from "../../services/source";

function UserTable() {

    const [user, setUser] = useState([]);
    const [edit, setEdit] = useState(false);
    const [editdata, setEditData] = useState({});
    const [deldata, setDelData] = useState({});
    const [del, setDel] = useState(false);
  
    const handleEdit = (edit) => {
      setEdit(true);
      setEditData(edit);
    };
  
    const handleDelete = (del) => {
      setDel(true);
      setDelData(del);
    };
  
    useEffect(() => {
      async function getListUser() {
        const result = await getUsers();
        setUser(result.data);
      }
      getListUser();
    }, []);
    
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Apelido</th>
            <th>Email</th>
            <th>Role</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {user.map((u) => (
            <tr key={u.id}>
              <td>{u.id}</td>
              <td>{u.nome}</td>
              <td>{u.apelido}</td>
              <td>{u.email}</td>
              <td>{u.role}</td>
              <td>
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Opções"
                  variant="secundary"
                >
                  <Dropdown.Item href="#/editar" onClick={() => handleEdit(u)}>
                    <i className="fa fa-edit">Editar</i>
                  </Dropdown.Item>

                  <UseModal
                    size={"lg"}
                    show={edit}
                    close={() => setEdit(false)}
                    body={<EditUser {...editdata} />}
                    title="Editar"
                  />

                  <Dropdown.Item
                    href="#/apagar"
                    onClick={() => handleDelete(u)}
                  >
                    <i className="fa fa-trash">Apagar</i>
                  </Dropdown.Item>

                  <UseModal
                    show={del}
                    close={() => setDel(false)}
                    body={<DelUser {...deldata} />}
                    title="Apagar"
                  />
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default UserTable;
