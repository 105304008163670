import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";

import { getEditLetter } from "../services/source";
import EditCreateLetter from "./common/editCreateLetter";
import EditFinanceOneLetter from "./common/editFinanceOneLetter";
import EditOperationOneLetter from "./common/editOperationOneLetter";
import EditFinanceTwoLetter from "./common/editFinanceTwoLetter";

function EditLetter() {
  const [letter, setLetter] = useState([]);
  const [letterobj, setLetterObj] = useState([]);
  const [showcicle, setShowCicle] = useState();
  const [showClick, setShowClick] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sucess, setSucess] = useState(false);

  useEffect(() => {
    async function getLetterEdit() {
      const result = await getEditLetter();
      setLetter(result.data);
    }
    getLetterEdit();
  }, []);

  const { register, handleSubmit } = useForm({});

  const onSubmit = (data) => {
    if (searchValue.startsWith("CADS9")) {
    const lic = data.licensa;
    let obj = letter?.filter((obj) => obj.filenumber === lic);
    setLetterObj(obj);
    setShowCicle(obj[0]?.cicle);
    setShowClick(true);}else{
      setSucess(true);
    }
  };

  const handleCloseAlert = () =>{
    setSucess(false)
  }

  return (
    <div className="container-fluid component">
            <div className="container-fluid px4">
              <div className="card mb-4 mt-4">
                <div className="card-header">Edit Cartas</div>
                <div className="card-body">
                  {/* ############################## Formulario ############################## */}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* ############### Procurar filenumber ################# */}
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <button
                          className="btn btn-outline-success"
                          type="submit"
                          disabled={showClick}
                        >
                          <i className="fa fa-search">&nbsp;</i>
                          Procurar
                        </button>
                      </div>
                      <input
                        type="text"
                        {...register("licensa")}
                        className="form-control"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                    <Alert
                      show={sucess}
                      variant="danger"
                      onClick={handleCloseAlert}
                    >
                      Numero de Carta invalido !!!
                    </Alert>
                    {/* ############### End ############*/}
                  </form>
                  {/* ################## inserir os files para editar ############### */}
                  <div>
                    {(() => {
                      switch (showcicle) {
                        case "4":
                          return <EditCreateLetter file={letterobj} />;
                        case "5":
                          return (
                            <>
                              <EditCreateLetter file={letterobj} />
                              <EditFinanceOneLetter file={letterobj} />
                            </>
                          );
                        case "6":
                          return (
                            <>
                              <EditCreateLetter file={letterobj} />
                              <EditFinanceOneLetter file={letterobj} />
                              <EditOperationOneLetter file={letterobj} />
                            </>
                          );
                        case "Fechado":
                          return (
                            <>
                              <EditCreateLetter file={letterobj} />
                              <EditFinanceOneLetter file={letterobj} />
                              <EditOperationOneLetter file={letterobj} />
                              <EditFinanceTwoLetter file={letterobj} />
                            </>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>

                  {/* ############### End ############*/}
                </div>
              </div>
            </div>
            </div>
      
  );
}

export default EditLetter;
