import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { getControlItems } from "../services/source";

function ControlTable() {
  const [control, setControl] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function getItemsForControl() {
      const result = await getControlItems();
      setControl(result.data);
    }
    getItemsForControl();
  }, []);

  const displayControl = control

    .filter((val)=>{
      if(searchTerm === ""){
        return val;
      }else if(
        val.filenumber.toLowerCase().includes(searchTerm.toLowerCase())
      ){
        return val;
      }else if(
        val.refclient.toLowerCase().includes(searchTerm.toLowerCase())
      ){
        return val;
      }else if(
        val.refimpor.toLowerCase().includes(searchTerm.toLowerCase())
      ){
        return val;
      }else if(
        val.opencasedate.toLowerCase().includes(searchTerm.toLowerCase())
      ){
        return val;
      }
      return null;
    })
    .sort((a, b) => a.filenumber.localeCompare(b.filenumber))
    .map((c, index) => {
      let datepro = c.datepro;
      let datapag;
      let pagrupe = c.pagamentoRupe;
      let cads = new Date(c.datapagcads);
      let client = new Date(c.datapagcli);
      const opencase = new Date(c.opencasedate);
      let pronunciamento = new Date(datepro);
      let datelic = new Date(c.datereclic);
      let datapagcont;
      let licensa;

      if (datepro === "NaN-aN-aN") {
        datepro = "";
      } else if (datepro) {
        datepro = Math.ceil(
          (pronunciamento - opencase) / (1000 * 60 * 60 * 24)
        );
      }

      switch (true) {
        case c.service === "INACOM":
        case c.service === "INIQ":
          datapag = "ISENTO";
          break;
        case pagrupe === "cads":
          datapagcont = c.datapagcads;
          datapag = Math.ceil((cads - pronunciamento) / (1000 * 60 * 60 * 24));
          break;
        case pagrupe === "client":
          datapagcont = c.datapagcli;
          datapag = Math.ceil(
            (client - pronunciamento) / (1000 * 60 * 60 * 24)
          );
          break;
        default:
          datapag = null;
      }

      if (datapagcont !== undefined && datapagcont !== "") {
        let usedate = new Date(datapagcont);
        licensa = Math.ceil((datelic - usedate) / (1000 * 60 * 60 * 24));
      } else if (datapagcont === "") {
        licensa = Math.ceil((datelic - pronunciamento) / (1000 * 60 * 60 * 24));
      }

      licensa = licensa < 0 ? "" : licensa;

      let total =
        (datapag === "ISENTO" ? 0 : datapag ? datapag : 0) +
        (datepro ? datepro : 0) +
        (licensa ? licensa : 0);

      total = total < 0 ? " " : total;

      // let line = c.refimpor.split("/");

      return (
        <tr key={index}>
          <td>{c.opencasedate}</td>
          <td>{c.filenumber}</td>
          <td>{c.refclient}</td>
          <td style={{width:130, wordBreak:"break-all"}}>{c.refimpor}</td>
          <td>{c.service}</td>
          <td style={{ color: datepro > 2 ? "red" : "inherit" }}>{datepro}</td>
          <td style={{ color: datapag > 2 ? "red" : "inherit" }}>{datapag}</td>
          <td style={{ color: licensa > 2 ? "red" : "inherit" }}>{licensa}</td>
          <td>{total}</td>
        </tr>
      );
    });

  return (
    <>
    <div className="container-fluid component">
        <div
          id="layoutSidenav_content"
          style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <div className="container-fluid px4">
              <h1 className="mt-4">Control Ficheiros</h1>
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active">Ficheiros</li>
              </ol>
              <div className="card mb-4 mt-4">
                <div className="card-header">
                  <i className="fa fa-table me-1"></i>
                  Tabela de Controlo
                </div>
                <div className="card-body">
                  <div className="dataTable-top">
                    <div className="dataTable-search">
                      <div className="input-group">
                        <input
                          className="form-control mb-4"
                          type="text"
                          placeholder="Procurar..."
                          aria-label="Search for..."
                          aria-describedby="btnNavbarSearch"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Data Abertura</th>
                        <th>N File</th>
                        <th>V. Ref</th>
                        <th>Fact Import</th>
                        <th>Desc Servicos</th>
                        <th>Data Pronunciamento</th>
                        <th>Data Pag Rupe</th>
                        <th>Data Licensa</th>
                        <th>Total Dias</th>
                      </tr>
                    </thead>
                    <tbody>{displayControl}</tbody>
                  </Table>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default ControlTable;
