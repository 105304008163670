import React, { useState, useEffect } from "react";
import ContactoForm from './forms/contactosForm';
import { getClients } from "../services/source";



function ViewContact(data) {
    
    useEffect(() => {
        async function getCli() {
          const res = await getClients();
          setClients(res.data);
        }
        getCli();
      }, []);


    const [clients, setClients] = useState([]);

    const disableFiels = {
        firstName: true,
        lastName: true,
        email: true,
        company: true,
        pass: true,
        confirpass: true,
        phone1: true,
        phone2: true,
      };

      const initalValues = {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        company: data.company,
        phone1: data.phone1,
        phone2: data.phone2,
      };

      const options = clients.map((client) => ({
        label: client.company, // Use the company name as the label
        value: client.company, // Use the client id as the value
      }));

    return ( <>
    <ContactoForm
        disabled={disableFiels}
        defaultValues={initalValues}
        options={options}
        but={true}
        selabel={initalValues.company}
    />
    
    </> );
}

export default ViewContact;