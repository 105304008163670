import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { NumericFormat } from "react-number-format";
import Spinner from "./spinner";
import UseModal from "./useModal";


import { getClients, getImportador, updateCreateLetter } from "../../services/source";
import Input from "./input";
import auth from "../../services/auth";

function EditCreateLetter(data) {
  const [clients, setClients] = useState([]);
  const [imports, setImports] = useState([]);
  const [isento, setIsento] = useState(false);
  const [load, setLoad] = useState(false);
  const [sucess, setSucess] = useState(false);

  useEffect(() => {
    async function getCli() {
      const res = await getClients();
      setClients(res.data);
    }
    getCli();
    async function getImpor() {
      const imp = await getImportador();
      setImports(imp.data);
    }
    getImpor();
  }, []);

  const user = auth.getCurrentUser();
  let dateopen = new Date(data.file[0].opencasedate);
  let clientes = data.file[0].clients;
  let importador = data.file[0].importador;
  let refclient = data.file[0].refclient;
  let labeltransp = data.file[0].labeltransp;
  let doctransp = data.file[0].doctransp;
  let dateservice = new Date(data.file[0].dateservice);
  let service = data.file[0].service;
  let refactimpor = data.file[0].refimpor;
  let valorfactimpor = data.file[0].valorfactimpor;
  let moeda = data.file[0].moeda;
  let valoremolumentos = data.file[0].valoremolu;
  let datesubmin = new Date(data.file[0].datesubmit);
  let datepromin = new Date(data.file[0].datepro);
  let id = data.file[0].casenumber;
  let filenumber = data.file[0].filenumber;


  const defaultValues = {
    dateopenfile: dateopen,
    client: clientes,
    importador: importador,
    clientfileref: refclient,
    labeltransport: labeltransp,
    docTransporte: doctransp,
    dateservice: dateservice,
    service: service,
    refactimpor: refactimpor,
    valorfactimpor: valorfactimpor,
    moeda: moeda,
    valoremolumentos: valoremolumentos,
    datesubmin: datesubmin,
    datepromin: datepromin,
  };

  registerLocale("pt", pt);

  const handleIsento = (e) => {
    if (e.target.value === "INIQ") {
      setIsento(true);
    } else if (e.target.value === "INACOM") {
      setIsento(true);
    } else setIsento(false);
  };

  const NumericFormatWrapper = React.forwardRef(
    ({ onChange, ...rest }, ref) => {
      return (
        <NumericFormat
          {...rest}
          onChange={(val) => {
            onChange(val);
          }}
        />
      );
    }
  );

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const { register, handleSubmit, control } = useForm({ defaultValues });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const openf = convert(data.dateopenfile);
    const dateser = convert(data.dateservice);
    const datesub = convert(data.datesubmin);
    const datepro = convert(data.datepromin);
    const username = user.data.id;
    const payload = {
      id,
      filenumber,
      data,
      isento,
      openfile: openf,
      dateservice: dateser,
      datesubmin: datesub,
      datepro: datepro,
      username,
    };
   
    setLoad(true);
    await updateCreateLetter(payload).then((res) => {
      if (res.data === "true") {
        setLoad(false);
        setSucess(true);
      }
    });
  };

  return (
    <div>
      <Spinner active={load} />
      <UseModal
        stylehead={{
          background: "rgb(228 203 126 / 33%)",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          borderBottom: "1px solid black",
        }}
        stylebody={{ display: "none" }}
        show={sucess}
        close={() => window.location.reload()}
        title={"Ficheiro alterado com sucesso !!!"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* ### Open File Data #### */}
        <div className="col-md-4">
          <small>
            <label className="breadcrumb-item active" htmlFor="abrDate">
              Data Abertura File
            </label>
          </small>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <i className="fa fa-calendar"></i>
            </span>
            <Controller
              name="dateopenfile"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  locale={pt}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              )}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* ### Cliente #### */}
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <select
                className="form-select"
                {...register("client")}
                placeholder="Clientes"
                type="select"
              >
                <option value={clientes}>{clientes}</option>
                {clients.map((i) => (
                  <option key={i.id} id={i.id} value={i.company}>
                    {i.company}
                  </option>
                ))}
              </select>
              <label htmlFor="clients">Selecionar Clientes</label>
            </div>
          </div>
          {/* ### Importador #### */}
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <select
                className="form-select"
                {...register("importador")}
                placeholder="Importador"
                type="select"
              >
                <option value={importador}>{importador}</option>
                {imports.map((i) => (
                  <option key={i.id} id={i.id} value={i.company}>
                    {i.company}
                  </option>
                ))}
              </select>
              <label htmlFor="clients">Selecionar Importador</label>
            </div>
          </div>
        </div>
        {/* ### Referencia do ficheiro do cliente #### */}
        <div className="row mb-3">
          <div className="col-md-6">
            <Input
              className="form-control"
              id="clientfileref"
              type="text"
              name="clientfileref"
              register={register}
              label="Referencia Ficheiro do Cliente"
              placeholder="Our File Ref"
            />
          </div>
          {/* ### Documento Transporte #### */}
          <div className="col-md-6">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                <select
                  className="form-control"
                  {...register("labeltransport")}
                  style={{
                    border: "none",
                    backgroundColor: "#e9ecef",
                    textAlign: "center",
                  }}
                >
                  <option value={labeltransp}>{labeltransp} &#8595;</option>
                  <option value="BL">BL</option>
                  <option value="Carta Porte">Carta Porte</option>
                </select>
              </InputGroup.Text>

              <Form.Control
                id="basic-url"
                aria-describedby="basic-addon3"
                {...register("docTransporte")}
              />
            </InputGroup>
          </div>
        </div>
        {/* ### Data Pedido Servico #### */}
        <div className="col-md-4">
          <small>
            <label
              className="breadcrumb-item active text-wrap "
              htmlFor="abrDate"
            >
              Data Pedido Servico
            </label>
          </small>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <i className="fa fa-calendar"></i>
            </span>
            <Controller
              name="dateservice"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  locale={pt}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              )}
            />
          </div>
        </div>
        {/* ### Tipo Servico #### */}
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <select
              className="form-select"
              {...register("service")}
              placeholder="service"
              type="select"
              onChange={handleIsento}
            >
              <option value="">Servicos</option>
              <option value="INGA">INGA</option>
              <option value="INACOM">INACOM</option>
              <option value="IRDP">IRDP</option>
              <option value="INIQ">INIQ</option>
              <option value="MINSA">MINSA</option>
              <option value="MININT">MININT</option>
              <option value="MIREMPET">MIREMPET</option>
            </select>
            <label htmlFor="Tipo Servicos">Tipo Servicos</label>
          </div>
        </div>
        {/* ### Referencia Fatura Import #### */}
        <div className="col-md-12">
          <Input
            className="form-control"
            id="refactimpor"
            type="text"
            name="refactimpor"
            register={register}
            label="Referencia Fatura Importacao"
            placeholder="Referencia Fatura Importacao"
          />
        </div>
        {/* ### END #### */}
        <div className="row mb-3">
          {/* ### Valor Fatura Importacao #### */}
          <div className="col-md-3">
            <small>
              <label
                className="breadcrumb-item active text-wrap "
                htmlFor="factImp"
              >
                Valor Fatura Importacao
              </label>
            </small>

            <div className="input-group mb-3">
              <Controller
                name="valorfactimpor"
                control={control}
                render={({ field }) => (
                  <NumericFormatWrapper
                    className="form-control text-end"
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="form-control"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onChange={(val) => field.onChange(val)}
                    valueIsNumericString={true}
                    {...field}
                  />
                )}
              />

              <span className="input-group-text">
                <select
                  {...register("moeda")}
                  style={{
                    border: "none",
                    backgroundColor: "#e9ecef",
                  }}
                >
                  <option value="Kz">Kz</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                  <option value="LIB">LIB</option>
                </select>
              </span>
            </div>
          </div>
          {/* ### Valor Estimado de emolumentos a ser pago #### */}
          <div className="col-md-3">
            <small>
              <label
                className="breadcrumb-item active text-wrap "
                htmlFor="emolu"
              >
                Valor Estimado Emolumentos
              </label>
            </small>
            <div className="input-group mb-3">
              <Controller
                name="valoremolumentos"
                control={control}
                render={({ field }) => (
                  <NumericFormatWrapper
                    className="form-control text-end"
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="form-control"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onChange={(val) => field.onChange(val)}
                    valueIsNumericString={true}
                    {...field}
                  />
                )}
              />

              <span className="input-group-text">Kz</span>
            </div>
          </div>
          {/* ### Data Submisso nos Ministerios #### */}
          <div className="col-md-3">
            <small>
              <label
                className="breadcrumb-item active text-wrap "
                htmlFor="dataSubMi"
              >
                Data Submissao Ao Ministerio
              </label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="datesubmin"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    isClearable
                  />
                )}
              />
            </div>
          </div>
          {/* ### Data Pronunciamento #### */}
          <div className="col-md-3">
            <small>
              <label
                className="breadcrumb-item active text-wrap "
                htmlFor="datePagamento"
              >
                Data Pronunciamento
              </label>
            </small>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
              <Controller
                name="datepromin"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    locale={pt}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    // onChangeRaw={handleSelect}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 d-flex justify-content-center mb-4">
          <button type="submit" className="btn btn-outline-success col-md-2">
            Modificar
          </button>
          <div className="m-2"></div>
          <button
            type="button"
            className="btn btn-outline-danger col-md-2"
            onClick={() => {
              window.location.reload();
            }}
          >
            Fechar
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditCreateLetter;
