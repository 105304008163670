import React, { useState } from "react";
import UserForm from "./forms/userForm";
import auth from "../services/auth";
import { Alert } from "react-bootstrap";

function EditUser(data) {
  const [sucess, setSucess] = useState(false);

  const initialValues = {
    id: data.id || "",
    nome: data.nome || "",
    apelido: data.apelido || "",
    email: data.email || "",
    role: data.role || "",
  };

  const disableFields = {
    nome: false,
    apelido: false,
    email: true,
    role: false,
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    auth.editUser(data);
    setSucess(true);
  };

  return (
    <>
      <Alert
        show={sucess}
        variant="success"
        dismissible
        onClose={() => (window.location.href = "/user")}
      >
        Editado com sucesso!!!
      </Alert>
      <UserForm
        onSubmit={onSubmit}
        lableButton="Editar Utilizador"
        defaultValues={initialValues}
        disabled={disableFields}
        buttonClass="btn btn-danger"
        selabel={initialValues.role}
      />
    </>
  );
}

export default EditUser;
