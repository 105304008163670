import React, { useState, useEffect } from "react";
import LicensasForm from "./forms/licensasForm";
import { useLocation } from "react-router-dom";
import UseModal from "./common/useModal";
import Spinner from "./common/spinner";
import auth from "../services/auth";
import io from "socket.io-client";
import OpenFile from "./common/openFile";
import {
  operationOneLetter,
  updateCicleTwoLetter,
  deleteOpenFile,
  getOpenFiles,
  recordOpenfile,
} from "../services/source";

function DepartamentoOperacoesOneLetter() {
  const [load, setLoad] = useState(false);
  const [showmodal, setShowModal] = useState(false);
  const [sucess, setSucess] = useState(false);

  const socket = io.connect(process.env.REACT_APP_SOCKET);
  let location = useLocation();
  const { file } = location.state || {};
  const user = auth.getCurrentUser();
  const nome = user.data.nome;
  const fileopen = file.filenumber;
  const obj = { fileopen, nome };

  useEffect(() => {
    getOpenFiles(obj).then((res) => {
      if (res.data === "true") return recordOpenfile(obj);
      else {
        setShowModal(true);
      }
    });
  }, [fileopen, nome]);

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const onSubmit = (data) => {
    const datenvlic = convert(data.datenvlic);
    const datereclic = convert(data.datereclic);
    const caseid = file.idfile;
    const username = user.data.id;
    const payload = {
      data,
      datenvlic,
      datereclic,
      caseid,
      username,
    };
    setLoad(true);
    operationOneLetter(payload).then((res) => {
      if (res.data === "true") {
        updateCicleTwoLetter(payload);
        deleteOpenFile(obj);
        setLoad(false);
        setSucess(true);
        socket.emit("newtask", {
          message: "Letter Atribuido " + fileopen,
          room: "Financas",
        });
      }
    });
  };

  return (
    <div className="container-fluid component">
      <Spinner active={load} />
      <UseModal
        stylehead={{
          background: "rgb(228 203 126 / 33%)",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          borderBottom: "1px solid black",
        }}
        stylebody={{ display: "none" }}
        show={sucess}
        close={() => (window.location.href = "/progressTable")}
        title={"Ficheiro criado com sucesso !!!"}
      />
      <UseModal
        show={showmodal}
        close={() => (window.location.href = "/progressTable")}
        title={"File " + fileopen}
        body={<OpenFile file={fileopen} util={nome} />}
      />
      <h3 className="text-dark mb-4">File Operações</h3>
      <small>{file.filenumber}</small>
      <div className="row mb3">
        <div className="card mb-3">
          <LicensasForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default DepartamentoOperacoesOneLetter;
