import React from "react";
import ClientForm from "./forms/clientForm";

function ViewImportador(data) {
  const disableFields = {
    company: true,
    nif: true,
    email: true,
    phone: true,
    address: true,
  };

  const initialValues = {
    company: data.company,
    nif: data.nif,
    email: data.email,
    phone: data.phone,
    addreess: data.address,
  };

  return (
    <>
      <ClientForm
        disabled={disableFields}
        defaultValues={initialValues}
        but={true}
      />
    </>
  );
}

export default ViewImportador;
