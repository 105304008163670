import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getCreateFile, getCreateLetter, getDespesasFile, getDespesasLetter } from "../../services/source";

import ReadOnly from "./readOnly";

function ReadOnlyTable() {
  const [recfile, setRecFile] = useState({});
  const [depfile, setDepFile] = useState();


  let location = useLocation();
  const { file } = location.state || {};
  let number = file.filenumber || {};

  const cads8 = number.startsWith("CADS8");
  const cads9 = number.startsWith("CADS9");

  useEffect(() => {
    async function getFilesCreate() {
      if (cads8) {
        const recFileResponse = await getCreateFile(number);
        const depFileResponse = await getDespesasFile(number);
        setRecFile(recFileResponse.data[0]);
        if (Array.isArray(depFileResponse.data)) {
          setDepFile(depFileResponse.data);
        } else {
          setDepFile([]);
        }
      } else if (cads9) {
        const recFileResponse = await getCreateLetter(number);
        const depFileResponse = await getDespesasLetter(number);
        setRecFile(recFileResponse.data[0]);
        if (Array.isArray(depFileResponse.data)) {
          setDepFile(depFileResponse.data);
        } else {
          setDepFile([]);
        }
      }
    }
    getFilesCreate();
  }, [number]);


  return (
    <div className="container-fluid component">
      <h3 className="text-dark mb-4">File</h3>
      <div className="card mb3">
        <div className="card-header">
          <div className="col-md-12">
            <div className="row mb-2">
              <div className="col">
                <Link to={cads8 ? "/licenseTable" : "/letterTable"}>
                  <i
                    className="fa fa-arrow-circle-left fa-lg"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ReadOnly {...recfile} depfile={depfile} step={file} protocolo={true} />
      </div>
    </div>
  );
}

export default ReadOnlyTable;
