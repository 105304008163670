import React, { useState } from "react";
import Invoice from "./invoiceletter";
import Debit from "./debitletter";
import DebitInvoice from "./debitInvoiceletter";

function InvoiceDebitFile() {
  const [invoice, setInvoice] = useState();
  const [debit, setDebit] = useState();
  const [both, setBoth] = useState();

  const handleChange = (data) => {
    if (data === "invoice") {
      setInvoice(true);
    } else {
      setInvoice(false);
    }
    if (data === "debit") {
      setDebit(true);
    } else {
      setDebit(false);
    }
    if (data === "both") {
      setBoth(true);
    } else {
      setBoth(false);
    }
  };

  return (
    <div>
    <div className="container-fluid component">
        <div
          id="layoutSidenav_content"
          style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <div className="container-fluid px4">
              <div className="card mt-4">
                <div className="card-header">
                <div className="col-md-12">
                    <h1>
                      {" "}
                      <i className="far fa-file-alt" aria-hidden="true">
                        {" "}
                      </i>{" "}
                      Relatório Exo Letter
                    </h1>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-md-4">
                    <select
                      type="select"
                      className="form-select"
                      onChange={(e) => handleChange(e.target.value)}
                    >
                      <option value="">Tipo Relatorio</option>
                      <option value="invoice">Relatorio Faturas</option>
                      <option value="debit">Relatorio Debito</option>
                      <option value="both">Relatorio Faturas & Debito</option>
                    </select>
                  </div>
                  <hr />
                  {invoice ? <Invoice /> : null}
                  {debit ? <Debit /> : null}
                  {both ? <DebitInvoice /> : null}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDebitFile;
