import React from "react";
import { delClient } from "../services/source";

function DelClient(data) {
  const onSubmit = () => {
    delClient(data).then((res) =>
      res.data ? (window.location.href = "/clients") : null
    );
  };

  return (
    <div className="text-center">
      <p>
        Tem a certeza que quer apagar permanentemente o cliente{" "}
        <strong>{data.company}</strong>
      </p>
      <button className="btn btn-danger" onClick={onSubmit}>
        Apagar
      </button>
    </div>
  );
}

export default DelClient;
