import React from "react";

function InputIcon({
  iconClassName,
  placeholder,
  name,
  value,
  defaultValue,
  register,
  autoFocus,
  readOnly,
  label,
  ...rest
}) {
  return (
    <div>
      <small>
        <label className="breadcrumb-item active">
          {label}
        </label>
      </small>
      <div className="input-group mb-3">
        <span className="input-group-text">
          <i className={iconClassName}></i>
        </span>
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          name={name}
          value={value}
          defaultValue={defaultValue}
          readOnly={readOnly}
          autoFocus={autoFocus}
          {...register(name)}
          {...rest}
        />
      </div>
    </div>
  );
}

export default InputIcon;
