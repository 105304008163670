import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import UserForm from "./forms/userForm"; // Make sure to use the correct import path
import { checkMail } from "../services/source";
import auth from "../services/auth";
import UserTable from "./table/userTable";

function User() {
  const [danger, setDanger] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const onSubmit = async (data, e) => {
    e.preventDefault();
    await checkMail(data).then((res) => {
      if (res.data === true) {
        setDanger(true);
      } else if (res.data === false) {
        setDanger(false);
        // Insert user into the database
        auth.register(data);
        e.target.reset();
        setSuccess(true);
      }
    }
    );
  };

  const disableFields = {
    nome: false,
    apelido:false,
    email:false,
    role:false,
  };


  return (
    <div className="container-fluid component">
      <h3 className="text-dark mb-4">Utilizadores</h3>
      <Alert
            show={danger}
            variant="danger"
            dismissible
            onClose={() => setDanger(false)}
          >
            Este email já existe!!!!
          </Alert>

          <Alert
            show={success}
            variant="success"
            dismissible
            onClose={() => (window.location.href = "/user")}
          >
            Registo efetuado com sucesso!!!
          </Alert>
      <div className="row mb-3">
        <div className="card mb-3">
          <UserForm
            onSubmit={onSubmit}
            lableButton="Criar Utilizador"
            disabled={disableFields}
            buttonClass="btn btn-primary"
            selabel="Role"
          />
             {/* Tabela de Utilizadores */}
             <div className="card mb-4 mt-4">
              <div className="card-header">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-table me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                </svg>
                Tabela de Utilizadores
              </div>
              <div className="card-body">
                <UserTable/>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default User;
