import React from "react";
import Modal from "react-bootstrap/Modal";

function UseModal(props) {
  return (
    <div>
      <Modal
        size={props.size}
        show={props.show}
        onHide={props.close}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton style={props.stylehead}>
          <Modal.Title  id="example-modal-sizes-title-lg">{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={props.stylebody}>{props.body}</Modal.Body>
      </Modal>
    </div>
  );
}

export default UseModal;
