import React from "react";
import ClientForm from "./forms/clientForm";
import { editClient } from "../services/source";

function EditClient(data) {
  const disableFields = {
    company: false,
    nif: false,
    email: false,
    phone: false,
    address: false,
  };

  const initialValues = {
    id: data.id || "",
    company: data.company || "",
    nif: data.nif || "",
    email: data.email || "",
    phone: data.phone || "",
    address: data.address || "",
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
   editClient(data).then((res)=>res.data ? window.location.href='/clients' : null);
  };

  return (
    <>
      <ClientForm
        onSubmit={onSubmit}
        disabled={disableFields}
        defaultValues={initialValues}
        but={false}
        buttonClass="btn btn-danger"
        lableButton="Editar Cliente"
      />
    </>
  );
}

export default EditClient;
