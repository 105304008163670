import React, { useState, useEffect } from "react";
import "../styles/inbox.css";
import UseModal from "./common/useModal";
import ComposeEmail from "./composeEmail";
import { getSendEmails, disableEmail, enableEmail } from "../services/source";
import ShowSolDoc from "./showSolDoc";
import DelEmail from "./delEmail";

function Inbox() {
  const [mail, setMail] = useState(false);
  const [showMail, setShowMail] = useState(false);
  const [readEmail, setReadEmail] = useState([]);
  const [showData, setShowData] = useState({});
  const [deldata, setDelData] = useState({});
  const [del, setDel] = useState(false);
  const [disable, setDisable] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEmails, setFilteredEmails] = useState([]);

  const handleMail = () => {
    setMail(true);
  };

  const handleShowmail = (email) => {
    setShowMail(true);
    setShowData(email);
  };

  useEffect(() => {
    async function getEmail() {
      const result = await getSendEmails();
      setReadEmail(result.data);
    }
    getEmail();
  }, []);

  const handleDelete = (del) => {
    setDel(true);
    setDelData(del);
  };

  const handleEnable = (en) => {
    enableEmail(en).then((res) => (res ? window.location.reload(true) : null));
  };

  const handleDisable = (id) => {
    setDisable((prevState) => ({ ...prevState, [id.fileaddid]: true }));
    disableEmail(id).then((res) => (res ? window.location.reload(true) : null));
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const filtered = readEmail.filter((item) =>
      item.subject.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEmails(filtered);
  }, [searchTerm, readEmail]);

  return (
    <div>
      <div id="layoutSidenav">
        <div
          id="layoutSidenav_content"
          style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <div className="container-fluid component px4">
              <div className="card mt-4">
                <div className="card-header">
                  <div className="col-md-12">
                    <h1>
                      <i className="fa fa-envelope-open-o" aria-hidden="true">
                        {" "}
                      </i>
                      &nbsp; Solicitaçāo de Documentos
                    </h1>
                  </div>
                </div>

                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-2">
                          <UseModal
                            size="lg"
                            show={mail}
                            close={() => setMail(false)}
                            body={<ComposeEmail />}
                            title={
                              <i className="fa fa-envelope-o">
                                &nbsp;&nbsp;&nbsp; Novo Email
                              </i>
                            }
                          />
                          <UseModal
                            show={del}
                            close={() => setDel(false)}
                            body={<DelEmail {...deldata} />}
                            title="Apagar"
                          />
                        </div>

                        <div className="col-sm-9">
                          <div className="panel rounded shadow panel-teal">
                            <div className="panel-heading">
                              <div className="pull-left mt-3">
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleMail()}
                                >
                                  <i className="fa fa-pencil-square-o">
                                    {" "}
                                    Novo Email
                                  </i>
                                </button>
                              </div>
                              <div className="pull-right">
                                <form
                                  onSubmit={handleSubmit}
                                  action="#"
                                  className="form-horizontal mr-5 mt-3"
                                >
                                  <div className="form-group no-margin no-padding has-feedback">
                                    <div className="input-group mb-3">
                                      <input
                                        type="text"
                                        className="form-control no-border"
                                        placeholder="Procurar email"
                                        name="procurar"
                                        value={searchTerm}
                                        onChange={handleInputChange}
                                      />
                                      <button
                                        type="submit"
                                        className="btn btn-light fa fa-search form-control-feedback"
                                      ></button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div className="clearfix"></div>
                            </div>

                            <div className="panel-body no-padding">
                              <div className="table-responsive">
                                <table className="table table-hover table-email">
                                  <tbody>
                                    {filteredEmails.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          <div className="media">
                                            {/* disable button */}
                                            {item.emaildisable === "0" ? (
                                              <button
                                                key={item.id}
                                                className="btn btn-outline-danger  pull-left"
                                                onClick={() =>
                                                  handleDisable(item)
                                                }
                                                disabled={
                                                  disable[item.fileaddid]
                                                }
                                              >
                                                <i className="fa fa-ban"></i>
                                              </button>
                                            ) : null}
                                            {/* end disable */}
                                            <div className="media-body ">
                                              <h4 className="text-primary ">
                                                Numero: {item.filenumber}
                                              </h4>
                                              <p
                                                className="email-summary"
                                                style={{
                                                  color: "black",
                                                  textDecoration: "none",
                                                }}
                                              >
                                                {item.subject}
                                                <button
                                                  className="badge bg-success ms-3"
                                                  onClick={() =>
                                                    handleShowmail(item)
                                                  }
                                                >
                                                  Abrir
                                                </button>
                                              </p>

                                              <UseModal
                                                show={showMail}
                                                close={() => setShowMail(false)}
                                                body={
                                                  <ShowSolDoc {...showData} />
                                                }
                                                title={
                                                  <i className="fa fa-envelope-o">
                                                    {" "}
                                                    &nbsp;Email
                                                  </i>
                                                }
                                              />

                                              <span className="media-meta">
                                                {item.datetime}
                                                <button
                                                  className="btn btn-outline-danger mx-3"
                                                  onClick={() =>
                                                    handleDelete(item)
                                                  }
                                                >
                                                  <i className="fa fa-trash"></i>
                                                </button>
                                                {item.emaildisable === "1" ? (
                                                  <button
                                                    className="btn btn-outline-success"
                                                    onClick={() =>
                                                      handleEnable(item)
                                                    }
                                                  >
                                                    <i className="fa fa-check-circle-o fa-lg"></i>
                                                  </button>
                                                ) : null}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              {/* <!-- /.table-responsive --> */}
                            </div>
                            {/* <!-- /.panel-body --> */}
                          </div>
                          {/* <!-- /.panel --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Inbox;
