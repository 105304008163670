import React from "react";

const InputSelect = ({
  label,
  onChange,
  disabled,
  name,
  register,
  options=[],
  errors,
}) => {
  return (
    <div className="form-floating mb-3">
      <select
        className="form-select"
        {...register(name)}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="">{label}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label htmlFor={name}>{label}</label>
      <p style={{ color: "red" }}>{errors?.[name]?.message}</p>
    </div>
  );
};

export default InputSelect;
