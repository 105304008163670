import React, { useEffect, useState } from "react";
import { getEmailAddress } from "../services/source";

function ShowSolDoc(data) {
  const [mailAdd, setMailAdd] = useState([]);

  useEffect(() => {
    async function getMailAddr() {
      const result = await getEmailAddress();
      setMailAdd(result.data);
    }
    getMailAddr();
  }, []);

  return (
    <div>
      {mailAdd
        .filter((i) => {
          return i.filenumber === data.filenumber;
        })
        .map((f) => (
          <div key={f.id}>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Para :
            </span>
            <input
              type="email"
              className="form-control"
              aria-describedby="basic-addon1"
              value={f.emailaddress}
              disabled
            />
          </div>
          </div>
        ))}

      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          File :
        </span>
        <input
          value={data.filenumber}
          disabled
          type="text"
          className="form-control"
          aria-describedby="basic-addon1"
        />
      </div>

      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          Assunto :
        </span>
        <input
          value={data.subject}
          disabled
          type="text"
          className="form-control"
          aria-describedby="basic-addon1"
        />
      </div>

      <div className="input-group mb-3">
        <textarea
          value={data.message}
          disabled
          cols="30"
          rows="10"
          className="form-control"
          placeholder="Messagem
            :"
        ></textarea>
      </div>
    </div>
  );
}

export default ShowSolDoc;
