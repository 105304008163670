import React, { useState } from "react";
import Input from "../common/input";
import { useForm } from "react-hook-form";
import InputIcon from "../common/inputicon";
import InputData from "../common/inputdata";
import InputSelect from "../common/inputselect";
import CustomInputGroup from "../common/custominputgroup";
import CurrencyInput from "../common/currencyinput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CreateForm = ({
  onSubmit,
  defaultValues,
  clientsList,
  importadorList,
  filterResult,
  setDuplicate,
  disabled,
  selClient,
  selImport,
  selServ,
  docTransp,
  numOrdem,
}) => {

  const [ver, setVer] = useState(false);

  const schema = yup.object().shape({
    case: yup.string().required(),
    dateopenfile: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
    client: yup.string().required("Campo Obrigatório"),
    clientfileref: yup.string().required("Campo Obrigatório"),
    dateservice: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
    importador: yup.string().required("Campo Obrigatório"),
    service: yup.string().required("Campo Obrigatório"),
    refactimpor: yup.string().required("Campo Obrigatório"),
    valorfactimpor: yup.string().required("Campo Obrigatório"),
    valoremolumentos: yup.string().required("Campo Obrigatório"),
    labeltransport: yup.string().notRequired(),
    docTransporte: yup.string().notRequired(),
    moeda: yup.string().notRequired(),
  });

  const {
    register,
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues,});

  const documentOptions = [
    { label: "BL", value: "BL" },
    { label: "Carta Porte", value: "Carta Porte" },
  ];

  const services = [
    { label: "INGA", value: "INGA" },
    { label: "INACOM", value: "INACOM" },
    { label: "IRDP", value: "IRDP" },
    { label: "INIQ", value: "INIQ" },
    { label: "MINSA", value: "MINSA" },
    { label: "MININT", value: "MININT" },
    { label: "MIREMPET", value: "MIREMPET" },
  ];

  const currencyOptions = {
    name: "moeda",
    values: ["Kz", "EUR", "USD", "LIB"],
  };

  const handleDuplicate = () => {
    setDuplicate();
    const data = getValues();
    if (data.clientfileref) {
      data.clientfileref = data.clientfileref
        .replace(/[\s.,#!$%^&*;:{}=\-_'`~()]/g, "")
        .toUpperCase();
    }
    if (data.refactimpor) {
      data.refactimpor = data.refactimpor
        .replace(/[\s.,#!$%^&*;:{}=\-_'`~()]/g, "")
        .toUpperCase();
    }
    let clientref = data.clientfileref.trim();
    setValue("clientfileref", clientref);
    let refimpor = data.refactimpor.trim();
    setValue("refactimpor", refimpor);
    let service = data.service;
    if (clientref && refimpor && service) {
      const exist = filterResult.some(
        (item) =>
          item.refclient === clientref &&
          item.refimpor === refimpor &&
          item.service === service
      );
      if (exist) {
        setDuplicate(true);
        alert("Este file ja existe no sistema!!!");
      } else {
        setDuplicate(false);
        setVer(true);
      }
    } else {
      alert(
        "A referencia Cliente, Importação e Servicos não podem estar vazios "
      );
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body shadow p-3">
          {/* Case Number */}
          <div className="col-md-4">
            <InputIcon
              label="Numero Ordem"
              iconClassName="fa fa-file"
              register={register}
              name="case"
              autoFocus
              value={numOrdem}
              readOnly
              // disabled={disabled.case}
            />
          </div>
          {/* Data Abertura File */}
          <div className="col-md-4">
            <InputData
              name="dateopenfile"
              label="Data Abertura File"
              control={control}
              errors={errors}
              disabled={disabled.dateopenfile}
              isclear={true}
            />
          </div>
          {/* ############################ */}
          <div className="row mb-3">
            {/* Cliente */}
            <div className="col-md-6">
              <InputSelect
                value="Cliente"
                label={selClient}
                name="client"
                register={register}
                options={clientsList}
                errors={errors}
                disabled={disabled.client}
                defaultValues={defaultValues.client}
              />
            </div>
            {/* Importador */}
            <div className="col-md-6">
              <InputSelect
                value="Importador"
                name="importador"
                register={register}
                options={importadorList}
                errors={errors}
                disabled={disabled.importador}
                label={selImport}
              />
            </div>
          </div>
          {/* ############################ */}
          <div className="row mb-3">
            {/* ### Referencia do ficheiro do cliente #### */}
            <div className="col-md-6">
              <Input
                register={register}
                name="clientfileref"
                type="text"
                errors={errors}
                label="Referencia Ficheiro do Cliente"
                placeholder="Referencia Ficheiro do Cliente"
                disabled={disabled.clientfileref || ver}
              />
            </div>
            {/* ### Documento Transporte #### */}
            <div className="col-md-6">
              <CustomInputGroup
                label={docTransp}
                name="docTransporte"
                register={register}
                options={documentOptions}
                disabled={disabled.docTransporte}
              />
            </div>
          </div>
          {/* ############################ */}
          {/* ### Data Pedido Servico #### */}
          <div className="col-md-4">
            <InputData
              name="dateservice"
              label="Data Pedido Serviço"
              control={control}
              errors={errors}
              disabled={disabled.dateservice}
              isclear={true}
            />
          </div>
          {/* ### Referencia Fatura Import #### */}
          <div className="col-md-12">
            <Input
              register={register}
              name="refactimpor"
              label="Referência Factura Importaçāo"
              placeholder="Referência Factura Importaçāo"
              errors={errors}
              disabled={disabled.refactimpor || ver}
            />
          </div>
          {/* ### Tipo Servico #### */}
          <div className="col-md-4">
            <InputSelect
              value="Serviços"
              label={selServ}
              register={register}
              name="service"
              options={services}
              errors={errors}
              disabled={disabled.service || ver}
            />
          </div>
          {/* ############################ */}
          <div className="row mb-3">
            {/* ### Valor Fatura Importacao #### */}
            <div className="col-md-3">
              <CurrencyInput
                label="Valor Fatura Importacao"
                name="valorfactimpor"
                control={control}
                register={register}
                options={currencyOptions}
                errors={errors}
                disabled={disabled.valorfactimpor}
              />
            </div>
            {/* ### Valor Estimado de emolumentos a ser pago #### */}
            <div className="col-md-3">
              <CurrencyInput
                label="Valor Estimado Emolumentos"
                name="valoremolumentos"
                control={control}
                register={register}
                suffix="Kz"
                errors={errors}
                disabled={disabled.valoremolumentos}
              />
            </div>
            {/* Button */}
            <div className="row mb-3">
              <div className="col-md-12 d-flex justify-content-center">
                {!ver ? (
                  <button
                    type="button"
                    className="btn btn-outline-warning col-md-2"
                    onClick={handleDuplicate}
                  >
                    Verificar
                  </button>
                ) : null}

                {ver ? (
                  <button
                    type="submit"
                    className="btn btn-outline-success col-md-2"
                  >
                    Submeter
                  </button>
                ) : null}
                <div className="m-2"></div>
                <button
                  type="button"
                  className="btn btn-outline-danger col-md-2"
                  onClick={() => {
                    reset(setVer(false), setDuplicate(false));
                  }}
                >
                  Apagar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateForm;
