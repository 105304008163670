import React, { useEffect, useState } from "react";
import "../styles/arquiveTable.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col, Container } from "react-bootstrap";
import UseModal from "./common/useModal";


import DeletePDF from "./deletePDF";
import {
  getFilesLetter,
  getAnexoRupeName,
  getAnexoProtName,
  getAnexoNotaDebName,
  getAnexoLicName,
  getAnexoFacRecName,
  getAnexoRecName,
} from "../services/source";

function ArquiveTable() {
  const [filesletter, setFilesLetter] = useState([]);
  const [selectedFileNumber, setSelectedFileNumber] = useState(null);
  const [filterFiles, setFilterFiles] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [deletepdf, setDeletePdf] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [showFiles, setShowFile] = useState(false);

  useEffect(() => {
    async function getFileNumber() {
      const result = await getFilesLetter();
      setFilesLetter(result.data);
    }
    getFileNumber();
  }, []);

  const selectOptions = filesletter.map((obj) => ({
    value: obj.filenumber,
    label: obj.filenumber,
    id: obj.idfile,
  }));

  useEffect(() => {
    if (selectedFileNumber) {
      const { value } = selectedFileNumber;
      getAnexoProtName({ filenumber: value }).then((res) => {
        if (res) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            const fileproname = res.data[0];
            const protocolo = {
              ...fileproname,
              filename: fileproname.filenamesub,
              arquive: "arquiveprotocolosub",
            };
            setFilterFiles((prevFilterFiles) => [
              ...prevFilterFiles,
              protocolo,
            ]);
          }
        }
      });
      getAnexoRupeName({ filenumber: value }).then((res) => {
        if (res) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            const filenamerupe = res.data[0];
            const rupe = {
              ...filenamerupe,
              filename: filenamerupe.filenamerupe,
              arquive: "arquiverupe",
            };
            setFilterFiles((prevFilterFiles) => [...prevFilterFiles, rupe]);
          }
        }
      });
      getAnexoNotaDebName({ filenumber: value }).then((res) => {
        if (res) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            const filenamenotadeb = res.data[0];
            const nota = {
              ...filenamenotadeb,
              filename: filenamenotadeb.filenamenotadeb,
              arquive: "arquivenotadeb",
            };
            setFilterFiles((prevFilterFiles) => [...prevFilterFiles, nota]);
          }
        }
      });
      getAnexoLicName({ filenumber: value }).then((res) => {
        if (res) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            const filenamelic = res.data[0];
            const lic = {
              ...filenamelic,
              filename: filenamelic.filenamelic,
              arquive: "arquivelic",
            };
            setFilterFiles((prevFilterFiles) => [...prevFilterFiles, lic]);
          }
        }
      });
      getAnexoFacRecName({ filenumber: value }).then((res) => {
        if (res) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            const filenamefacrec = res.data[0];
            const fac = {
              ...filenamefacrec,
              filename: filenamefacrec.filenamefacrec,
              arquive: "arquivefacrec",
            };
            setFilterFiles((prevFilterFiles) => [...prevFilterFiles, fac]);
          }
        }
      });
      getAnexoRecName({ filenumber: value }).then((res) => {
        if (res) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            const filenamerec = res.data[0];
            const rec = {
              ...filenamerec,
              filename: filenamerec.filenamerec,
              arquive: "arquiverec",
            };
            setFilterFiles((prevFilterFiles) => [...prevFilterFiles, rec]);
          }
        }
      });
      setShowFile(true);
    }
  }, [selectedFileNumber]);

  const deleteFileHandler = (data) => {
    setDeletePdf(true);
    setDeleteData(data);
  };

  const handleShowPdf = (data) => {
    const newData = data.toUpperCase().replace(/\s/g, "-");
    const api = process.env.REACT_APP_API_URL + "/uploads/" + newData;
    fetch(api, {
      mode: "no-cors",
      method: "GET",
      headers: { "Content-Type": "application/pdf" },
    })
      .then((res) => res.blob())
      .then((blog) => {
        const pdf = URL.createObjectURL(blog);
        setPdfFile(pdf);
      });
  };


  const handleClean = () => {
    setFilterFiles([]);
    setShowFile(false);
  };

  return (
    <div>
      <div className="container-fluid component">
        <div
          id="layoutSidenav_content"
          style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <div className="container-fluid px4">
              <div className="mt-4"></div>

              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active"></li>
              </ol>
            </div>
            {!pdfFile && (
              <div>
                <div className="card shadow mainCard">
                  <div className="card-body">
                    <div>
                      <div className="row d-lg-inline-flex">
                        <div className="col">
                          <div className="card shadow d-flex justify-content-center align-items-center justify-content-sm-center align-items-sm-center mt-5 outsideCard">
                            <i
                              className="fas fa-archive"
                              style={{ color: " #c4c2c2", fontSize: "700%" }}
                            ></i>
                          </div>
                        </div>
                        <div id="controlsCol" className="col text-center">
                          <h3 id="label">Arquivo</h3>

                          {!showFiles ? (
                            <div className="input-group  mx-5 search">
                              <Select
                                placeholder="File Number"
                                className="search"
                                options={selectOptions}
                                isClearable
                                onChange={(selectedOption) =>
                                  setSelectedFileNumber(selectedOption)
                                }
                              />
                            </div>
                          ) : null}
                          {showFiles ? (
                            <div className="input-group  mx-5 search">
                              <div className=" d-grid gap-2">
                                <Button
                                  className="search"
                                  onClick={handleClean}
                                  variant="danger"
                                >
                                  Limpar
                                </Button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <ul className="file-list">
                    {filterFiles.map((fileName, index) => (
                      <div key={index}>
                        <Container>
                          <Row className="justify-content-md-center">
                            <Col xs lg="1" style={{ color: "#ee5435" }}>
                              <FontAwesomeIcon
                                icon={faFileAlt}
                                style={{ fontSize: "150%" }}
                                onClick={() => handleShowPdf(fileName.filename)}
                              />
                            </Col>
                            <Col xs lg="2">
                              <span className="mr-3">
                                {" "}
                                {fileName.filename}{" "}
                              </span>
                            </Col>
                            <Col xs lg="2" style={{ color: "#ee5435" }}>
                              <FontAwesomeIcon
                                style={{
                                  cursor: "pointer",
                                  fontSize: "150%",
                                }}
                                icon={faTrash}
                                onClick={() => deleteFileHandler(fileName)}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <div className="container-fluid">
              {pdfFile && (
                <Button
                  className="mb-3"
                  variant="outline-danger"
                  onClick={() => setPdfFile(null)}
                >
                  <i
                    className="fa fa-arrow-circle-left fa-lg"
                    aria-hidden="true"
                  >
                    {" "}
                    Voltar
                  </i>
                </Button>
              )}
              {pdfFile && (
                <object
                  data={pdfFile}
                  type="application/pdf"
                  width="100%"
                  height="1000"
                >
                  {" "}
                  <p>Unable to display PDF file</p>
                </object>
              )}
            </div>
          </main>
          <UseModal
            show={deletepdf}
            close={() => setDeletePdf(false)}
            body={<DeletePDF {...deleteData} />}
            title="Apagar"
          />
        </div>
      </div>
    </div>
  );
}

export default ArquiveTable;
