import React from "react";
import { InputGroup, Form } from "react-bootstrap"; 

const CustomInputGroup = ({ label, name, register, options, placeholder }) => {
  return (
    <InputGroup className="mb-3">
      <InputGroup.Text id={`${name}-addon`}>
        <select
          className="form-control"
          {...register(`${name}Label`)}
          style={{
            border: "none",
            backgroundColor: "#e9ecef",
            textAlign: "center",
          }}
        >
          <option value="">{label} &#8595;</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </InputGroup.Text>

      <Form.Control
        id={name}
        aria-describedby={`${name}-addon`}
        {...register(name)}
        placeholder={placeholder}
      />
    </InputGroup>
  );
};

export default CustomInputGroup;
