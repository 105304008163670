import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import ContactoForm from "./forms/contactosForm";
import { getClients } from "../services/source";
import { createContact } from "../services/source";
import ContactTable from "../components/table/contactTable";

function Contactos() {
  useEffect(() => {
    async function getCli() {
      const res = await getClients();
      setClients(res.data);
      setLoad(false);
    }
    getCli();
  }, []);

  const disableFiels = {
    firstName: false,
    lastName: false,
    email: false,
    conpany: false,
    pass: false,
    confirpass: false,
    phone1: false,
    phone2: false,
  };

  const [sucess, setSucess] = useState(false);
  const [clients, setClients] = useState([]);
  const [load, setLoad] = useState(true);

  const options = clients.map((client) => ({
    label: client.company, // Use the company name as the label
    value: client.company, // Use the client id as the value
  }));

  const initial = {
    company: "",
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    await createContact(data).then((res) => {
      if (res.data === "true") {
        e.target.reset();
        setSucess(true);
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="container-fluid component">
        <h3 className="text-dark mb-4">Contactos</h3>
        <div className="text-dark mb-4">
          <div className="row mb-3">
            <div className="card mb-3">
              <Alert
                show={sucess}
                variant="success"
                dismissible
                onClose={() => (window.location.href = "/contactos")}
              >
                Registo criado com sucesso!!!
              </Alert>

              {!load ? (
                <ContactoForm
                  onSubmit={onSubmit}
                  disabled={disableFiels}
                  buttonClass="btn btn-primary"
                  options={options}
                  labelButton="Criar Contacto"
                  defaultValues={initial}
                  selabel="Empresa"
                />
              ) : null}
              <div className="card mb-4 mt-4">
                <div className="card-header">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-table me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                  </svg>
                  Tabela de Contactos
                </div>
                <div className="card-body">
                  <ContactTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactos;
