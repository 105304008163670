import React, { useState } from "react";
import { delPDF } from "../services/source";
import Alert from "react-bootstrap/Alert";

function DeletePDF(data) {

  const [danger, setDanger] = useState(false);

  const handleDelete = () => {
    delPDF(data).then((res) => {
      res.data ? setDanger(true) : setDanger(false);
    });
  };

  const handleClose = () => {
    window.location.href = "/arquiveTable";
  };

  return (
    <div>
      <Alert show={danger} variant="danger">
        Ficheiro eliminado com sucesso!!!
      </Alert>
      <p>
        Tem a certeza que quer apagar o ficheiro <strong>{data.filename}</strong>
      </p>
      {!danger ? (
        <button className="btn btn-danger m-2" onClick={handleDelete}>
          Apagar
        </button>
      ) : (
        <button className="btn btn-success m-2" onClick={handleClose}>
          Fechar
        </button>
      )}
    </div>
  );
}

export default DeletePDF;
