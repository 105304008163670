import React from "react";
import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import pdfHeading from "./common/pdfHeading";

function PDFControl(data) {


  const styles = StyleSheet.create({
    body: {
      paddingTop: 30,
      paddingBottom: 50,
    },
    line: { marginBottom: 10 },
    title: { textAlign: "center" },
    tableStyle: { display: "table", width: "auto" },
    tableRowStyle: {
      flexDirection: "row",
      paddingTop: 0,
      paddingHorizontal: 20,
    },
    firstTableColHeaderStyle: {
      width: "10%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    secondTableColHeaderStyle: {
      width: "30%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    tirthTableColHeaderStyle: {
      width: "22%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    fourTableColHeaderStyle: {
      width: "25%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    fiveTableColHeaderStyle: {
      width: "25%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    sixTableColHeaderStyle: {
      width: "15%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    tableColHeaderStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    tableCellHeaderStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 9,
      fontWeight: "bold",
    },
    firstTableColStyle: {
      width: "10%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    secondTableColStyle: {
      width: "30%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    fourtableColStyle: {
      width: "25%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    fivetableColStyle: {
      width: "25%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    sixtableColStyle: {
      width: "15%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    tableCellStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 10,
    },
    tableColStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    tirthtableColStyle: {
      width: "22%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    commentableStyle: {
      marginTop: "4%",
    },
    commentableRowStyle: {
      flexDirection: "row",
      paddingTop: 0,
      paddingHorizontal: 20,
    },
    commnetfirstTableColStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
      padding: "1%",
      backgroundColor: "#bdbdbd",
    },
    commentableCellStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 10,
    },
    commentableCellStyleWrite: {
      textAlign: "",
      margin: 1,
      fontSize: 10,
      paddingTop: 5,
      paddingLeft: 5,
    },
    commnetsecondTableColStyle: {
      width: "80%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    tableColHeaderStyleCelula: {
      width: "10%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    tableColHeaderStyleVisto: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    firstTableColStylePrep: {
      width: "30%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
      // paddingTop: "3%",
      padding: "4%",
    },
    tableColStyleCelula: {
      width: "10%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      padding: "3%",
    },
    tableColStyleData: {
      width: "15%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      paddingTop: "3%",
    },
    tableColStyleVisto: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      paddingTop: "3%",
    },
    tableColStyleContact: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      paddingTop: "3%",
    },
    firstTableColHeaderStyleEntrega: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    tableColHeaderStyleCarrimbo: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    firstTableColStylEntrega: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
      padding: "3%",
    },
    tableColStyleCarr: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    preparTableColHeaderStyle: {
      width: "30%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },
    tableColHeaderStyleData: {
      width: "15%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#bdbdbd",
    },
    tableCellStyleData: {
      textAlign: "center",
      margin: 1,
      fontSize: 10,
      top: "50%",
    },
    tableRowStyleNota: {
      marginTop: "6%",
      marginLeft: "3%",
      marginRight: "3%",
      flexDirection: "row",
      paddingTop: 0,
      paddingHorizontal: 30,
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
    },
    noteText: {
      padding: "1%",
      textAlign: "center",
      fontSize: 9,
      fontStyle: "italic",
      fontWeight: "bold",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  const createTableHeader = () => {
    return (
      <View style={styles.tableRowStyle} fixed>
        <View style={styles.firstTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Nº</Text>
        </View>

        <View style={styles.secondTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>N/FILE</Text>
        </View>

        <View style={styles.tirthTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>N DA FACTURA</Text>
        </View>

        <View style={styles.fourTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>IMPORTADOR</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>DESIGNAÇAO</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>NIF</Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>DATA DE SUBMISSAO</Text>
        </View>

        <View style={styles.fiveTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>VALOR DA FACTURA</Text>
        </View>

        <View style={styles.sixTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>DIAS ESPERA</Text>
        </View>
      </View>
    );
  };

  const createTableHeaderSec = () => {
    return (
      <View style={styles.tableRowStyle} fixed>
        <View style={styles.preparTableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Preparado por (Nome)</Text>
        </View>
        {/* <View style={styles.tableColHeaderStyleCelula}>
              <Text style={styles.tableCellHeaderStyle}>Célula</Text>
            </View> */}

        <View style={styles.tableColHeaderStyleData}>
          <Text style={styles.tableCellHeaderStyle}>Data & Hora</Text>
        </View>

        <View style={styles.tableColHeaderStyleVisto}>
          <Text style={styles.tableCellHeaderStyle}>
            Visto por (Nome & Assinatura)
          </Text>
        </View>
        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Contactos</Text>
        </View>
      </View>
    );
  };

  const createTableHeaderTree = () => {
    return (
      <View style={styles.tableRowStyle} fixed>
        <View style={styles.firstTableColHeaderStyleEntrega}>
          <Text style={styles.tableCellHeaderStyle}>
            Entrega a DSV - Panalpina
          </Text>
        </View>
        <View style={styles.tableColHeaderStyleCarrimbo}>
          <Text style={styles.tableCellHeaderStyle}>
            Carrimbo & Assinatura{" "}
          </Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>Data</Text>
        </View>
      </View>
    );
  };

  let today = new Date();
  return (
    <div>
      <div style={{ paddingLeft: "10%" }}>
        <PDFViewer width={800} height={1000} showToolbar>
          <Document>
            <Page style={styles.body}>
              <View>{pdfHeading()}</View>
              <Text style={styles.line}>
                ____________________________________________________________
              </Text>
              <Text style={styles.title}>
                MAPA DE PROCESSO DO {data.processo} QUE AGUARDAM {data.title}
              </Text>
              <Text style={styles.line}>
                ____________________________________________________________
              </Text>
              <View style={styles.tableStyle}>
                {createTableHeader()}
                {data.result.map((data, index) => {
                  const lines = data.refimpor.split("/");
                  return (
                    <View key={index} style={styles.tableRowStyle}>
                      <View style={styles.firstTableColStyle}>
                        <Text style={styles.tableCellStyle}>{index + 1}</Text>
                      </View>

                      <View style={styles.secondTableColStyle}>
                        <Text style={styles.tableCellStyle}>
                          {data.filenumber}
                        </Text>
                      </View>

                      <View style={styles.tirthtableColStyle}>
                        <Text style={styles.tableCellStyle}>{lines}</Text>
                      </View>

                      <View style={styles.fourtableColStyle}>
                        <Text style={styles.tableCellStyle}>
                          {data.importador}
                        </Text>
                      </View>

                      <View style={styles.tableColStyle}>
                        <Text style={styles.tableCellStyle}>
                          {data.service}
                        </Text>
                      </View>

                      <View style={styles.tableColStyle}>
                        <Text style={styles.tableCellStyle}>{data.nif}</Text>
                      </View>

                      <View style={styles.tableColStyle}>
                        <Text style={styles.tableCellStyle}>
                          {data.datesubmit}
                        </Text>
                      </View>

                      <View style={styles.fivetableColStyle}>
                        <Text style={styles.tableCellStyle}>
                          {data.valorfactimpor + " " + data.moeda}
                        </Text>
                      </View>

                      <View style={styles.sixtableColStyle}>
                        <Text style={styles.tableCellStyle}>
                          {Math.ceil(
                            (new Date(data.opencasedate) - today) /
                              (1000 * 60 * 60 * 24)
                          )}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>

              <View style={styles.commentableStyle}>
                <View style={styles.commentableRowStyle}>
                  <View style={styles.commnetfirstTableColStyle}>
                    <Text style={styles.commentableCellStyle}>
                      {" "}
                      Comentários
                    </Text>
                  </View>
                  <View style={styles.commnetsecondTableColStyle}>
                    <Text style={styles.commentableCellStyleWrite}>
                      {data.commnet.comentarios}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.commentableStyle}>
                {createTableHeaderSec()}
                <View style={styles.tableRowStyle}>
                  <View style={styles.firstTableColStylePrep}>
                    <Text style={styles.tableCellStyle}>
                      {data.commnet.preparado}
                    </Text>
                  </View>

                  <View style={styles.tableColStyleData}>
                    <Text style={styles.tableCellStyle}>
                      {data.commnet.data}
                    </Text>
                  </View>
                  <View style={styles.tableColStyleVisto}>
                    <Text style={styles.tableCellStyle}>
                      {data.commnet.visto}
                    </Text>
                  </View>
                  <View style={styles.tableColStyleContact}>
                    <Text style={styles.tableCellStyle}>
                      {data.commnet.contact}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.tableRowStyleNota}>
                <View>
                  <Text style={styles.noteText}>
                    NOTA: Ao assinar e carimbar este protocolo confirmo que
                    recebi todos os documentos acima descritos e verifiquei a
                    sua Veracidade e admissibilidade para o processo.
                  </Text>
                </View>
              </View>

              <View style={styles.commentableStyle}>
                {createTableHeaderTree()}
                <View style={styles.tableRowStyle}>
                  <View style={styles.firstTableColStylEntrega}>
                    <Text style={styles.tableCellStyle}></Text>
                  </View>
                  <View style={styles.tableColStyleCarr}>
                    <Text style={styles.tableCellStyle}></Text>
                  </View>
                  <View style={styles.tableColStyle}>
                    <Text style={styles.tableCellStyleData}>
                      {" "}
                      __ de ___ de _____
                    </Text>
                  </View>
                </View>
              </View>
              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          </Document>
        </PDFViewer>
      </div>
    </div>
  );
}

export default PDFControl;
