import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getSubmitionCases, getAtributeFile } from "../../services/source";
import "../../styles/nav.css";
import "react-toastify/dist/ReactToastify.css";
import auth from "../../services/auth";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";

import { Offcanvas } from "react-bootstrap";

function TopNav() {
  const [sidebarToggled, setSidebarToggled] = useState(false);
  const [submitionCount, setSubmitionCount] = useState([]);
  // const [progress, setProgress] = useState();
  const [atribute, setAtribute] = useState();
  const [admin, setAdmin] = useState(false);
  const [lic, setLic] = useState(false);
  const [letter, setLetter] = useState(false);
  const [control, setControl] = useState(false);
  const [arquive, setArquive] = useState(false);
  const [track, setTrack] = useState(false);

  const user = auth.getCurrentUser();
  const role = user?.data.role;

  const prod = user?.data.role === "Operacoes" ? true : false;
  const man = user?.data.role === "Manager" ? true : false;
  const adminis = user?.data.role === "Administracao" ? true : false;

  const socket = io.connect(process.env.REACT_APP_SOCKET);
  const room = role;
  socket.emit("join_room", room);

  useEffect(() => {
    socket.on("receivetask", (data) => {
      socket.emit(GetAtrFile(data.message));
    });
  });

  async function GetAtrFile(data) {
    const atrib = await getAtributeFile(role);
    setAtribute(atrib);
    toast.success(data, {
      toastId: data,
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    async function Submition() {
      const result = await getSubmitionCases();
      setSubmitionCount(result);
    }
    Submition();
  }, []);

  useEffect(() => {
    GetAtrFile();
  });

  const toggleSidebar = () => {
    setSidebarToggled(!sidebarToggled);
  };

  const handleCloseAdmin = () => setAdmin(false);
  const handleAdmin = () => setAdmin(true);
  const handleCloseLic = () => setLic(false);
  const handleLic = () => setLic(true);
  const handleLetter = () => setLetter(true);
  const handleCloseLetter = () => setLetter(false);
  const handleControl = () => setControl(true);
  const handleCloseControl = () => setControl(false);
  const handleArquive = () => setArquive(true);
  const handleCloseArquive = () => setArquive(false);
  const handleTrack = () => setTrack(true);
  const handleCloseTrack = () => setTrack(false);

  return (
    <div className="d-flex">
      {/* TopNav */}
      <nav className="navbar navbar-dark navbar-expand bg-dark shadow topbar static-top bg-dark fixed-top">
        <a className="navbar-brand d-flex align-items-center" href="/home">
          <img
            src={process.env.PUBLIC_URL + "assets/img/cads.png"}
            alt="cads"
            className="img-fluid"
            style={{ width: "20%" }}
          />
        </a>

        <div className="container-fluid">
          <button
            id="sidebarToggleTop"
            className="btn btn-link d-md-none rounded-circle me-3"
            type="button"
            onClick={toggleSidebar}
          >
            <i className="fas fa-bars"></i>
          </button>

          <form className="d-none d-sm-inline-block me-auto ms-md-3 my-2 my-md-0 mw-100 navbar-search">
            {/* Search form */}
          </form>
          {/* Submissão */}
          {(prod || man || adminis) && (
            <div className="btn-group p-2">
              <button
                type="button"
                className="btn btn-primary m-2 position-relative"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="far fa-clock fa-lg" aria-hidden="true"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {submitionCount}
                </span>
              </button>
              <ul className="dropdown-menu dropdown-menu-end" id="drop">
                <li>
                  <h6 className="dropdown-header" id="drophead">
                    Submissão
                  </h6>
                </li>
                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="/recordSub"
                  >
                    <div className="mr-3">
                      <div className="icon-circle">
                        <i className="far fa-file fa-2x" id="iconew"></i>
                      </div>
                    </div>

                    <div id="crfile">
                      <span className="font-weight-bold">
                        {" "}
                        Ficheiros para Submissāo
                      </span>
                    </div>
                    <div className="mr-3">
                      <span
                        className="translate badge rounded-pill bg-danger"
                        id="draf"
                      >
                        {submitionCount}
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          )}
          {/* ################################### */}
          <div className="btn-group">
            <ToastContainer />
            <button
              type="button"
              className="btn btn-secondary position-relative"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="far fa-bell fa-lg" aria-hidden="true"></i>
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {atribute}
              </span>
            </button>
            <ul className="dropdown-menu dropdown-menu-end" id="drop">
              <li>
                <h6 className="dropdown-header" id="drophead">
                  Files
                </h6>
              </li>
              {/* Ficheiros Atribuidos */}
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/progressTable"
                >
                  <div className="mr-3">
                    <div className="icon-circle">
                      <i className="fas fa-handshake fa-2x" id="iconew"></i>
                    </div>
                  </div>

                  <div id="crfile">
                    <span className="font-weight-bold">
                      Ficheiro Atribuidos
                    </span>
                  </div>
                  <div className="mr-3">
                    <span
                      className="translate badge rounded-pill bg-danger"
                      id="draf"
                    >
                      {atribute}
                    </span>
                  </div>
                </a>
              </li>
              {/* ############################ */}
            </ul>
          </div>
          <span className="p-3"></span>

          {/* ################################### */}

          <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
            <NavLink className="nav-link" to="/logout" id="sair">
              <i className="fas fa-sign-out-alt"></i>
              Sair
            </NavLink>
          </ul>
        </div>
      </nav>
      {/* Sidebar */}
      {/* Fixed Sidebar */}
      <div className={`fixed-sidebar ${sidebarToggled ? "toggled" : ""}`}>
        <ul className="vertical-sidebar-icons mt-3">
          {/* ################# Home  ################## */}
          <li className="nav-item ">
            <NavLink className="nav-link" to="/home">
              <i className="fas fa-home"></i>
              <small style={{ color: "white" }}>Home</small>
            </NavLink>
          </li>
          {/* ################# Admin  ################## */}
          {(man || adminis) && (
            <li className="nav-item">
              <button className="nav-link" onClick={handleAdmin}>
                <i className="fas fa-user-tie"></i>
                <small style={{ color: "white" }}>Admin</small>
              </button>
            </li>
          )}
          {/* ################# Licencas ################## */}
          <li className="nav-item">
            <button className="nav-link" onClick={handleLic}>
              <i className="fas fa-ship"></i>
              <small style={{ color: "white" }}>Licenças</small>
            </button>
          </li>
          {/* ################# Cartas ################## */}
          <li className="nav-item">
            <button className="nav-link" onClick={handleLetter}>
              <i className="far fa-file-alt"></i>
              <small style={{ color: "white" }}>Cartas</small>
            </button>
          </li>
          {/* ################# Control  ################## */}
          {(prod || man || adminis) && (
            <li className="nav-item">
              <button className="nav-link" onClick={handleControl}>
                <i className="fas fa-user-secret"></i>
                <small style={{ color: "white" }}>Control</small>
              </button>
            </li>
          )}
          {/* ################# Arquivo ################## */}
          <li className="nav-item">
            <button className="nav-link" onClick={handleArquive}>
              <i className="fas fa-archive"></i>
              <small style={{ color: "white" }}>Arquivo</small>
            </button>
          </li>
          {/* ################# Track ################## */}
          <li className="nav-item">
            <button className="nav-link" onClick={handleTrack}>
              <i className="fas fa-map-marker-alt"></i>
              <small style={{ color: "white" }}>Track</small>
            </button>
          </li>
        </ul>
        {/* ################################### */}
        <ul className="fixed-button">
          <i className="fas fa-user fa-lg"></i>
          <small style={{ color: "white" }}>{user?.data.nome}</small>
        </ul>
      </div>

      {/* Menu Offcanvas  */}
      {/* Admin */}
      <Offcanvas show={admin} onHide={handleCloseAdmin}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div>
              <NavLink
                className="link-body-emphasis d-flex align-items-center me-md-auto mb-3 mb-md-0 text-decoration-none"
                to="#/"
              >
                <svg
                  className="bi bi-person-fill-lock me-3"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  style={{ fontSize: "30px" }}
                >
                  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5v-1a1.9 1.9 0 0 1 .01-.2 4.49 4.49 0 0 1 1.534-3.693C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm7 0a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z"></path>
                </svg>
                <span className="fs-4">Admin</span>
              </NavLink>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body d-flex flex-column justify-content-between pt-0">
            <div>
              <hr className="mt-0" />
              <ul className="navbar-nav flex-column mb-auto">
                {/* Utilizador */}
                <li className="nav-item">
                  <NavLink className="nav-link link-body-emphasis" to="/user">
                    <svg
                      className="bi bi-speedometer2 me-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Utilizador</p>
                  </NavLink>
                </li>
                {/* Clientes */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/clients"
                  >
                    <svg
                      className="bi bi-person-rolodex me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Clientes</p>
                  </NavLink>
                </li>
                {/* Importador */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/importador"
                  >
                    <svg
                      className="bi bi-briefcase me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Importador</p>
                  </NavLink>
                </li>
                {/* Contactos */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/contactos"
                  >
                    <svg
                      className="bi bi-journals me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                      <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Contactos</p>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Licencas */}
      <Offcanvas show={lic} onHide={handleCloseLic}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div>
              <NavLink
                className="link-body-emphasis d-flex align-items-center me-md-auto mb-3 mb-md-0 text-decoration-none"
                to="#/"
              >
                <svg
                  className="me-3"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 640 512"
                  style={{ fontSize: "30px" }}
                >
                  <path d="M496.616 372.639l70.012-70.012c16.899-16.9 9.942-45.771-12.836-53.092L512 236.102V96c0-17.673-14.327-32-32-32h-64V24c0-13.255-10.745-24-24-24H248c-13.255 0-24 10.745-24 24v40h-64c-17.673 0-32 14.327-32 32v140.102l-41.792 13.433c-22.753 7.313-29.754 36.173-12.836 53.092l70.012 70.012C125.828 416.287 85.587 448 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24 61.023 0 107.499-20.61 143.258-59.396C181.677 487.432 216.021 512 256 512h128c39.979 0 74.323-24.568 88.742-59.396C508.495 491.384 554.968 512 616 512c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24-60.817 0-101.542-31.001-119.384-75.361zM192 128h256v87.531l-118.208-37.995a31.995 31.995 0 0 0-19.584 0L192 215.531V128z" />
                </svg>
                <span>Licenças Sectoriais</span>
              </NavLink>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body d-flex flex-column justify-content-between pt-0">
            <div>
              <hr className="mt-0" />
              <ul className="navbar-nav flex-column mb-auto">
                {/* Novo */}
                {(prod || man || adminis) && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link link-body-emphasis"
                      to="/createFile"
                    >
                      <svg
                        className="bi bi-plus-circle-fill me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        style={{ fontSize: "25px", width: "100%" }}
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                      <p style={{ textAlign: "center" }}>Novo</p>
                    </NavLink>
                  </li>
                )}
                {/* Editar */}
                {(man || adminis) && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link link-body-emphasis "
                      to="/editfiles"
                    >
                      <svg
                        className="bi bi-pencil-square me-2"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        style={{ fontSize: "25px", width: "100%" }}
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <p style={{ textAlign: "center" }}>Editar</p>
                    </NavLink>
                  </li>
                )}
                {/*Tabela  */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis "
                    to="/licenseTable"
                  >
                    <svg
                      className="bi bi-table me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Tabela Licenças</p>
                  </NavLink>
                </li>
                {/*Relatorio  */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/invoicedebitbreakdownfile"
                  >
                    <svg
                      className="bi bi-file-earmark-bar-graph me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Relatório</p>
                  </NavLink>
                </li>
                {/*Protocolo  */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/protocoldeleveryfile"
                  >
                    <svg
                      className="me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 576 512"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z" />
                    </svg>
                    <p style={{ textAlign: "center" }}> Protocolo Entrega</p>
                  </NavLink>
                </li>
                {/* Protocolo Cliente */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/protocolodeleveryclient"
                  >
                    <svg
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      className="bi bi-file-ruled"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "97%" }}
                    >
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v4h10V2a1 1 0 0 0-1-1zm9 6H6v2h7zm0 3H6v2h7zm0 3H6v2h6a1 1 0 0 0 1-1zm-8 2v-2H3v1a1 1 0 0 0 1 1zm-2-3h2v-2H3zm0-3h2V7H3z" />
                    </svg>
                    <p style={{ textAlign: "center" }}> Protocolo Client</p>
                  </NavLink>
                </li>

                {/* Solicitar Doc */}
                {/* <li className="nav-item">
                  <NavLink className="nav-link link-body-emphasis" to="/inbox">
                    <svg
                      className="bi bi-inbox me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Solicitar Documentos</p>
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Cartas */}
      <Offcanvas show={letter} onHide={handleCloseLetter}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div>
              <NavLink
                className="link-body-emphasis d-flex align-items-center me-md-auto mb-3 mb-md-0 text-decoration-none"
                to="#/"
              >
                <svg
                  className="bi bi-file-text me-3"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  style={{ fontSize: "30px" }}
                >
                  <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                </svg>
                <span className="fs-4">Cartas de Insençāo</span>
              </NavLink>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body d-flex flex-column justify-content-between pt-0">
            <div>
              <hr className="mt-0" />
              <ul className="navbar-nav flex-column mb-auto">
                {/* Novo */}
                {(prod || man || adminis) && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link link-body-emphasis "
                      to="/createLetter"
                    >
                      <svg
                        className="bi bi-plus-circle-fill me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        style={{ fontSize: "25px", width: "100%" }}
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                      <p style={{ textAlign: "center" }}>Novo</p>
                    </NavLink>
                  </li>
                )}
                {/* Editar */}
                {(man || adminis) && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link link-body-emphasis"
                      to="/editletter"
                    >
                      <svg
                        className="bi bi-pencil-square me-2"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        style={{ fontSize: "25px", width: "100%" }}
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <p style={{ textAlign: "center" }}>Editar</p>
                    </NavLink>
                  </li>
                )}
                {/*Tabela  */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/letterTable"
                  >
                    <svg
                      className="bi bi-table me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Tabela Letter</p>
                  </NavLink>
                </li>
                {/*Relatorio  */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/invoicedebitbreakdownletter"
                  >
                    <svg
                      className="bi bi-file-earmark-bar-graph me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Relatório</p>
                  </NavLink>
                </li>
                {/*Protocolo  */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/protocoldeleveryletter"
                  >
                    <svg
                      className="me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 576 512"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Protocolo Entrega</p>
                  </NavLink>
                </li>
                {/* Solicitar Doc */}
                {/* <li className="nav-item">
                  <NavLink className="nav-link link-body-emphasis" to="/inbox">
                    <svg
                      className="bi bi-inbox me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Solicitar Documentos</p>
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Control */}
      <Offcanvas show={control} onHide={handleCloseControl}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {" "}
            <div>
              <NavLink
                className="link-body-emphasis d-flex align-items-center me-md-auto mb-3 mb-md-0 text-decoration-none"
                to="#/"
              >
                <svg
                  className="bi bi-incognito me-3"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  style={{ fontSize: "30px" }}
                >
                  <path
                    fillRule="evenodd"
                    d="m4.736 1.968-.892 3.269-.014.058C2.113 5.568 1 6.006 1 6.5 1 7.328 4.134 8 8 8s7-.672 7-1.5c0-.494-1.113-.932-2.83-1.205a1.032 1.032 0 0 0-.014-.058l-.892-3.27c-.146-.533-.698-.849-1.239-.734C9.411 1.363 8.62 1.5 8 1.5c-.62 0-1.411-.136-2.025-.267-.541-.115-1.093.2-1.239.735Zm.015 3.867a.25.25 0 0 1 .274-.224c.9.092 1.91.143 2.975.143a29.58 29.58 0 0 0 2.975-.143.25.25 0 0 1 .05.498c-.918.093-1.944.145-3.025.145s-2.107-.052-3.025-.145a.25.25 0 0 1-.224-.274ZM3.5 10h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Zm-1.5.5c0-.175.03-.344.085-.5H2a.5.5 0 0 1 0-1h3.5a1.5 1.5 0 0 1 1.488 1.312 3.5 3.5 0 0 1 2.024 0A1.5 1.5 0 0 1 10.5 9H14a.5.5 0 0 1 0 1h-.085c.055.156.085.325.085.5v1a2.5 2.5 0 0 1-5 0v-.14l-.21-.07a2.5 2.5 0 0 0-1.58 0l-.21.07v.14a2.5 2.5 0 0 1-5 0v-1Zm8.5-.5h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Z"
                  />
                </svg>
                <span className="fs-4">Relatório Control</span>
              </NavLink>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body d-flex flex-column justify-content-between pt-0">
            <div>
              <hr className="mt-0" />
              <ul className="navbar-nav flex-column mb-auto">
                {/* Controlo Ficheiros */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/controltable"
                  >
                    <svg
                      className="bi bi-list-check me-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"
                      />
                    </svg>
                    <p style={{ textAlign: "center" }}>Controlo Ficheiros</p>
                  </NavLink>
                </li>
                {/* Aguardam Notas */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis "
                    to="/controlNotas"
                  >
                    <svg
                      className="bi bi-sticky me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Aguardam Notas</p>
                  </NavLink>
                </li>
                {/* Aguardam Licencas */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis "
                    to="/controlLicensas"
                  >
                    <svg
                      className="bi bi-postcard me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Zm7.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5ZM10.5 5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3ZM13 8h-2V6h2v2Z"
                      />
                    </svg>
                    <p style={{ textAlign: "center" }}>Aguardam Licenças</p>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Arquivo */}
      <Offcanvas show={arquive} onHide={handleCloseArquive}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div>
              <NavLink
                className="link-body-emphasis d-flex align-items-center me-md-auto mb-3 mb-md-0 text-decoration-none"
                to="#/"
              >
                <svg
                  className="bi bi-archive me-3"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  style={{ fontSize: "30px" }}
                >
                  <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                </svg>
                <span className="fs-4">Arquivo</span>
              </NavLink>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body d-flex flex-column justify-content-between pt-0">
            <div>
              <hr className="mt-0" />
              <ul className="navbar-nav flex-column mb-auto">
                {/* Arquivo */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis "
                    to="/arquive"
                  >
                    <svg
                      className="bi bi-upload me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Carregar Arquivo</p>
                  </NavLink>
                </li>
                {/* Procurar  */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/arquiveTable"
                  >
                    <svg
                      className="bi bi-search me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Procurar Arquivo</p>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Track */}
      <Offcanvas show={track} onHide={handleCloseTrack}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div>
              <NavLink
                className="link-body-emphasis d-flex align-items-center me-md-auto mb-3 mb-md-0 text-decoration-none"
                to="#/"
              >
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-geo-alt-fill me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                </svg>

                <span className="fs-4">Track</span>
              </NavLink>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body d-flex flex-column justify-content-between pt-0">
            <div>
              <hr className="mt-0" />
              <ul className="navbar-nav flex-column mb-auto">
                {/* Cases */}
                <li className="nav-item">
                  <NavLink className="nav-link link-body-emphasis " to="/track">
                    <svg
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      className="bi bi-folder me-2"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z" />
                    </svg>

                    <p style={{ textAlign: "center" }}>Porcurar Casos</p>
                  </NavLink>
                </li>
                {/* Procurar  */}
                {/* <li className="nav-item">
                  <NavLink
                    className="nav-link link-body-emphasis"
                    to="/arquiveTable"
                  >
                    <svg
                      className="bi bi-search me-2"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style={{ fontSize: "25px", width: "100%" }}
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    <p style={{ textAlign: "center" }}>Procurar Arquivo</p>
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default TopNav;
