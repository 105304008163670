import React, { useState, useEffect } from "react";
import InvoiceForm from "./forms/invoiceForm";
import { useLocation } from "react-router-dom";
import UseModal from "./common/useModal";
import Spinner from "./common/spinner";
import auth from "../services/auth";
import io from "socket.io-client";
import OpenFile from "./common/openFile";
import {
  deleteOpenFile,
  getOpenFiles,
  recordOpenfile,
  financeTwo,
  updateCicleClose,
} from "../services/source";

function DepartamentoFinancasTwo() {
  const [load, setLoad] = useState(false);
  const [showmodal, setShowModal] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [formValues, setFormValues] = useState([]);

  const socket = io.connect(process.env.REACT_APP_SOCKET);
  let location = useLocation();
  const { file } = location.state || {};
  const user = auth.getCurrentUser();
  const nome = user.data.nome;
  const fileopen = file.filenumber;
  const obj = { fileopen, nome };

  useEffect(() => {
    getOpenFiles(obj).then((res) => {
      if (res.data === "true") return recordOpenfile(obj);
      else {
        setShowModal(true);
      }
    });
  }, [fileopen, nome]);

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleFormValuesSubmit = (values) => {
    setFormValues(values);
  };

  const onSubmit = (data,e) => {
    e.preventDefault();
    const datefact = convert(data.datefact);
    const datenfact = convert(data.datenfact);
    const recfactpgo = convert(data.recfactpgo);
    const caseid = file.idfile;
    const username = user.data.id;
    const payload = {
      data,
      formValues,
      datefact,
      datenfact,
      recfactpgo,
      caseid,
      username,
    };
    setLoad(true);
    financeTwo(payload).then((res) => {
      if (res.data === "true") {
        updateCicleClose(payload);
        deleteOpenFile(obj);
        setLoad(false);
        setSucess(true);
      }
    });
  };

  return (
    <div className="container-fluid component">
      <Spinner active={load} />
      <UseModal
        stylehead={{
          background: "rgb(228 203 126 / 33%)",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          borderBottom: "1px solid black",
        }}
        stylebody={{ display: "none" }}
        show={sucess}
        close={() => (window.location.href = "/progressTable")}
        title={"Ficheiro fechado com sucesso !!!"}
      />
      <UseModal
        show={showmodal}
        close={() => (window.location.href = "/progressTable")}
        title={"File " + fileopen}
        body={<OpenFile file={fileopen} util={nome} />}
      />
      <h3 className="text-dark mb-4">File Finanças</h3>
      <small>{file.filenumber}</small>
      <div className="row mb3">
        <div className="card mb-3">
          <InvoiceForm
            onSubmit={onSubmit}
            onFormValuesSubmit={handleFormValuesSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default DepartamentoFinancasTwo;
