import React from "react";
import { Routes, Route } from "react-router-dom";

// autentication
import RequireAuth from "./components/requireAuth";

//Public Route
import Nav from "./components/common/nav";

//import SideNav from "./components/common/sidenav";
import Layout from "./components/layout";
import Login from "./components/login";

//Protected Routes
import Home from "./components/home";
import User from "./components/user";
import Clients from "./components/clients";
import Importador from "./components/importador";
import Contactos from "./components/contactos";
import Inbox from "./components/inbox";

import CreateFile from "./components/createFile";
import RecordSub from "./components/recordSub";
import LicenseTable from "./components/common/licenseTable";
import LetterTable from "./components/common/letterTable";
import ReadOnlyTable from "./components/common/readOnlyTable";
import Cover from "./components/common/cover";
import DepartamentoFinancasOne from "./components/dpFinancasOne";
import DepartamentoOperacoesOne from "./components/dpOperacoesOne";
import DepartamentoFinancasTwo from "./components/dpFinanceTwo";

import DepartamentoFinancasOneLetter from "./components/dpFinancasOneLetter";
import DepartamentoOperacoesOneLetter from "./components/dpOperacoesOneLetter";
import DepartamentoFinancasTwoLetter from "./components/dpFinancasTwoLetter";

import InvoiceDebitFile from "./components/invoicedebitbreakdownfile";
import ProtocolDeleveryFile from "./components/protocoldeleveryfile";
import ProtocoloDeleveryClient from "./components/protocolodeleveryclient";

import InvoiceDebitLetter from "./components/invoicedebitbreakdownletter";
import ProtocolDeleveryLetter from "./components/protocoldeleveryletter";

import ControlTable from "./components/controltable";
import ControlNotas from "./components/controlNotas";
import ControlLicencas from "./components/controlLicensas";

import Arquive from "./components/arquive";
import ArquiveTable from "./components/arquiveTable";

import EditFiles from "./components/editfiles";
import EditLetter from "./components/editletter";

import Track from "./components/track";
import TrackCase from "./components/trackcase";

import ProgressTable from "./components/progressTable";

import CreateLetter from "./components/createLetter";
import Logout from "./components/logout";
//All
import NotFound from "./components/notFound";

function App() {

  return (
    <div>
      {/* {auth.getCurrentUser() && <Nav />} */}
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* Public Routes */}
          <Route path="/" element={<Login />} />
          {/* <Route path="/" element={<Login/>} /> */}
          {/* Protected Route */}
          <Route element={<RequireAuth />}>
            <Route path="nav" element={<Nav/>} />
            <Route path="home" element={<Home />} />
            <Route path="user" element={<User />} />
            <Route path="clients" element={<Clients />} />
            <Route path="importador" element={<Importador />} />
            <Route path="contactos" element={<Contactos />} />
            <Route path="inbox" element={<Inbox />} />
            <Route path="createFile" element={<CreateFile />} />
            <Route path="recordSub" element={<RecordSub />} />
            <Route path="licenseTable" element={<LicenseTable />} />
            <Route path="letterTable" element={<LetterTable />} />
            <Route path="ReadOnlyTable" element={<ReadOnlyTable />} />
            <Route path="Cover" element={<Cover />} />
            <Route path="dpFinancasOne" element={<DepartamentoFinancasOne />} />
            <Route
              path="dpOperacoesOne"
              element={<DepartamentoOperacoesOne />}
            />
            <Route path="dpFinancasTwo" element={<DepartamentoFinancasTwo />} />

            <Route path="progressTable" element={<ProgressTable />} />

            <Route path="editfiles" element={<EditFiles/>} />
            <Route path="editletter" element={<EditLetter/>} />

            <Route path="createLetter" element={<CreateLetter />} />
            <Route
              path="dpFinancasOneletter"
              element={<DepartamentoFinancasOneLetter />}
            />
            <Route
              path="dpOperacoesOneLetter"
              element={<DepartamentoOperacoesOneLetter />}
            />
            <Route
              path="dpFinancasTwoLetter"
              element={<DepartamentoFinancasTwoLetter />}
            />

            <Route
              path="invoicedebitbreakdownfile"
              element={<InvoiceDebitFile />}
            />
            <Route
              path="protocoldeleveryfile"
              element={<ProtocolDeleveryFile />}
            />
             <Route
              path="protocolodeleveryclient"
              element={<ProtocoloDeleveryClient />}
            />

            <Route
              path="invoicedebitbreakdownletter"
              element={<InvoiceDebitLetter />}
            />
            <Route
              path="protocoldeleveryletter"
              element={<ProtocolDeleveryLetter />}
            />
            <Route path="controltable" element={<ControlTable />} />
            <Route path="controlNotas" element={<ControlNotas />} />
            <Route path="controlLicensas" element={<ControlLicencas />} />
            <Route path="arquive" element={<Arquive />} />
            <Route path="arquiveTable" element={<ArquiveTable />} />
            <Route path="track" element={<Track />} />
            <Route path="trackcase" element={<TrackCase />} />
            <Route path="logout" element={<Logout />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
