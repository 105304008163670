import React, { useEffect, useState } from "react";
import InputData from "../common/inputdata";
import InputButton from "../common/inputbutton";
import UseModal from "../common/useModal";
import FileUploadLic from "../fileUploadLic";

import Accordion from "react-bootstrap/Accordion";
import ReadOnly from "../common/readOnly";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Link, useLocation } from "react-router-dom";

import { getAnexoLicName } from "../../services/source";

const LicensasForm = ({ onSubmit, defaultValues }) => {
  const [showLic, setShowLic] = useState(false);
  const [licName, setLicName] = useState();

  let location = useLocation();
  const { file } = location.state;

  const schema = yup.object().shape({
    datereclic: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
    datenvlic: yup
      .date()
      .required("Campo Obrigatório")
      .nullable("Campo Obrigatório"),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues });

  const handleLicAnexo = () => {
    setShowLic(true);
  };

  const handleCloseLic = () => {
    setShowLic(false);
  };

  useEffect(() => {
    async function getAnexoLic() {
      const result = await getAnexoLicName(file);
      if (result.data && result.data[0] && result.data[0].filenamelic) {
        setLicName(result.data[0].filenamelic);
      } else {
        return null;
      }
    }
    getAnexoLic();
  }, [handleCloseLic]);

  return (
    <div>
      <div className="card-header">
        <div className="col">
          <Link to={"/progressTable"}>
            <i className="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i>
          </Link>
        </div>
      </div>
      <div className="card-body">
        <Accordion id="create">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <i
                className="fa fa-folder-open fa-lg"
                aria-hidden="true"
                style={{ marginRight: "8px" }}
              ></i>
              File
            </Accordion.Header>
            <Accordion.Body>
              {<ReadOnly {...file} step={file} />}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <UseModal
        title="Anexo Licensa"
        size={"xl"}
        show={showLic}
        close={handleCloseLic}
        body={<FileUploadLic data={file} handleCloseLic={handleCloseLic} />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body shadow p-3">
          <div className="row mb-3">
            {/* ### Data Recebimentos Licensas #### */}
            <div className="col-md-4">
              <InputData
                name="datereclic"
                label="Data Recebimentos Licensas"
                control={control}
                errors={errors}
                // disabled={disabled.datecobmin}
                isclear={true}
              />
            </div>
            {/* ### Data envio das Licensas #### */}
            <div className="col-md-4">
              <InputData
                name="datenvlic"
                label="Data Envio das Licensas"
                control={control}
                errors={errors}
                // disabled={disabled.datecobmin}
                isclear={true}
              />
            </div>
            {/* ### Anexo #### */}
            <div className="col-md-4">
              <InputButton
                button={<i className="fa fa-upload"></i>}
                label="Anexo Licensa"
                name="anexolic"
                register={register}
                errors={errors}
                onClick={handleLicAnexo}
                value={licName}
              />
            </div>
          </div>
          {/* ######## Button  ########### */}
          <div className="row mb-3">
            <div className="col-md-12 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-outline-success col-md-2"
              >
                Submeter
              </button>
              <div className="m-2"></div>
              <button className="btn btn-outline-danger col-md-2">
                Apagar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LicensasForm;
