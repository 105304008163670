import React, { useState, useEffect } from "react";
import CreateForm from "./forms/createForm";
import Spinner from "./common/spinner";
import auth from "../services/auth";
import UseModal from "./common/useModal";
import {
  getLetter,
  getClients,
  getImportador,
  filterSectorLetter,
  createLetter,
} from "../services/source";

function CreateLetter() {
  // useState
  const user = auth.getCurrentUser();
  const [clients, setClients] = useState([]);
  const [imports, setImports] = useState([]);
  const [duplicate, setDuplicate] = useState(false);
  const [filterResult, setFilterResult] = useState([]);
  const [load, setLoad] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [focus, setFocus] = useState(false);
  const [numOrdem, setNumOrdem] = useState();

  // ##########################################

  // useEffect
  useEffect(() => {
    async function getCli() {
      const res = await getClients();
      setClients(res.data);
    }
    getCli();
    async function getImpor() {
      const imp = await getImportador();
      setImports(imp.data);
    }
    getImpor();
  }, []);

  useEffect(() => {
    async function getFilteCol() {
      const result = await filterSectorLetter();
      setFilterResult(result.data);
    }
    getFilteCol();
  }, []);

  useEffect(() => {
    async function getCaseNumber() {
      const result = await getLetter();
      const currentValue = parseInt(result.data[0].filenumber.slice(4));
      const nextValue = currentValue + 1;
      const nextValueString = "CADS" + nextValue.toString().padStart(9, "0");
      setNumOrdem(nextValueString);
    }
    getCaseNumber();
  },[]);

  // ##########################################

  const listCli = clients.map((cli) => ({
    label: cli.company,
    value: cli.company,
  }));

  const listImpor = imports.map((imp) => ({
    label: imp.company,
    value: imp.company,
  }));

  const disableFiels = {
    case: false,
    dateopenfile: false,
    client: false,
    importador: false,
    clientfileref: false,
    docTransporte: false,
    dateservice: false,
    service: false,
    valorfactimpor: false,
    valoremolumentos: false,
  };

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const initialValue = {};

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (!duplicate && data.case) {
      const openf = convert(data.dateopenfile);
      const datser = convert(data.dateservice);
      const username = user.data.id;
      const payload = {
        data,
        openfile: openf,
        dateservice: datser,
        username: username,
      };
      setLoad(true);
      await createLetter(payload).then((res) => {
        if (res.data === "true") {
          setLoad(false);
          setSucess(true);
        }
      });
    } else {
      alert("Porfavor click no numero ordem tente novamente");
    }
  };

  return (
    <div className="container-fluid component">
      <h3 className="text-dark mb-4">Carta Isenção</h3>
      <div className="row mb-3">
        <div className="card mb-3">
          <Spinner active={load} />
          <UseModal
            stylehead={{
              background: "rgb(228 203 126 / 33%)",
              boxShadow:
                "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
              borderBottom: "1px solid black",
            }}
            stylebody={{ display: "none" }}
            show={sucess}
            close={() => window.location.reload()}
            title={"Ficheiro criado com sucesso !!!"}
          />

          <CreateForm
            onSubmit={onSubmit}
            importadorList={listImpor}
            clientsList={listCli}
            defaultValues={initialValue}
            setDuplicate={setDuplicate}
            disabled={disableFiels}
            filterResult={filterResult}
            selClient="Selecionar Cliente"
            selImport="Selecionar Importador"
            selServ="Tipo Serviços"
            docTransp="Documento Transporte"
            // getNumber={getLetter()}
            numOrdem={numOrdem}

          />
        </div>
      </div>
    </div>
  );
}

export default CreateLetter;
