import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getFileData } from "../services/source";
import { Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

function ProtocoloDeleveryClient() {
  const [doc, setDoc] = useState([]);
  const [getSelect, setGetSelect] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [title, setTitle] = useState([]);
const [showPDF, setShowPDF] = useState(false);
const [hideSub, setHideSub] = useState(false);

  useEffect(() => {
    async function getDocument() {
      const result = await getFileData();
      setDoc(result.data);
    }
    getDocument();
  }, []);

  var refclient = [];
  doc.forEach((item) => {
    refclient.push({ label: item.refclient, value: item.refclient });
  });

  const { register, handleSubmit } = useForm();

  const handlechange = (data, clearValue) => {
    if (clearValue.action === "select-option") {
      for (let i = 0; i < data.length; i++) {
        const proto = doc.filter((val) => {
          if (val.refclient === data[i].label) {
            return val;
          }
          return null;
        });
        const input = [...getSelect];
        input.push(proto);
        setGetSelect(input);
      }
    } else if (clearValue.action === "clear") {
      setGetSelect([]);
      setTotalData([]);
    } else if (clearValue.action === "remove-value") {
      var item = clearValue.removedValue.value;
      var arr = getSelect.filter((val) => {
        if (val[0].refclient !== item) {
          return val;
        }
        return null;
      });
      setGetSelect(arr);
    }
  };

  const addMultiRows = (data) => {
    const totalDocs = getSelect.length;
    data.id = totalDocs + 1;
    setTotalData(getSelect);
  };

  const onSubmit = (data) => {
    setTitle(data.titulo);
    setHideSub(true);
  };

  const styles = StyleSheet.create({
    body: {
      paddingTop: 30,
      paddingBottom: 68,
    },
    subtitle: { textAlign: "center", fontSize: 15, paddingBottom: 10 },
    line: { marginBottom: 10 },
    title: { textAlign: "center", marginTop: "10%", marginBottom: "20px" },
    tableRowStyle: {
      flexDirection: "row",
      paddingTop: 0,
      paddingHorizontal: 20,
    },
    tableStyle: { display: "table", width: "auto" },
    firstTableColHeaderStyle: {
      width: "5%",
      backgroundColor: "black",
    },
    tableColFirstHeaderStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "black",
      borderBottomColor: "black",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#4169E1",
      color: "white",
    },
    tableCellFirstHeaderStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 9,
      fontWeight: "bold",
    },
    tableColHeaderStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "black",
      borderBottomColor: "black",
      borderWidth: 1,
      borderLeftWidth: 0,
      backgroundColor: "#F0F8FF",
    },
    tableCellHeaderStyle: {
      verticalAlign: "bottom",
      textAlign: "center",
      margin: 1,
      fontSize: 9,
      fontWeight: "bold",
    },

    firstTableColStyle: {
      width: "5%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderTopWidth: 1,
    },
    tableCellStyle: {
      textAlign: "center",
      margin: 1,
      fontSize: 9,
    },
    tableColStyle: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },

    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  const createTableHeader = () => {
    return (
      <div>
        <View style={styles.tableRowStyle} fixed>
          <View style={styles.firstTableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}></Text>
          </View>

          <View style={styles.tableColFirstHeaderStyle}>
            <Text style={styles.tableCellFirstHeaderStyle}>Column1</Text>
          </View>

          <View style={styles.tableColFirstHeaderStyle}>
            <Text style={styles.tableCellFirstHeaderStyle}>Column2</Text>
          </View>

          <View style={styles.tableColFirstHeaderStyle}>
            <Text style={styles.tableCellFirstHeaderStyle}>Column3</Text>
          </View>

          <View style={styles.tableColFirstHeaderStyle}>
            <Text style={styles.tableCellFirstHeaderStyle}>Column4</Text>
          </View>

          <View style={styles.tableColFirstHeaderStyle}>
            <Text style={styles.tableCellFirstHeaderStyle}>Column5</Text>
          </View>
          <View style={styles.tableColFirstHeaderStyle}>
            <Text style={styles.tableCellFirstHeaderStyle}>Column6</Text>
          </View>
          <View style={styles.tableColFirstHeaderStyle}>
            <Text style={styles.tableCellFirstHeaderStyle}>Column7</Text>
          </View>
          <View style={styles.tableColFirstHeaderStyle}>
            <Text style={styles.tableCellFirstHeaderStyle}>Column8</Text>
          </View>
        </View>
        <View style={styles.tableRowStyle} fixed>
          <View style={styles.firstTableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}></Text>
          </View>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>BACH DATE</Text>
          </View>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>CAB FILE Nº</Text>
          </View>{" "}
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>CUG INVOICE</Text>
          </View>{" "}
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>SECTOR LICENSE</Text>
          </View>{" "}
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>REQUEST DATE</Text>
          </View>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>RUPE RCVD DATE</Text>
          </View>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>
              PAYMENT REQUEST ED DATE
            </Text>
          </View>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>SL RCVD DATE</Text>
          </View>
        </View>
      </div>
    );
  };

  const generatePDF = () => {
    showPDF ? setShowPDF(false) : setShowPDF(true);
  };

  return (
    <>
      <div className="container-fluid component">
        <div
          id="layoutSidenav_content"
          style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <div className="container-fluid px4">
              <div className="card mt-4">
                <div className="card-header">
                  <div className="col-md-12">
                    <h1>
                      <i className="far fa-file-alt" aria-hidden="true">
                        {" "}
                      </i>
                      &nbsp; Protocolo de Entrega Cliente
                    </h1>{" "}
                    <small>Sector License</small>
                  </div>
                </div>

                <div className="card-body">
                  <div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <Select
                          options={refclient}
                          onChange={handlechange}
                          isMulti
                          placeholder="Selecionar..."
                        />
                        <br />
                        <div className="col-md-1">
                          <button
                            className="btn btn-primary"
                            onClick={() => addMultiRows(getSelect)}
                          >
                            Adicionar
                          </button>
                        </div>
                      </div>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th>#</th>
                          <th>BACH DATE</th>
                          <th>CAB FILE Nº</th>
                          <th>CUG INVOICE</th>
                          <th>SECTOR LICENSE</th>
                          <th>REQUEST DATE</th>
                          <th>RUPE RCVD DATE</th>
                          <th>PAYMENT REQUEST ED DATE</th>
                          <th>SL RCVD DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {totalData.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{val[0].opencasedate}</td>
                              <td>{val[0].refclient}</td>
                              <td>{val[0].refimpor}</td>
                              <td>{val[0].service}</td>
                              <td>{val[0].dateservice}</td>
                              <td>{val[0].datecobmin}</td>
                              <td>{val[0].datapagcads}</td>
                              <td>{val[0].datenvlic}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="form-floating mb-3 mb-md-0">
                            <input
                              type="text"
                              className="form-control"
                              {...register("titulo")}
                            />
                            <label htmlFor="Titulo">Titulo</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-1">
                          <button className="btn btn-outline-success">
                            Submeter
                          </button>
                        </div>
                      </div>
                    </form>

                    {hideSub ? (<div className="row mb-12">
                      <div className="col-md-10">
                      <button
                          className="btn btn-success"
                           onClick={() => generatePDF()}
                        >
                          {showPDF ? (
                          <i className="fa fa-arrow-circle-left">
                            &nbsp;Voltar
                          </i>
                      ) : (
                          <i className="fa fa-file-pdf-o">&nbsp;Exportar</i>
                          )} 
                        </button>
                      </div>

                      <div className="col-md-2">
                        <button
                          className="btn btn-danger"
                          onClick={() => window.location.reload(false)}
                        >
                          <i className="fa fa-times-circle">&nbsp;Fechar</i>
                        </button>
                      </div>
                    </div>):null}

                    <br />

                    <div style={{ paddingLeft: "10%" }}>
                      {showPDF ? (<PDFViewer width={800} height={1000} showToolbar>
                        <Document>
                          <Page>
                            <Text style={styles.title}>{title}</Text>
                            <View style={styles.tableStyle}>
                              {createTableHeader()}
                              {totalData.map((data, index) => {
                                return (
                                  <View
                                    key={index}
                                    style={styles.tableRowStyle}
                                  >
                                    <View style={styles.firstTableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {index + 1}
                                      </Text>
                                    </View>

                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].opencasedate}
                                      </Text>
                                    </View>

                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].refclient}
                                      </Text>
                                    </View>

                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].refimpor}
                                      </Text>
                                    </View>
                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].service}
                                      </Text>
                                    </View>
                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].dateservice}
                                      </Text>
                                    </View>

                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].datecobmin}
                                      </Text>
                                    </View>

                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].datapagcads}
                                      </Text>
                                    </View>

                                    <View style={styles.tableColStyle}>
                                      <Text style={styles.tableCellStyle}>
                                        {data[0].datenvlic}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              })}
                            </View>
                          </Page>
                        </Document>
                      </PDFViewer>):null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default ProtocoloDeleveryClient;
