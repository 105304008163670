import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { getLicense } from "../../services/source";
import { Link } from "react-router-dom";

import ReactPaginate from "react-paginate";

function LicenseTable() {
  const [disCase, setDisCase] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function getCaseNumber() {
      const result = await getLicense();
      setDisCase(result.data);
    }
    getCaseNumber();
  }, []);


  const pathFile = (res) => {
    switch (res) {
      case res:
        return "/readOnlyTable";
      default:
        return null;
    }
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const casePerPage = 15;
  const pageVisited = pageNumber * casePerPage;

  //search
  const pageCount = Math.ceil(
    disCase.filter((file) => {
      if (searchTerm === "") {
        return file;
      } else if (
        file.filenumber.toLowerCase().indexOf(searchTerm.toLocaleLowerCase())
      ) {
        return file;
      }
      return false;
    }).length / casePerPage
  );

  const displayCases = disCase
    .filter((f) => {
      if (searchTerm === "") {
        return f;
      } else if (
        f.filenumber.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return f;
      } return null;
    })
    .slice(pageVisited, pageVisited + casePerPage)
    .map((cas) => {
      return (
        <tr key={cas.idfile} style={{ textAlign: "center" }}>
          <td>{cas.filenumber}</td>
          <td>{cas.client}</td>
          <td>{cas.username}</td>
          <td style={{color: cas.step1 === "ISENTO" ? "red" : null}} >{cas.step1 }</td>
          <td>{cas.step2}</td>
          <td>{cas.step3}</td>
          <td>{cas.created}</td>
          <td>{cas.modified}</td>
          <td style={{ color: cas.cicle === "Close" ? "red" : null }}>
            {cas.cicle  === "1" ? "Finanças": null}
            {cas.cicle  === "2" ? "Operações": null}
            {cas.cicle  === "3" ? "Finanças": null}
            {cas.cicle  === "Close" ? "Fechado": null}
          </td>
          <td><Link to={"/cover"} state={{file: cas}}><i className="fa fa-file-pdf-o"></i></Link></td>
          <td>
            <Link to={pathFile(cas.cicle)} state={{ file: cas }}>
              <i className="fa fa-forward" aria-hidden="true"></i>
            </Link>
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className="container-fluid component">
            <h3 className="text-dark mb-4">License Table</h3>
            <div className="text-dark mb-4">
                <div className="row mb-3">
                <div className="card mb-3">
                <div className="card-header">
                  <i className="fa fa-table me-1"></i>
                  License Table
                </div>
                <div className="card-body">
                  <div className="dataTable-top">
                    <div className="dataTable-search">
                      <div className="input-group">
                        <input
                          className="form-control mb-3"
                          type="text"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                            changePage({ selected: 0 });
                          }}
                          placeholder="Procurar..."
                          aria-label="Search for..."
                          aria-describedby="btnNavbarSearch"
                        />
                      </div>
                    </div>
                  </div>
                  {/* ############## Tables ############## */}
                  <Table striped bordered hover>
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <th>File</th>
                        <th>Cliente</th>
                        <th>OPE1</th>
                        <th>FIN1</th>
                        <th>OPE2</th>
                        <th>FIN2</th>
                        <th>Data Criacao</th>
                        <th>Modificado</th>
                        <th>Status</th>
                        <th>Cover</th>
                        <th>Abrir</th>
                      </tr>
                    </thead>
                    <tbody>{displayCases}</tbody>
                  </Table>
                  {/* ############## !Tables ############## */}
                  <div className="pagination justify-content-center">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"pagination"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      disabledClassName={"page-item disabled"}
                      activeClassName={"page-item active"}
                      pageLinkClassName={"page-link"}
                    />
                  </div>
                </div>
              </div>
                </div>
            </div>
        </div>
    </>

   
  );
}

export default LicenseTable;
