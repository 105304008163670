import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getSectorLicense, getSectorLetter } from "../../services/source";

import pdfCover from "./pdfCover";
import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

function Cover() {
  const [recfile, setRecFile] = useState({});

  let location = useLocation();
  const { file } = location.state || {};
  let number = file.filenumber || {};

  console.log(number);


  const cads8 = number.startsWith("CADS8");
  const cads9 = number.startsWith("CADS9");

  let recFileResponse;

  useEffect(() => {
    async function getFilesCreate() {
      if (cads8) {
        recFileResponse = await getSectorLicense(file);
        setRecFile(recFileResponse.data[0]);
      } else if (cads9) {
        recFileResponse = await getSectorLetter(file);
        setRecFile(recFileResponse.data[0]);
      }
    }
    getFilesCreate();
  }, [number]);

  const styles = StyleSheet.create({
    body: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    title: {
      textAlign: "center",
      marginTop: "20px",
    },
    subtitle: {
      textAlign: "center",
      marginTop: "20px",
      fontSize: "14px",
      marginBottom: "50%",
    },

    tableRowStyle: {
      flexDirection: "row",
      paddingTop: 0,
      paddingHorizontal: 20,
    },

    tableStyle: { display: "table", width: "auto" },

    firstTableColHeaderStyle: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },

    secondTableColHeaderStyle: {
      width: "70%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      backgroundColor: "#bdbdbd",
    },

    tableCellHeaderStyle: {
      margin: 1,
      fontSize: 12,
      fontWeight: "bold",
      paddingLeft: 8,
    },

    tableColHeaderStyle: {
      width: "40%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
    },

    tableSecColHeaderStyle: {
      width: "70%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      paddingLeft: 10,
    },

    tableCellStyle: {
      margin: 1,
      fontSize: 12,
    },
  });

  const createTableHeader = () => {
    return (
      <div>
        <View style={styles.tableRowStyle} fixed>
          <View style={styles.firstTableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>Dados</Text>
          </View>
          <View style={styles.secondTableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>Descrição</Text>
          </View>
        </View>

        {/* ########################################################################## */}
        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>Cliente</Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.clients}</Text>
          </View>
        </View>

        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>Importador</Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.importador}</Text>
          </View>
        </View>

        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>
              N Factura Importacao
            </Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.refimpor}</Text>
          </View>
        </View>

        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>
              Documento Transporte
            </Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.doctransp}</Text>
          </View>
        </View>

        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>
              Referencia Ficheiro Cliente
            </Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.refclient}</Text>
          </View>
        </View>

        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>Data Pedido Serviço</Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.dateservice}</Text>
          </View>
        </View>

        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>Tipo Produto</Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.service}</Text>
          </View>
        </View>

        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>Valor da Factura</Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.valorfactimpor}</Text>
          </View>
        </View>

        <View style={styles.tableRowStyle} fixed>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>Operador</Text>
          </View>
          <View style={styles.tableSecColHeaderStyle}>
            <Text style={styles.tableCellStyle}>{recfile.nome}</Text>
          </View>
        </View>
      </div>
    );
  };

  return (
    <div>
      <div className="container-fluid component">
        <div
          id="layoutSidenav_content"
          style={{ backgroundColor: "rgb(234 229 213)" }}
        >
          <main>
            <div className="container-fluid px4">
              <div className="card mt-4">
                <div className="card-header">
                  <div className="col-md-12">
                    <h1>
                      <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                      &nbsp; Cover File
                    </h1>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-1">
                      <Link to={cads8 ? "/licenseTable" : "/letterTable"}>
                        <i
                          className="fa fa-arrow-circle-left fa-lg"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "10%" }}>
                    <PDFViewer width={800} height={1000} showToolbar>
                      <Document>
                        <Page>
                          <View>{pdfCover()}</View>
                          <Text style={styles.title}>{recfile.importador}</Text>
                          <Text style={styles.subtitle}>
                            Processo nº: {recfile.filenumber} | Data Abertura:{" "}
                            {recfile.opencasedate}
                          </Text>
                          <View style={styles.tableStyle}>
                            {createTableHeader()}
                          </View>
                        </Page>
                      </Document>
                    </PDFViewer>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Cover;
