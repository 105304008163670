import React, { useEffect, useState } from "react";
import { getOpenFiles, deleteOpenFile } from "../../services/source";
import auth from "../../services/auth";


function OpenFile(props) {
  const [body, setBody] = useState({});
  const [names, setNames] = useState();
  const fileopen = props.file;
  const obj = { fileopen};

  const user = auth.getCurrentUser();
  const username = user.data.nome;

  useEffect(() => {
    getOpenFiles(obj).then((res) => {
      setNames(res.data[0].name);
      if (res.data[0].name === username) {
        setBody(true);
      } else {
        setBody(false);
      }
    });
  }, [props.file, props.util ]);

  return (
    <>
      {!body ? (<p style={{ textAlign: "center" }}>
        Esta a ser editado por outro utilizador {names}  !!!
      </p>): (<p style={{ textAlign: "center" }}>
        Este ficheiro nāo foi fechado corretamente por favor recupere!!!!
      </p>)}
      {body ? (
        <div className="text-center">
          <button
            className="btn btn-danger"
            onClick={() => {
              deleteOpenFile(obj);
              window.location.href = "/progressTable";
            }}
          >
            Recuperar
          </button>
        </div>
      ) : null}
    </>
  );
}

export default OpenFile;
