import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import auth from "../services/auth";
import Nav from "../components/common/nav"; // Import your Nav component

const userAut = () => {
  const user = auth.getCurrentUser();
  const company = user?.data.company;
  if (company === "CADS" || company) {
    return company;
  } else {
    return null;
  }
};

const RequireAuth = () => {
  const location = useLocation();
  const isAuth = userAut();

  if (isAuth === "CADS") {
    // Render the navigation only if the current route is not "track" or "trackcase"
    if (!["track", "trackcase"].includes(location.pathname.split("/").pop())) {
      return (
        <>
          <Nav />
          <Outlet />
        </>
      );
    } else {
      return <Outlet />;
    }
  } else if (isAuth) {
    // If the company is not "CADS" but exists, allow access to "track" and "trackcase" routes
    const allowedRoutes = ["track", "trackcase"];
    if (allowedRoutes.includes(location.pathname.split("/").pop())) {
      return <Outlet />;
    }
  }

  // Redirect to home if not authenticated
  return <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireAuth;
