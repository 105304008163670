import React from "react";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";

function InputData({ name, label, control, errors, isDisabled, isclear, onCalendarClose, defaultValue }) {
  return (
    <div>
      <small>
        <label className="breadcrumb-item active" htmlFor={name}>
          {label}
        </label>
      </small>
      <div className="input-group mb-3">
        <span className="input-group-text">
          <i className="fa fa-calendar"></i>
        </span>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DatePicker
              selected={field.value}
              onChange={(date) => field.onChange(date)}
              className="form-control custom-datepicker"
              dateFormat="dd/MM/yyyy"
              locale={pt}
              isClearable={isclear}
              disabled={isDisabled}
              onCalendarClose={onCalendarClose}
              defaultValue={defaultValue} 
            />
          )}
        />
        <p style={{ color: "red" }}>{errors[name]?.message}</p>
      </div>
    </div>
  );
}

export default InputData;
