import React, { useState, useEffect } from "react";
import Select from "react-select";
import FileUpload from "./common/fileUpload";
import FileList from "./common/fileList";
import "../styles/arquive.css";
import { Alert } from "react-bootstrap";
import {
  getFilesLetter,
  arquiveRupe,
  arquiveFactRec,
  arquiveNotaDeb,
  arquiveLic,
  arquiveRec,
  arquiveProtocoloSub,
  getAnexoRupeName,
  getAnexoProtName,
  getAnexoNotaDebName,
  getAnexoLicName,
  getAnexoFacRecName,
  getAnexoRecName,
} from "../services/source";

function Arquive() {
  const [files, setFiles] = useState([]);
  const [showDatas, setShowDatas] = useState(false);
  const [filesletter, setFilesLetter] = useState([]);
  const [selectedFileNumber, setSelectedFileNumber] = useState(null);
  const [selectedArquive, setSelectedArquive] = useState(null);

  const [isRupeOptionDisabled, setIsRupeOptionDisabled] = useState(false);
  const [isProtocolOptionDisabled, setIsProtocolOptionDisabled] = useState(false);
  const [isNotaDebDisabled, setIsNotaDebDisabled] = useState(false);
  const [isLicDisabled, setIsLicDisabled] = useState(false);
  const [isFacDisabled, setIsFacDisabled] = useState(false);
  const [isRecDisabled, setIsRecDisabled] = useState(false);

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  useEffect(() => {
    if (showDatas) {
    }
  }, [showDatas]);

  useEffect(() => {
    async function getFileNumber() {
      const result = await getFilesLetter();
      setFilesLetter(result.data);
    }
    getFileNumber();
  }, []);

  const arquiveOptions = [
    {
      value: arquiveProtocoloSub,
      label: "Protocolo Submissão",
      isDisabled: isProtocolOptionDisabled,
    },
    { value: arquiveRupe, label: "Rupe", isDisabled: isRupeOptionDisabled },
    {
      value: arquiveNotaDeb,
      label: "Nota Debito",
      isDisabled: isNotaDebDisabled,
    },
    { value: arquiveLic, label: "Licensa", isDisabled: isLicDisabled },
    { value: arquiveFactRec, label: "Factura", isDisabled: isFacDisabled },
    { value: arquiveRec, label: "Recibo", isDisabled: isRecDisabled },
  ];

  const selectOptions = filesletter.map((obj) => ({
    value: obj.filenumber,
    label: obj.filenumber,
    id: obj.idfile,
  }));

  useEffect(() => {
    if (selectedFileNumber) {
      const {  value } = selectedFileNumber;
      getAnexoRupeName({ filenumber: value })
        .then((res) => setIsRupeOptionDisabled(res.data.length > 0))
        .catch((error) => console.error(error));
      getAnexoProtName({ filenumber: value })
        .then((res) => setIsProtocolOptionDisabled(res.data.length > 0))
        .catch((error) => console.error(error));
      getAnexoNotaDebName({ filenumber: value })
        .then((res) => setIsNotaDebDisabled(res.data.length > 0))
        .catch((error) => console.error(error));
      getAnexoLicName({ filenumber: value })
        .then((res) => setIsLicDisabled(res.data.length > 0))
        .catch((error) => console.error(error));
      getAnexoFacRecName({ filenumber: value })
        .then((res) => setIsFacDisabled(res.data.length > 0))
        .catch((error) => console.error(error));
      getAnexoRecName({ filenumber: value })
        .then((res) => setIsRecDisabled(res.data.length > 0))
        .catch((error) => console.error(error));
    }
  }, [selectedFileNumber]);

  return (
    <div className="container-fluid component">
      <div className="card-track">
        <h3 className="text-dark mb-4">Carregar Ficheiros</h3>
        <div className="mt-3">
          <Select
            placeholder="File Number"
            className="mt-5 anexo"
            options={selectOptions}
            isClearable
            onChange={(selectedOption) => setSelectedFileNumber(selectedOption)}
          />
        </div>
        <div className="mt-3">
          <Select
            placeholder="Select Arquive"
            className="mt-5"
            options={arquiveOptions}
            isClearable
            onChange={(selectedOption) => setSelectedArquive(selectedOption)}
          />
        </div>
        <div className="mt-5">
          <FileUpload
            files={files}
            setFiles={setFiles}
            casenumber={selectedFileNumber?.id}
            arquive={selectedArquive?.value}
            showDatas={showDatas}
            setShowDatas={setShowDatas}
            filenumber={selectedFileNumber?.value}
          />
          {!showDatas ? (
            <FileList files={files} removeFile={removeFile} />
          ) : null}
        </div>
        <div>
          <Alert
            show={showDatas}
            variant="success"
            dismissible
            onClose={() => (window.location.href = "/arquive")}
          >
            Upload com Sucesso!!!
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default Arquive;
