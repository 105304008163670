import React, { useState, useEffect } from "react";
import { getEditFiles } from "../services/source";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import EditCreateFile from "./common/editCreateFile";
import EditFinanceOne from "./common/editFinanceOne";
import EditOperationOne from "./common/editOperationOne";
import EditFinanceTwo from "./common/editFinanceTwo";

function EditFiles() {
  const [files, setFiles] = useState([]);
  const [fileobj, setFileObj] = useState([]);
  const [showcicle, setShowCicle] = useState();
  const [showClick, setShowClick] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sucess, setSucess] = useState(false);

  useEffect(() => {
    async function getFilesEdit() {
      const result = await getEditFiles();
      setFiles(result.data);
    }
    getFilesEdit();
  }, []);

  const { register, handleSubmit } = useForm({});
  const onSubmit = (data) => {
    if (searchValue.startsWith("CADS8")) {
      const lic = data.licensa;
      let obj = files?.filter((obj) => obj.filenumber === lic);
      setFileObj(obj);
      setShowCicle(obj[0]?.cicle);
      setShowClick(true);
    } else{
      setSucess(true);
    }
  };

  const handleCloseAlert = () => {
    setSucess(false);
  };

  return (
    <div className="container-fluid component">
      <div className="card mb-4 mt-4">
        <div className="card-header">Edit File</div>
        <div className="card-body">
          {/* ############################## Formulario ############################## */}

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* ############### Procurar filenumber ################# */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <button
                  className="btn btn-outline-success"
                  type="submit"
                  disabled={showClick}
                >
                  <i className="fa fa-search">&nbsp;</i>
                  Procurar
                </button>
              </div>
              <input
                type="text"
                {...register("licensa")}
                className="form-control"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <Alert show={sucess} variant="danger" onClick={handleCloseAlert}>
              Numero de Licensa invalido !!!
            </Alert>
            {/* ############### End ############*/}
          </form>
          {/* ################## inserir os files para editar ############### */}
          <div>
                    {(() => {
                      switch (showcicle) {
                        case "1":
                          return <EditCreateFile file={fileobj} />;
                        case "2":
                          return (
                            <>
                              <EditCreateFile file={fileobj} />
                              <EditFinanceOne file={fileobj} />
                            </>
                          );
                        case "3":
                          return (
                            <>
                              <EditCreateFile file={fileobj} />
                              <EditFinanceOne file={fileobj} />
                              <EditOperationOne file={fileobj} />
                            </>
                          );
                        case "Close":
                          return (
                            <>
                              <EditCreateFile file={fileobj} />
                              <EditFinanceOne file={fileobj} />
                              <EditOperationOne file={fileobj} />
                              <EditFinanceTwo file={fileobj} />
                            </>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
          {/* ############### End ############*/}
        </div>
      </div>
    </div>
  );
}

export default EditFiles;
