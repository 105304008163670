import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "./common/spinner";
import { Link } from "react-router-dom";
import auth from "../services/auth";
import UseModal from "./common/useModal";
import FileSubmition from "./common/fileSubmition";
import { getSubmissao } from "../services/source";

function RecordSub() {
  const [load, setLoad] = useState(false);
  const [sub, setSub] = useState([]);
  const user = auth.getCurrentUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [isMatch, setIsMatch] = useState();
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    async function getALLSubmissao() {
      const result = await getSubmissao();
      setSub(result.data);
    }
    getALLSubmissao();
  }, []);

  const file = user.data.role;

  const handleClearSearch = () => setSearchTerm("");
  const handleClose = () => setSelectedRow(null);

  const handleClick = (index, files) => {
    const numberCase = files.filenumber;
    if (numberCase.startsWith("CADS8")) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
    setSelectedRow(index);
  };

  const allSubmit = sub
    .filter((f) => {
      if (file === "") {
        return f;
      } else if ("Operacoes" === file) {
        return f;
      }
      return null;
    })
    .filter((n) => {
      if (searchTerm === "") {
        return n;
      } else if (
        n.filenumber.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return n;
      } else if (n.refclient.toLowerCase().includes(searchTerm.toLowerCase())) {
        return n;
      } else if (n.refimpor.toLowerCase().includes(searchTerm.toLowerCase())) {
        return n;
      }
      return null;
    })
    .map((d, index) => {
      return (
        <tr key={d.filenumber} style={{ textAlign: "center" }}>
          <td>{d.filenumber}</td>
          <td>{d.refclient}</td>
          <td>{d.refimpor}</td>
          <td>
            <i
              className="far fa-edit fa-2x"
              aria-hidden="true"
              onClick={() => handleClick(index, d)}
              style={{ color: "silver", cursor: "pointer" }}
            ></i>
            {selectedRow === index && (
              <UseModal
                size={"xl"}
                show={true}
                close={handleClose}
                title= "Caso para Submissāo"
                body={
                    <FileSubmition {...d} />
                }
               
              />
            )}
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className="container-fluid component px4">
        <h3 className="mt-4">Lista de Casos para Submissāo</h3>
        <ol className="breadcrumb mb-4"></ol>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fa fa-table me-1"></i>
            Casos
          </div>

          <Spinner active={load} />
          <div className="card-header">
            <div className="col-md-12">
              <div className="row mb-2">
                <div className="col">
                  <Link to={"/home"}>
                    <i
                      className="fa fa-arrow-circle-left fa-lg"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="dataTable-top">
              <div className="dataTable-search">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    value={searchTerm}
                    placeholder="Procurar..."
                    aria-label="Search for..."
                    aria-describedby="btnNavbarSearch"
                  />
                </div>
              </div>
            </div>
          </div>

          <Table>
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>File</th>
                <th>Ref Cliente</th>
                <th>Ref Factura</th>
                <th>Abrir</th>
              </tr>
            </thead>
            <tbody>{allSubmit}</tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default RecordSub;
