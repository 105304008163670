import React from "react";

function RadioboxWithLabel({
  id,
  label,
  name,
  errors,
  value,
  register,
  checked,
  onChange,
  type,
  ...res
}) {
  return (
    <div>
      <div className="form-check">
        <input
        className="form-check-input"
          {...res}
          type={type}
          value={value}
          id={id}
          name={name}
          {...register(name)}
          checked={checked}
          onChange={onChange}
        />
      <label htmlFor={id} className="form-check-label">{label}</label>
      </div>
      <p style={{ color: "red" }}>{errors[name]?.message}</p>
    </div>
  );
}

export default RadioboxWithLabel;
